@import url(https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap);
.App {
  text-align: center;
}
.divider {
  margin: 0;
}

.pointer {
  cursor: pointer;
}

.not-allowed {
  cursor: not-allowed !important;
}

.flex {
  display: flex;
}

.display-hide-hard {
  display: none !important;
}

.inline-flex {
  display: inline-flex;
}

.ml-60px {
  margin-left: 60px;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 19px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.align-center {
  align-items: center;
}

.align-flex-end {
  align-items: flex-end;
}

.align-flex-start {
  align-items: flex-start;
}

.align-baseline {
  align-items: baseline;
}

.dialog-content {
  margin: auto;
  border-radius: 4px;
  background-color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.dialog-content input,
textarea {
  outline: none !important;
}

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.dialog-overlay.draggable {
  overflow: hidden;
}

.dialog-content.draggable {
  margin: 0;
}

.ml-0 {
  margin-left: 0;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.mr-15 {
  margin-right: 15px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mr-40 {
  margin-right: 40px;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-space-around {
  justify-content: space-around;
}

.justify-flex-end {
  justify-content: flex-end;
}

.justify-flex-start {
  justify-content: flex-start;
}

.width-100 {
  width: 100%;
}

.force-width-100 {
  width: 100% !important;
}

.height-100 {
  height: 100%;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.disable-pointer-events {
  pointer-events: none;
}

.underlined {
  text-decoration: underline;
}

.user-select-none {
  -webkit-user-select: none;
          user-select: none;
}

.white-spice-nowrap {
  white-space: nowrap;
}

.dark-blue-btn {
  outline: none !important;
  background-color: #3e6fd9;
  color: white;
}

.dark-blue-btn:hover {
  background-color: #3061ae;
}

.transparent-btn {
  outline: none !important;
  background-color: transparent;
  color: rgb(136, 141, 168);
}

.hidden {
  opacity: 0;
  pointer-events: none;
}

.ellipsed-text {
  overflow: hidden;
  text-overflow: ellipsis;
}

.pd-20 {
  padding: 20px;
}

.logo {
  align-self: flex-start;
  margin-left: 15px;
  height: 80px;
  -webkit-user-select: none;
          user-select: none;
}

.logo img {
  height: 100%;
  pointer-events: none;
}

.bg-white {
  background-color: #fff;
}

.overflow-visible {
  overflow: visible !important;
}

.height-auto-important {
  height: auto !important;
}

.text-align-center {
  text-align: center;
}

.text-align-start {
  text-align: start;
}

.widht-50 {
  width: 50%;
}

.mb-10px {
  margin-bottom: 10px;
}

.mb-0 {
  margin-bottom: 0;
}

.border-0 {
  border: 0;
}

.justify-between {
  justify-content: space-between;
}

.justify-space-evenly {
  justify-content: space-evenly;
}

.margin-left-20px {
  margin-left: 20px;
}

.ml-80px {
  margin-left: 80px;
}

.ml-250px {
  margin-left: 220px;
}

.max-width-250px {
  max-width: 250px;
}

.delete-popup.delete-conformation-modal {
  height: 250px;
}

.delete-popup-text.span-delete-modal {
  width: 400px;
  height: auto;
}

.pt-10 {
  padding-top: 10px;
}

.pt-0 {
  padding-top: 0;
}

.hide:hover,
.hide:active,
.hide {
  border: 0;
  background-color: none;
  padding: 0;
  width: 0;
  height: 0;
}
.background-grey {
  background-color: #eceef5;
}

.font-size-20 {
  font-size: 20px;
}

.mt-120px {
  margin-top: 120px !important;
}

.mt-25px {
  margin-top: 25px;
}

.white-no-wrap {
  white-space: nowrap;
}

.icon-17px {
  margin-right: 8px;
  width: 17px;
}

.icon-32px {
  margin-right: 8px;
  width: 32px;
}

.tooltip-message.white {
  background-color: white;
  width: auto;
  max-width: 300px;
  color: #3e6fd9;
}

.tooltip-message.white.top::after {
  border-color: white transparent transparent transparent;
}

.tooltip-message.white.left::after {
  border-color: transparent transparent transparent white;
}

.tooltip-message.white.right::after {
  border-color: transparent white transparent transparent;
}

.tooltip-message.white.bottom::after {
  border-color: transparent transparent white transparent;
}

.ai-generate-modal-window-message {
  color: #9880ff;
  font-size: 16px;
}

.video {
  max-width: 60vw;
}

.loader {
  position: relative;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation:
    load6 1.7s infinite ease,
    round 1.7s infinite ease;
  animation:
    load6 1.7s infinite ease,
    round 1.7s infinite ease;
  margin: 15% auto;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  overflow: hidden;
  color: #3e6fd9;
  font-size: 50px;
  text-indent: -9999em;
}

@-webkit-keyframes load6 {
  0% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      0 -0.83em 0 -0.42em,
      0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em,
      0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      0 -0.83em 0 -0.42em,
      0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em,
      0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em,
      -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em,
      -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em,
      -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      0 -0.83em 0 -0.42em,
      0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em,
      0 -0.83em 0 -0.477em;
  }
}

@keyframes load6 {
  0% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      0 -0.83em 0 -0.42em,
      0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em,
      0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      0 -0.83em 0 -0.42em,
      0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em,
      0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em,
      -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em,
      -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em,
      -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      0 -0.83em 0 -0.42em,
      0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em,
      0 -0.83em 0 -0.477em;
  }
}

@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.tooltip-container {
  position: relative;
  border-radius: 4px;
  width: 18px;
  min-width: 18px;
  height: 18px;
  min-height: 18px;
}

.tooltip-container:hover .tooltip-message-container {
  display: block;
}

.tooltip-indicator {
  height: 27px;
  font-size: 20px;
  line-height: 26px;
  font-family: "Inter";
  -webkit-user-select: none;
          user-select: none;
}

.tooltip-text {
  white-space: pre-line;
}

.tooltip-message-container {
  position: fixed;
  z-index: 1000;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background-color: #3e6fd9;
  padding: 15px;
  width: 450px;
  color: white;
  font-size: 14px;
  line-height: 16px;
  font-family: "Inter";
  text-align: justify;
}

.tooltip-message-container::after {
  position: absolute;
  border: 5px solid;
  width: 0;
  height: 0;
  content: "";
}

.tooltip-message-container.top::after {
  bottom: -10px;
  left: calc(50% - 5px);
  border-color: #3e6fd9 transparent transparent transparent;
}

.tooltip-message-container.bottom::after {
  top: -10px;
  left: calc(50% - 5px);
  border-color: transparent transparent #3e6fd9 transparent;
}

.tooltip-message-container.right::after {
  top: calc(50% - 5px);
  left: -10px;
  border-color: transparent #3e6fd9 transparent transparent;
}

.tooltip-message-container.left::after {
  top: calc(50% - 5px);
  right: -10px;
  border-color: transparent transparent transparent #3e6fd9;
}

.tooltip-inner-text {
  border-bottom: 1px dotted #242c5c;
}

.tooltip-inner-text-white {
  border-bottom: 1px dotted white;
}

.tooltip-inner-text-blue {
  border-bottom: 1px dotted #3e6fd9;
}

.tooltip-inner-text-red {
  border-bottom: 1px dotted #f1666a;
}

.width-fit-content {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.custom-checkbox {
  border: 2px solid #cdd1e5;
  border-radius: 4px;
  background-color: white;
  width: 21px;
  min-width: 21px;
  height: 21px;
  min-height: 21px;
  -webkit-user-select: none;
          user-select: none;
}

.custom-checkbox img {
  width: 16px;
  height: 16px;
  pointer-events: none;
}

.custom-checkbox.checked {
  border: 2px solid #3e6fd9;
  background-color: #3e6fd9;
}

.custom-checkbox.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.custom-checkbox.red {
  border: 2px solid #f1666a;
}

.custom-input {
  outline: none !important;
  border: 2px solid #eceef5;
  border-radius: 4px;
  padding: 0 16px;
  width: 100%;
  height: 43px;
  color: #465664;
  font-size: 16px;
}

.custom-input-label-default {
  align-self: flex-start;
  color: #465664;
  font-size: 16px;
  text-align: start;
}

.custom-input:hover {
  border: 2px solid #9eb6ec;
}

.custom-input:focus {
  border: 2px solid #9eb6ec;
}

.custom-input.empty {
  background-color: #f6f7f9;
}

.custom-input.disabled {
  background-color: #d9dce2;
}

.custom-input.has-errors {
  border: 2px solid #f1666a;
}

.custom-input-errors {
  border-radius: 0 0 4px 4px;
  background-color: #f6e2e5;
  width: 100%;
  height: 41px;
  min-height: 41px;
}

.custom-input-errors span {
  height: 23px;
  color: #f1666a;
  font-size: 17px;
}

.custom-number-input {
  -webkit-appearance: textfield;
  appearance: textfield;
  border: 2px solid #eceef5;
  border-radius: 4px;
  padding: 0 32px 0 30px;
  width: 110px;
  height: 43px;
  color: #465664;
  font-size: 16px;
}

.custom-number-input::-webkit-inner-spin-button,
.custom-number-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.custom-input-container {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.custom-input-container.disabled {
  opacity: 0.5;
}

.custom-number-input-arrows {
  position: absolute;
  top: 0;
  right: 0;
  border: 2px solid #eceef5;
  border-radius: 0 4px 4px 0;
  width: 35px;
  height: 43px;
}

.triangle-icon-up {
  border: 5px solid;
  border-color: transparent transparent #888da8 transparent;
  width: 0;
  height: 0;
}

.triangle-icon-down {
  border: 5px solid;
  border-color: #888da8 transparent transparent transparent;
  width: 0;
  height: 0;
}

.arrow-icon.up {
  border-bottom: 2px solid #eceef5;
}

.arrow-icon {
  width: 100%;
  height: 21px;
}

.custom-number-input-label {
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 4px 0 0 4px;
  background-color: white;
  padding: 0 2px;
  width: 74px;
  height: 39px;
  overflow: hidden;
  pointer-events: none;
  color: #465664;
  font-size: 16px;
  -webkit-user-select: none;
          user-select: none;
}

.custom-radio-input {
  flex: 1 1;
  margin-right: 10px;
  border: 2px solid transparent;
  border-radius: 4px;
  background-color: #eceef5;
  padding-left: 10px;
  min-width: 80px;
  height: 39px;
}

.custom-radio-input:last-of-type {
  margin-right: 0;
}

.custom-radio-input span {
  margin-left: 5px;
  height: 23px;
  color: #888da8;
  font-size: 15px;
  font-family: "Inter";
}

.radio-button-icon {
  margin-left: 10px;
  border: 2px solid #cdd1e5;
  border-radius: 10px;
  background-color: #ffffff;
  width: 16px;
  min-width: 16px;
  height: 16px;
}

.custom-radio-input img {
  margin-left: 10px;
  width: 16px;
  height: 16px;
}

.show-radio-border {
  border: 2px solid #cdd1e5;
}

.custom-radio-input.active {
  border: 2px solid #3e6fd9;
}

.custom-radio-input.active span {
  color: #242c5c;
}

.transparent {
  background-color: transparent;
}

.custom-radio-input.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.custom-textarea {
  border: 2px solid #eceef5;
  border-radius: 4px;
  padding: 5px 16px 0;
  width: 100%;
  height: 96px;
  resize: none;
  color: #465664;
  font-size: 16px;
}

.custom-textarea-label,
.custom-textarea-label-default {
  align-self: flex-start;
  color: #465664;
  font-weight: 500;
  font-size: 16px;
}

custom-textarea-label-default {
  margin-bottom: 5px;
}

.custom-textarea-label {
  margin-bottom: 15px;
}

.custom-textarea:hover {
  border: 2px solid #9eb6ec;
}

.custom-textarea:focus {
  border: 2px solid #9eb6ec;
}

.custom-textarea.empty {
  background-color: #f6f7f9;
}

.custom-textarea::-webkit-input-placeholder {
  color: #75798d;
}

.custom-textarea::placeholder {
  color: #75798d;
}

.PromoCodeModal_radio-input__1-dDR {
  width: 100%;
  height: 39px;
}

.promocode-dialog-wrapper {
  position: relative;
  border-radius: 4px;
  width: 622px;
  height: 640px;
  overflow: hidden;
  font-family: "Inter";
}

.promocode-dialog-header {
  justify-content: space-between;
  align-items: center;
  background-color: #eceef5;
  padding: 0 28px;
  width: 100%;
  height: 85px;
}

.promocode-dialog-title {
  height: 33px;
  color: #242c5c;
  font-size: 26px;
  font-family: "Inter";
}

.promocode-dialog-body {
  padding: 26px 32px;
  width: 100%;
  height: calc(100% - 85px);
}

.promocode-dialog-buttons {
  position: absolute;
  right: 32px;
  bottom: 17px;
}

.promocode-dialog-buttons {
  position: absolute;
  right: 32px;
  bottom: 17px;
}

.promocode-dialog-button {
  outline: none !important;
  border: none;
  border-radius: 4px;
  min-width: 180px;
  height: 48px;
  font-size: 14px;
}

.promocode-dialog-button.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.promocode-dialog-label {
  height: 24px;
  color: #465664;
  font-size: 16px;
}

.mr-20 {
  margin-right: 20px;
}

.promocode-input-container {
  align-items: flex-start;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.mr-30 {
  margin-right: 30px;
}

.promocode-dialog-label.error-message {
  color: #ce3030;
}

.promocode-copy-button {
  margin-left: 10px;
  outline: none !important;
  border: none;
  border-radius: 4px;
  min-width: 50px;
  height: 25px;
  font-size: 14px;
}

.custom-toggle-container {
  position: relative;
  width: 40px;
  height: 20px;
}

.custom-toggle-container.disabled {
  opacity: 0.5;
}

.custom-toggle-rectangle {
  border-radius: 10px;
  background-color: #c3c5d3;
  width: 34px;
  height: 14px;
}

.custom-toggle-circle {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  background-color: #888da8;
  width: 20px;
  height: 20px;
}

.custom-toggle-circle.active {
  right: 0px;
  left: unset;
  background-color: #3e6fd9;
}

.custom-toggle-rectangle.active {
  background-color: #9db6ed;
}

.custom-toggle-label-default {
  margin-left: 30px;
  height: 24px;
  color: #465664;
  font-weight: 500px;
  font-size: 14px;
  font-family: "Inter";
  -webkit-user-select: none;
          user-select: none;
}

.custom-toggle-label-default.active {
  color: #3e6fd9;
}

.custom-toggle-label-default.disabled {
  opacity: 0.5;
}

.delete-popup {
  border-radius: 4px;
  background-color: #ffffff;
  padding: 20px;
  width: 472px;
  height: 207px;
  overflow: hidden;
  font-family: "Inter";
}

.delete-popup button {
  border: none;
  border-radius: 4px;
  background-color: #3e6fd9;
  width: 357px;
  height: 56px;
  color: #fff;
}

.delete-popup button span {
  height: 30px;
  font-size: 22px;
  text-transform: uppercase;
}

.delete-popup-close {
  justify-content: flex-end;
  min-height: 20px;
}

.delete-popup-text {
  margin-bottom: 24px;
  margin-bottom: 10px;
  width: 271px;
  height: 55px;
  color: #465664;
  font-size: 21px;
  line-height: 26px;
}

.delete-popup-button {
  outline: none !important;
}

.delete-popup {
  border-radius: 4px;
  background-color: #ffffff;
  padding: 20px;
  width: 472px;
  height: 207px;
  overflow: hidden;
  font-family: "Inter";
}

.delete-popup-buttons-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.delete-popup button {
  border: none;
  border-radius: 4px;
  background-color: #3e6fd9;
  width: 175px;
  height: 56px;
  color: #fff;
}

.delete-popup button span {
  height: 30px;
  font-size: 22px;
  text-transform: uppercase;
}

.delete-popup-close {
  justify-content: flex-end;
  min-height: 20px;
}

.delete-popup-text {
  margin-bottom: 24px;
  width: 271px;
  height: 55px;
  color: #465664;
  font-size: 21px;
  line-height: 26px;
}

.yes-popup-button {
  outline: none !important;
}

.no-popup-button {
  outline: none !important;
}

.user-dialog-wrapper {
  position: relative;
  border-radius: 4px;
  width: 622px;
  height: 710px;
  overflow: hidden;
  font-family: "Inter";
}

.user-dialog-header {
  justify-content: space-between;
  align-items: center;
  background-color: #eceef5;
  padding: 0 28px;
  width: 100%;
  height: 85px;
}

.user-dialog-title {
  height: 33px;
  color: #242c5c;
  font-size: 26px;
  font-family: "Inter";
}

.user-dialog-body {
  padding: 26px 32px;
  width: 100%;
  height: calc(100% - 85px);
}

.mr-20 {
  margin-right: 20px;
}

.modal-user-change-pass-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.modal-user-change-password {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.modal-users-button {
  margin-top: 24px;
  margin-left: 30px;
  outline: none !important;
  border: none;
  border-radius: 4px;
  background-color: #3e6fd9;
  width: 230px;
  height: 42px;
}

.modal-users-button span {
  height: 24px;
  color: #ffffff;
  font-size: 18px;
  font-family: "Inter";
}

.user-modal-change-roles {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.user-modal-change-features {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

.modal-user-info-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  color: #242c5c;
  font-size: 18px;
}

.feature-hover-style {
  margin-left: 30px;
  border-bottom: 1px dotted #242c5c;
}

.custom-scrollbar-scrollable-container::-webkit-scrollbar {
  display: none;
}

.custom-scrollbar-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.custom-scrollbar-scrollable-container {
  position: relative;
  height: 100%;
  overflow: auto;

  scrollbar-width: none;
  -ms-overflow-style: none;
}

.custom-scrollbar {
  position: absolute;
  top: 0px;
  right: 0;
  bottom: 0px;
  border-radius: 7px;
  width: 7px;
  height: 100%;
}

.custom-scrollbar-handle {
  position: absolute;
  top: 0;
  border-radius: 7px;
  width: 7px;
  height: 20px;
}

.custom-dropdown-container {
  position: relative;
  border-radius: 4px;
  width: 191px;
  height: 41px;
  -webkit-user-select: none;
          user-select: none;
}

.custom-dropdown-options-container {
  font-family: "Inter";
}

.custom-dropdown-options {
  position: absolute;
  z-index: 300;
  border: 2px solid #cdd1e5;
  border-top: none;
  border-radius: 0 0 4px 4px;
  background-color: #f7f8fa;
  width: 191px;
  height: auto;
  overflow: hidden;
}

.custom-dropdown-container span {
  height: 22px;
  color: #242c5c;
  font-size: 15px;
  -webkit-user-select: none;
          user-select: none;
}

.custom-dropdown-triangle-icon {
  position: absolute;
  top: 19px;
  right: 10px;
  border: 5px solid;
  border-color: #858aa6 transparent transparent transparent;
}

.dependency-indicator {
  margin-right: 10px;
  border-radius: 4px;
  background-color: #cdd1e5;
  width: 19px;
  height: 19px;
}

.dependency-indicator.unaddressed {
  border: 2px solid #cdd1e5;
}

.custom-dropdown-container-option {
  height: 39px;
}

.custom-dropdown-container-option.first {
  border: 2px solid #cdd1e5;
  border-radius: 4px 4px 4px 4px;
  height: 41px;
}

.custom-dropdown-container-option.first.open {
  border: 2px solid #cdd1e5;
  border-bottom: 2px solid #eceef5;
  border-radius: 4px 4px 0 0;
}

.custom-dropdown-container-option:not(.first) {
  border-bottom: 2px solid #eceef5;
}

.custom-dropdown-container-option:last-of-type {
  border-bottom: none;
}

.custom-dropdown-container-option:hover {
  background-color: #ffffff;
}

.custom-dropdown-container-option.active {
  background-color: white;
}

.custom-dropdown-option {
  padding: 0 12px;
}

.benefit-option img {
  margin-right: 6px;
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
}

.dnd-label {
  margin-bottom: 13px;
  height: 24px;
  color: #465664;
  font-weight: 500;
  font-size: 16px;
}

.dnd {
  border: 2px dashed rgba(62, 111, 217, 0.44);
  border-radius: 4px;
  background-color: #f7f8fa;
  width: 100%;
  height: 107px;
  font-family: "Inter";
}

.dnd.preview {
  border: none;
}

.dnd-text {
  height: 20px;
  color: #465664;
  font-size: 16px;
  line-height: 24px;
}

.drop-zone {
  width: 100%;
  height: 100%;
}

.light-blue {
  color: #3e6fd9;
}

.dnd-bottom-text {
  color: #888da8;
  font-size: 14px;
}

.dnd.dnd-progress {
  margin-top: 10px;
  border: none;
  height: 47px;
}

.dnd-cancel-icon {
  margin: 0 15px;
  width: 12px;
  height: 12px;
}

.dnd-progress-bar {
  border-radius: 8px;
  background-color: #cdd1e5;
  width: 59px;
  height: 7px;
}

.dnd-progress-bar.filled {
  background-color: #3e6fd9;
}

.dnd-filename span {
  height: 22px;
  color: #242c5c;
  font-size: 14px;
}

.dnd-filename {
  margin-left: 15px;
}

.dnd-filename svg {
  margin-right: 10px;
}

.dnd-file-info span {
  color: #888da8;
  font-size: 11px;
  line-height: 13px;
  text-align: right;
}

.display-none {
  display: none;
}

.dnd-image-preveiw img {
  max-width: 100%;
  height: 100%;
  max-height: 100%;
}

.dnd-preview-label {
  margin-bottom: 0;
}

.global-configurations-wrapper {
  font-family: "Inter";
}

.admin-url-input-container {
  width: 560px;
}

.download-error-message {
  color: #465664;
  font-size: 35px;
}

.global-configuration-container {
  align-items: stretch;
  margin-right: 30px;
  border: 2px solid #cdd1e5;
  border-radius: 4px;
  padding: 20px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 360px;
}

.global-configuration-label {
  height: 32px;
  color: #465664;
  font-size: 24px;
}

.global-configurations-button {
  outline: none !important;
  border: none;
  border-radius: 4px;
  background-color: #3e6fd9;
  width: 100%;
  height: 48px;
}

.global-configurations-button span,
.global-configurations-small-button span,
.global-configurations-remove-button span {
  height: 24px;
  color: #ffffff;
  font-size: 18px;
  font-family: "Inter";
}

.global-configurations-small-button,
.global-configurations-remove-button {
  margin-top: 10px;
  margin-left: 10px;
  outline: none !important;
  border: none;
  border-radius: 4px;
  background-color: #3e6fd9;
  width: 186px;
  height: 48px;
}

.global-configurations-remove-button {
  background-color: #ed5e68;
}

.global-configurations-remove-button:disabled {
  transition: all 0.15s ease-in;
  background-color: rgb(237, 94, 104, 0.5);
}

.global-configurations-small-button:disabled {
  transition: all 0.15s ease-in;
  background-color: rgba(62, 111, 217, 0.5);
}

.global-configurations-filename-span {
  width: 60%;
  overflow: hidden;
  font-size: 16px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.admin-url-input-container__dropdown {
  position: absolute;
}

.issue-box {
  width: 100%;
}

.issue-side {
  position: relative;
  width: 100px;
  min-height: 120px;
}
.issue-status {
  position: absolute;
  top: 10px;
  left: 10px;
  margin: 18px 15px;
  border-radius: 50%;
  width: 54px;
  height: 54px;
  font-size: 35px;
  font-family: "Inter";
  -webkit-user-select: none;
          user-select: none;
}
.issue-fixed {
  border-color: #5ac57a;
  background-color: #5ac57a;
  color: white;
}
.issue-ongoing {
  border-color: #ffcd67;
  background-color: #ffcd67;
  color: white;
}
.issue-line-container {
  width: 100%;
  height: 100%;
}
.issue-line {
  position: relative;
  left: 51px;
  background-color: #242c5c;
  width: 2px;
  height: 100%;
}

.issue-title {
  font-size: 22px;
  font-family: "Inter";
  text-align: left;
}
.issue-date {
  font-size: 16px;
  font-family: "Inter";
}

.issue-text-body {
  font-family: "Inter";
  text-align: justify !important;
}

.issue-info-container {
  padding-top: 25px;
  width: 100%;
}

.withPointer {
  cursor: pointer;
}

.admin-delete-btn {
  position: absolute;
  top: 90px;
  left: 18px;
  z-index: 2;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #f0383e;
  padding: 5px;
  width: 68.5px;
  overflow: hidden;
  color: #fff;
  font-family: "Inter";
}

.admin-delete-btn:disabled {
  background-color: grey;
}

.issues-tab {
  padding-bottom: 60px;
  overflow: hidden;
}
.issue-create-container {
  border: 2px solid #cdd1e5;
  border-radius: 10px;
  padding: 10px;
}

.button-create {
  margin-top: 10px;
  margin-left: 10px;
  outline: none !important;
  border: none;
  border-radius: 4px;
  background-color: #3e6fd9;
  width: 192px;
  height: 48px;
  color: white;
}
.button-create:disabled {
  background-color: grey;
}
.issue-display {
  margin-top: 2em;
}

.information-modal {
  border-radius: 4px;
  background-color: #ffffff;
  padding: 20px;
  width: 500px;
  height: auto;
  overflow: hidden;
  font-family: "Inter";
}

.information-modal button {
  border: none;
  border-radius: 4px;
  background-color: #3e6fd9;
  width: 100%;
  height: 56px;
  color: #fff;
}

.information-modal button span {
  height: 30px;
  font-size: 22px;
  text-transform: uppercase;
}

.information-modal-close {
  justify-content: flex-end;
  min-height: 20px;
}

.information-modal-text {
  margin-bottom: 10px;
  width: 271px;
  height: 55px;
  color: #465664;
  font-size: 22px;
  line-height: 26px;
}

.information-modal-button {
  outline: none !important;
  border: none;
  border-radius: 4px;
  background-color: #3e6fd9;
  width: 100%;
  height: 48px;
}
.information-modal-button:disabled {
  opacity: 0.5;
  transition: all 0.2s ease;
}

.information-modal-button span {
  height: 24px;
  color: #ffffff;
  font-size: 18px;
  font-family: "Inter";
}

.information-modal-button-contener {
  width: 100%;
}

.manage-user-wrapper {
  font-family: "Inter";
}

.admin-password-container {
  width: 300px;
}

.manage-user-container {
  align-items: stretch;
  margin-right: 30px;
  border: 2px solid #cdd1e5;
  border-radius: 4px;
  padding: 20px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 385px;
}

.manage-user-label {
  color: #465664;
  font-size: 22px;
}

.manage-users-button {
  outline: none !important;
  border: none;
  border-radius: 4px;
  background-color: #3e6fd9;
  width: 100%;
  height: 48px;
}

.manage-users-button span {
  height: 24px;
  color: #ffffff;
  font-size: 18px;
  font-family: "Inter";
}

.ManageSubscription_header__1oi2l {
  color: #242c5c;
  font-size: 26px;
}

.ManageSubscription_promo-code__2uOVZ {
  margin-top: 22px;
  background-color: #e2eafc;
  height: 76px;
}

.ManageSubscription_promo-code__2uOVZ span {
  padding-right: 40px;
  padding-left: 33px;
  color: #242c5c;
  font-weight: 500;
  font-size: 20px;
}

.ManageSubscription_promo-code-button__YZSfV {
  margin-left: 5px;
  width: 90px;
  color: #3e6fd9;
  font-weight: 500;
}

.ManageSubscription_promo-code-button__YZSfV:hover {
  color: white;
}

.ManageSubscription_promo-code-field__2H2u0 {
  border: 2px #9eb6ec solid;
  width: 215px;
  font-weight: 500;
}

.ManageSubscription_promo-code-field__2H2u0::-webkit-input-placeholder {
  color: #3e6fd9;
  font-weight: 500;
}

.ManageSubscription_promo-code-field__2H2u0::placeholder {
  color: #3e6fd9;
  font-weight: 500;
}

.ManageSubscription_promo-code-field__2H2u0:focus,
.ManageSubscription_promo-code-field__2H2u0:hover {
  border-color: #3e6fd9;
}

.ManageSubscription_subscription_block__3UGAe {
  margin-top: 20px;
  border-width: 1px;
  border-style: solid;
  border-color: #c6cbed;
  border-radius: 5px;
  padding: 28px 33px;
  min-height: 310px;
}

.ManageSubscription_header-active-subscription__2MWld {
  display: flex;
  color: #242c5c;
  font-size: 20px;
  line-height: 22px;
  text-align: left;
  text-align: start;
}

.ManageSubscription_body-active-subscription__20Nz2 {
  margin-top: 20px;
  min-height: 220px;
}

.ManageSubscription_left-body-active-subscription__1i7qC {
  min-width: 255px;
}

.ManageSubscription_active-plan-header__1O5Pv {
  display: flex;
  color: #242c5c;
  font-weight: 500;
  font-size: 18px;
  text-align: left;
  text-align: start;
}

.ManageSubscription_active-plan-item__1lZ4Z {
  min-width: 240px;
  color: #242c5c;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  list-style-type: "– ";
  text-align: left;
}

.ManageSubscription_active-plan-items__gNaiX {
  margin-top: 10px;
  padding-left: 13px;
}

.ManageSubscription_credits-header__6eSAb {
  color: #242c5c;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
}

.ManageSubscription_credits__2vrtL {
  margin-top: 20px;
  color: #242c5c;
  font-weight: 500;
  font-size: 30px;
  line-height: 22px;
  text-align: left;
}

.ManageSubscription_credits-details__2AiXB {
  margin-top: 7px;
  width: 120px;
  color: #242c5c;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
}

.ManageSubscription_credits-buy__1DIlM {
  margin-top: 29px;
  border-width: 1px;
  border-style: solid;
  border-color: #f1666a;
  border-radius: 3px;
  background: #fde8e9;
  padding-left: 12px;
  width: 205px;
  height: 43px;
  color: #f1666a;
  font-weight: 500;
  font-size: 16px;
  text-align: left;
}

.ManageSubscription_mb-32__2MFNs {
  margin-bottom: 32px;
}

.ManageSubscription_mt-20__2wWYN {
  margin-top: 20px;
}

.ManageSubscription_credits-buy__1DIlM:hover {
  background-color: #cc4b4f;
  color: white;
}

.ManageSubscription_billing-description__FyBaH {
  width: 140px;
  color: #242c5c;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  text-align: left;
}

.ManageSubscription_billing-description-right__1W-22 {
  margin-left: -26px;
  width: 140px;
  color: #242c5c;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  text-align: right;
}

.ManageSubscription_active-plan-body__301CM {
  margin-top: 16px;
}

.ManageSubscription_get-billing-details__3_wPZ {
  margin-top: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #3e6fd9;
  border-radius: 3px;
  background: #3e6fd9;
  width: 236px;
  height: 43px;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  text-align: left;
}

.ManageSubscription_cancel-subscription-link__35p-- {
  display: flex;
  margin-top: 10px;
  color: #3e6fd9 !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  text-align: start;
}

.ManageSubscription_cancel-subscription-link__35p--:hover {
  cursor: pointer;
}

.ManageSubscription_border-active-subscription__WjYLA {
  border-left: 2px #eceef5 solid;
  padding-left: 25px;
}

.ManageSubscription_border-ai-credits__1HH0T {
  border: 1px #c6cbed solid;
  border-radius: 5px;
  padding: 25px;
}

.ManageSubscription_padding-credits__2nq-p {
  padding-right: 30px;
}

.ManageSubscription_padding-15__jhpdq {
  padding: 15px;
}

.ManageSubscription_bulling-button-dark-blue__22Kc- {
  border-width: 1px;
  border-style: solid;
  border-color: #3e6fd9;
  border-radius: 3px;
  background: #3e6fd9;
  width: 168px;
  height: 43px;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  text-align: left;
}

.ManageSubscription_bulling-button-blue__5dnOB {
  display: flex;
  justify-content: center;
  margin-left: 5px;
  padding: 0;
  width: 168px;
  height: 43px;
  color: #3e6fd9;
  font-weight: 500;
}

.ManageSubscription_bulling-button-blue__5dnOB:hover {
  color: white;
}

.ManageSubscription_billing-left-button__307A4 {
  margin-left: -20px;
}

.ManageSubscription_billing-right-button__2cK8k {
  margin-left: 0 !important;
}

.ManageSubscription_buy-subscription__3icJY {
  margin-top: 27px;
}

.ManageSubscription_page__1JSTO {
  min-width: 1200px;
}

.ManageSubscription_billing-left-blue__2SILR {
  margin-left: -5px;
}

.ManageSubscription_ml-10__147gJ {
  margin-left: 10px;
}

.ManageSubscription_plan__3bs-i {
  -webkit-filter: drop-shadow(0px 1px 0.5px #c6cbed);
          filter: drop-shadow(0px 1px 0.5px #c6cbed);
  margin-top: 10px;
  margin-bottom: 20px;
  border-width: 1px;
  border-style: solid;
  border-color: #c6cbed;
  border-radius: 5px;
  background: #ffffff;
  padding: 27px 36px 27px;
  width: 335px;
  min-height: 540px;
}

.ManageSubscription_plan-header__DUrqt {
  color: #242c5c;
  font-style: normal;
  font-weight: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  text-align: left;
}

.ManageSubscription_plan-amount__2jqE_ {
  color: #242c5c;
  font-weight: 500;
  font-size: 30px;
  line-height: 22px;
  text-align: left;
}

.ManageSubscription_plan-period__2yC48 {
  margin-left: 12px;
  color: #242c5c;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
}

.ManageSubscription_plan-prev__3ppp_ {
  margin-top: 14px;
}

.ManageSubscription_plan-billed__qDXIW {
  margin-top: 4px;
  color: #242c5c;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
}

.ManageSubscription_plan-buy__8c3CA {
  margin-top: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: #3e6fd9;
  background: #3e6fd9;
  width: 168px;
  min-width: 100%;
  height: 43px;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  text-align: left;
}

.ManageSubscription_get-billing-details__3_wPZ:hover,
.ManageSubscription_plan-buy__8c3CA:hover {
  background-color: #3061ae;
  color: white;
}

.ManageSubscription_plan-descriptions__27sgr {
  margin-top: 20px;
  margin-left: -25px;
}

.ManageSubscription_plan-credits-description__2RoOS {
  display: flex;
  width: 270px;
  color: #242c5c;
  font-style: normal;
  font-weight: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
}

.ManageSubscription_select-amount__3i2LG {
  margin-top: 10px;
  padding-left: 27px;
  font-weight: 500;
}

.ManageSubscription_select-amount__3i2LG:hover {
  border-color: #f1666a;
  background-color: #fde8e9;
  color: #f1666a;
}

.ManageSubscription_plan-credits-buy__2cRE5 {
  border-top: 2px #c6cbed solid;
  padding-top: 15px;
}

.ManageSubscription_select-credits__3xA82 {
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-color: #f1f1f1;
  background: #fafafa;
  padding-right: 20px;
  padding-left: 17px;
  height: 39px;
  color: #242c5c;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 40px;
  text-align: left;
}

.ManageSubscription_select-credits__3xA82:hover {
  cursor: pointer;
  background-color: #ffffff;
}

button:active,
button:focus {
  outline: none;
}
button::-moz-focus-inner {
  border: 0;
}

.ManageSubscription_select-credits-block__31kKh {
  position: absolute;
  bottom: 69px;
  -webkit-filter: drop-shadow(0px -3px 4px rgba(0, 0, 0, 0.16));
          filter: drop-shadow(0px -3px 4px rgba(0, 0, 0, 0.16));
  width: 261px;
}

.ManageSubscription_select-amount-active__3fDEm {
  display: flex;
  align-items: center;
  -webkit-filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16));
          filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16));
  border-width: 1px;
  border-style: solid;
  border-color: #f1666a;
  border: 0;
  border-radius: 3px;
  background: #fde8e9;
  background-color: #fafafa;
  width: 250px;
  height: 43px;
}

.ManageSubscription_select-amount-active__3fDEm:hover {
  background-color: #fafafa;
  color: #242c5c;
}

.ManageSubscription_arrow-icon__2DWK9 {
  position: relative;
  width: 30px;
  height: 42px;
}

.ManageSubscription_select-credits-label__32i9l {
  display: flex;
  justify-content: space-between;
  width: 195px;
}

.Plans_plan-dialog__3HO3W {
  width: 348px;
  height: 308px;
}

.Plans_header-dialog__2q5Jx {
  font-weight: 500;
  font-size: 24px;
}

.Plans_dialog-close__1vcnS {
  width: 22px;
}

.Plans_top-dialog__3-OUa {
  background-color: #eceef5;
  padding: 30px;
  height: 90px;
}

.Plans_modal-adding-part__1LDQG {
  padding: 30px;
  padding-top: 16px;
  height: 218px;
}

.Plans_number-field__1YcDn {
  margin-top: 20px;
  margin-left: 20px;
}

.Plans_type-plan__2Mh5s {
  width: 371px;
}

.Plans_plan-subscription__36Dj1 {
  margin-top: 20px;
  background-color: white;
  padding: 20px 30px;
  width: 300px;
  min-height: 500px;
}

.Plans_plan-tokens-dialog__1Y8uw {
  width: 346px;
  height: 308px;
}

.Plans_modal-adding-tokens-part__2CZeB {
  padding: 30px;
  padding-top: 15px;
  height: 220px;
}

.Plans_plan-monthly__gLHTA {
  margin-left: 20px;
  font-weight: 100;
  font-size: 14px;
}

.Plans_small-description-input__3_9Ok {
  margin-left: -15px;
  border: 0 !important;
  background-color: #f6f7f9 !important;
  padding-left: 15px !important;
  width: 256px !important;
  height: 26px !important;
  color: #242c5c !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 26px !important;
  text-align: left;
}

.Plans_save-description__1GDbF {
  position: absolute;
  left: 286px;
  outline: 0 !important;
  border: 0;
  background-color: #3e6fd9;
  width: 22px;
  height: 26px;
}

.Plans_save-description__1GDbF:hover {
  background-color: #3061ae;
}

.Plans_active-plan-item__2KESv:hover {
  cursor: pointer;
  color: #3e6fd9;
}

.Plans_plan__21mG5 {
  margin-bottom: 50px;
}

.Plans_plus-icon__354zv {
  position: absolute;
  width: 18px;
}

.Plans_close-description__1e1X2 {
  position: absolute;
  left: 264px;
  z-index: 2;
  outline: 0 !important;
  border: 0;
  background-color: #fde8e9;
  width: 22px;
  height: 26px;
}

.Plans_close-description__1e1X2:hover {
  fill: white !important;
  background-color: #cc4b4f;
}

.Plans_close-description__1e1X2:hover .Plans_icon-close__32v56 {
  fill: white;
}

.Plans_icon-close__32v56 {
  fill: #f1666a;
}

.Plans_remove-description-field__dA604 {
  margin-right: 0 !important;
  border-radius: 0;
  width: 130px !important;
  height: 43px !important;
  min-height: 43px !important;
  max-height: 43px !important;
  font-weight: 500 !important;
}

.Plans_add-description-field__2_AR1 {
  margin-top: 0 !important;
  min-width: 130px !important;
  max-width: 130px !important;
}

.custom-scrollbar-scrollable-container::-webkit-scrollbar {
  display: none;
}

.custom-scrollbar-container {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.custom-scrollbar-scrollable-container {
  position: relative;
  height: 100%;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.custom-scrollbar {
  position: absolute;
  top: 0px;
  right: 0;
  bottom: 0px;
  border-radius: 7px;
  width: 7px;
  height: 100%;
}

.custom-scrollbar-handle {
  position: absolute;
  top: 0;
  border-radius: 7px;
  width: 7px;
  height: 20px;
}

.select-triangle-icon.scrollbar-arrow {
  position: absolute;
  top: -23px;
  left: -28px;
}

.prefab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  border: 1px solid #cdd1e5;
  padding: 15px 15px;
  width: 100%;
}

.prefab_deleted {
  background-color: #c2d4fa;
}

.prefab-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 15px;
  width: 100%;
}

.prefab-box-text {
  text-align: left;
  word-break: break-all;
}

.prefab-box__textarea {
  width: 100%;
}

.prefab-box-info p {
  margin-right: 10px;
  margin-bottom: 0;
  color: #a6a6a6;
  font-size: 12px;
}

.prefab-button {
  border: 2px solid #3e6fd9;
  border-radius: 5px;
  background-color: #f8f9fa;
  min-width: 120px;
  height: 40px;
  color: #3e6fd9;
}

.prefab-button_deleted {
  background-color: #3e6fd9;
  color: #f8f9fa;
}

.prefab-button:hover {
  background-color: #3e6fd9;
  color: #fff;
}

.prefab-button.disabled {
  border: 2px solid #a6a6a6;
  color: #a6a6a6;
}

.prefab-button.disabled:hover {
  border: 2px solid #a6a6a6;
  background-color: #a6a6a6;
  color: #fff;
}

.add-new-prefab-modal {
  position: relative;
  justify-content: space-between;
  border-radius: 4px;
  width: 50vw;
  height: 50vh;
  font-family: "Inter";
}

.add-new-prefab-modal-header {
  justify-content: space-between;
  align-items: center;
  background-color: #eceef5;
  padding: 25px 25px;
}

.add-new-prefab-modal-header h2 {
  margin: 0;
  color: #242c5c;
  font-weight: 400;
  font-size: 30px;
}

.add-new-prefab-modal-body {
  margin: 0 25px;
}

.add-new-prefab-modal-body__container {
  margin: 25px 0;
}

.add-new-prefab-modal-body__textarea {
  height: 60%;
}

.add-new-prefab-modal-footer {
  margin: 0 25px 25px 0;
}

.add-new-prefab-modal-footer-buttons__button {
  border: none;
  border-radius: 5px;
  width: 120px;
  height: 40px;
  font-size: 16px;
}

.add-new-prefab-modal-footer-buttons__button_save {
  background-color: #3e6fd9;
  color: white;
}

.add-new-prefab-modal-footer-buttons__button_save:hover {
  background-color: #3061ae;
}

.add-new-prefab-modal-footer-buttons__button_cancel {
  background-color: transparent;
  color: #888da8;
}

.text-loader {
  color: #9880ff;
  font-size: 16px;
}

.dot-flashing {
  position: relative;
  -webkit-animation: dot-flashing 1s infinite linear alternate;
          animation: dot-flashing 1s infinite linear alternate;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
  border-radius: 5px;
  background-color: #9880ff;
  width: 10px;
  height: 10px;
  color: #9880ff;
}

.dot-flashing::before,
.dot-flashing::after {
  display: inline-block;
  position: absolute;
  top: 0;
  content: "";
}

.dot-flashing::before {
  left: -15px;
  -webkit-animation: dot-flashing 1s infinite alternate;
          animation: dot-flashing 1s infinite alternate;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  border-radius: 5px;
  background-color: #9880ff;
  width: 10px;
  height: 10px;
  color: #9880ff;
}

.dot-flashing::after {
  left: 15px;
  -webkit-animation: dot-flashing 1s infinite alternate;
          animation: dot-flashing 1s infinite alternate;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
  border-radius: 5px;
  background-color: #9880ff;
  width: 10px;
  height: 10px;
  color: #9880ff;
}

@-webkit-keyframes dot-flashing {
  0% {
    background-color: #9880ff;
  }
  50%,
  100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}

@keyframes dot-flashing {
  0% {
    background-color: #9880ff;
  }
  50%,
  100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}

.answers-prefab-modal {
  width: 622px;
  height: 662px;
}

.answers-prefab-modal-header {
  justify-content: space-between;
  align-items: center;
  border-radius: 4px 4px 0 0;
  background-color: #fce8e9;
  padding: 10px 28px;
  height: 13%;
}

.answers-prefab-modal-header h2 {
  margin: 0;
  color: #cc4b4f;
  font-size: 1.6rem;
}

.answers-prefab-modal-list {
  margin: 20px;
}

.answers-prefab-modal-list__name {
  margin: 0 0 15px 0;
  border: 2px solid #eceef5;
  border-radius: 4px;
  padding: 5px 25px;
  width: 100%;
}

.answers-prefab-modal-list__name p {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 15px 0;
  min-height: 9vh;
  font-weight: 500;
  font-size: 20px;
  text-align: left;
}

.answers-prefab-modal__button {
  margin: 0 20px 10px;
  border: none;
  border-radius: 4px;
  background-color: #3e6fd9;
  height: 10%;
  color: #fff;
  font-size: 20px;
}

.error-message-modal {
  position: relative;
  justify-content: space-between;
  border-radius: 4px;
  width: 50vw;
  height: 35vh;
  font-family: "Inter";
}

.error-message-modal-header {
  justify-content: space-between;
  align-items: center;
  background-color: #eceef5;
  padding: 25px 25px;
}

.error-message-modal-header h2 {
  margin: 0;
  color: #242c5c;
  font-weight: 400;
  font-size: 30px;
}

.error-message-modal-body {
  margin: 0 25px;
}

.error-message-modal-text {
  font-size: 20px;
}

.error-message-modal-footer {
  margin: 0 0 25px 0;
}

.error-message-modal-footer__button {
  border: none;
  border-radius: 5px;
  background-color: #3e6fd9;
  width: 120px;
  height: 40px;
  color: white;
  font-size: 16px;
}

.progress-circle {
  position: relative;
}

.progress-circle svg {
  width: 100px;
  height: 100px;
}

.progress-circle circle {
  fill: transparent;
}

.progress-circle__circles {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.progress-circle__progress-dashes {
  stroke-linecap: round;
  stroke: #9880ff;
}

.progress-circle__background-dashes {
  stroke-linecap: round;
  stroke: #c8c8c8;
}

.progress-circle__body {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.generate-image-modal-header {
  justify-content: space-between;
  align-items: center;
  border-radius: 4px 4px 0 0;
  background-color: #fce8e9;
  padding: 10px 28px;
  height: 100px;
}

.generate-image-modal-header h2 {
  margin: 0 10px;
  color: #cc4b4f;
  font-size: 1.6rem;
}

.generate-image-modal__progress {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.generate-image-modal__body {
  top: 39px;
  left: 42px;
}

.generate-image-modal__text {
  margin: 0;
  color: #888da8;
}

.generate-image-modal__img {
  min-width: 300px;
  max-width: 80vw;
  min-height: 300px;
  max-height: 80vh;
}

.generate-image-modal__button {
  margin-top: 8px;
  border-width: 0px;
  border-radius: 16px;
  width: 80px;
  color: #ffffff;
}
.generate-image-modal__button_accept {
  background-color: #6ec988;
}
.generate-image-modal__button_decline {
  background-color: #d73245;
}
.generate-image-modal__buttons-container {
  display: flex;
  flex-direction: column;
}

.prefabs-chat-gtp {
  padding: 0 15px;
}

.prefabs-chat-gtp-header {
  font-size: 24px;
  text-align: left;
}

.prefabs-chat-gtp__optinon {
  margin-top: 20px;
}

.prefabs-chat-gtp__input {
  margin-right: 20px;
}

.prefabs-chat-gtp__textarea {
  margin-top: 20px;
  width: 50%;
}

.prefabs-chat-gtp-button {
  border: none;
  border-radius: 5px;
  width: 120px;
  height: 40px;
  font-size: 16px;
}

.prefabs-chat-gtp-button.add {
  background-color: #c2d4fa;
  color: #3e6fd9;
}

.prefabs-chat-gtp-button.add:hover {
  border: 2px solid #3e6fd9;
}

.prefabs-chat-gtp-button.save {
  margin-left: 20px;
  background-color: #3e6fd9;
  color: white;
}

.prefabs-chat-gtp-button.save:hover,
.prefabs-chat-gtp-button.delete:hover,
.prefabs-chat-gtp-button.test-button:hover {
  background-color: #3061ae;
}

.prefabs-chat-gtp-button.delete,
.prefabs-chat-gtp-button.test-button {
  margin-right: 20px;
  background-color: #3e6fd9;
  min-width: 180px;
  color: #fff;
}

.prefabs-chat-gtp-button.test-button {
  margin-left: 20px;
}

.prefabs-chat-gtp-button.delete:disabled {
  opacity: 0.5;
}

.prefabs-chat-gtp-delete-modal button {
  min-width: 240px;
}

.custom-search-input {
  position: relative;
}

.custom-search-input img {
  position: absolute;
  top: 10px;
  right: 17px;
}

.custom-search-input-input {
  outline: none;
  border: 2px solid #cdd1e5;
  border-radius: 4px;
  background-color: white;
  padding: 3px 40px 3px 14px;
  height: 41px;
  color: #242c5c;
  font-weight: 500;
  font-size: 14.5px;
  line-height: 40px;
  font-family: "Inter";
}

.custom-search-input-input::-webkit-input-placeholder {
  color: #242c5c;
}

.custom-search-input-input::placeholder {
  color: #242c5c;
}

.custom-search-input-input:hover {
  border: 2px solid #9eb6ec;
}

.custom-search-input-input:focus {
  border: 2px solid #9eb6ec;
}

.custom-search-input-input.empty {
  background-color: #f6f7f9;
}

.custom-search-input-input.empty:focus {
  background-color: white;
}

.custom-search-input-promo-codes {
  max-width: 280px;
}

.card-modal-container {
  position: fixed;
  top: 52px;
  z-index: 1000;
  border: 1px solid #c7ccec;
  border-radius: 4px;
  background-color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 155px;
  min-height: 33px;
  max-height: 265px;
  overflow: hidden;
  -webkit-user-select: none;
          user-select: none;
}

.search-bar {
  left: 270px;
  width: 20%;
}

.card-modal-container span {
  color: #242c5c;
  font-size: 15px;
  font-family: "Inter";
}

.card-modal-option {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eceef5;
  background-color: #f7f8fa;
  padding: 0 24px;
  height: 33px;
  min-height: 33px;
  overflow: hidden;
  color: #242c5c;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card-modal-option span {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card-modal-option:hover {
  background-color: white;
}

.card-modal-option:hover span {
  color: #3c6ad7;
}

.menu-items-container {
  overflow-y: auto;
}

.card-modal-search-input {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.card-modal-search-input .custom-search-input {
  max-width: unset;
}

.promo-codes-table-container {
  margin-top: 10px;
  height: 500px;
}

.promo-codes-header-container-header {
  margin: 10px 0 10px 0;
  padding: 0 20px 0 10px;
}

.promo-codes-header-row-header {
  margin-bottom: 2px;
  height: 22px;
}

.promo-codes-header-indicator {
  background-color: #cdd1e5;
  min-width: 7px;
  max-width: 7px;
  height: 51px;
}

.promo-codes-header-indicator.transparent {
  background-color: transparent;
}

.promo-codes-rows-container {
  border-radius: 4px;
  background-color: #ffffff;
  padding: 10px 20px 20px 10px;
  overflow-y: auto;
}

.promo-codes-row-text {
  max-width: calc(100% - 10px);
  height: 22px;
  overflow: hidden;
  color: #242c5c;
  font-size: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.promo-codes-row-text:hover,
.promo-codes-row-fullname-text:hover {
  position: absolute;
  border-right: 8px solid #eceef5;
  background-color: #eceef5;
}

.promo-codes-row.disabled .promo-codes-row-text:hover,
.promo-codes-row.disabled .promo-codes-row-fullname-text:hover {
  border-right: 8px solid #d6dbec;
  background-color: #d6dbec;
}

.promo-codes-row-fullname-text {
  height: 22px;
  overflow: hidden;
  color: #3e6fd9;
  font-size: 16px;
  font-family: "Inter";
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.promo-codes-header-row-date {
  flex: 1 1 110px;
  padding-left: 10px;
  min-width: 9%;
}

.promo-codes-header-row-created-by {
  flex: 1 1 160px;
  min-width: 13%;
}

.promo-codes-header-row-customer {
  flex: 1 1 160px;
  min-width: 13%;
}

.promo-codes-header-row-licenses {
  flex: 1 1 80px;
  min-width: 7%;
}

.promo-codes-header-row-duration-type {
  flex: 1 1 70px;
  min-width: 6%;
}

.promo-codes-header-row-duration-count {
  flex: 1 1 50px;
  min-width: 7%;
}

.promo-codes-header-row-price {
  flex: 1 1 90px;
  min-width: 7%;
}

.promo-codes-header-row-discount {
  flex: 1 1 60px;
  min-width: 7%;
}

.promo-codes-header-row-comments {
  flex: 1 1 160px;
  min-width: 15%;
}

.promo-codes-header-row-code {
  flex: 1 1 120px;
  min-width: 9%;
}

.promo-codes-header-row-auto-renewal {
  flex: 1 1 60px;
  min-width: 7%;
}

.promo-codes-button-container {
  position: absolute;
  right: 10px;
  min-width: 30px;
  min-height: 30px;
  -webkit-user-select: none;
          user-select: none;
}

.promo-codes-button-container:hover {
  opacity: 1;
  z-index: 100;
}

.promo-codes-menu-button {
  outline: none !important;
  border: none;
  border-radius: 4px;
  background-color: #f7f8fa;
  width: 30px;
  height: 30px;
}

.promo-codes-button-container .promo-codes-menu-button {
  opacity: 0;
}

.promo-codes-button-container:hover .promo-codes-menu-button {
  opacity: 1;
}

.promo-codes-row {
  margin-bottom: 2px;
  border: 2px solid transparent;
  border-radius: 4px;
  background-color: #f3f4f8;
  height: 51px;
}

.promo-codes-row:hover {
  border: 2px solid #3e6fd9;
  background-color: #eceef5;
}

.promo-codes-row div[class*="promo-codes-header-row-"] {
  padding-left: 10px;
}

.promo-codes-row.disabled {
  background-color: #d6dbec;
}

.promo-codes-text {
  max-width: calc(100% - 10px);
  height: 22px;
  overflow: hidden;
  color: #242c5c;
  font-size: 16px;
  -webkit-user-select: none;
          user-select: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.promo-codes-text:hover {
  position: absolute;
  border-right: 8px solid #f7f8fa;
  background-color: #f7f8fa;
}

.promo-codes-text.active {
  color: #3e6fd9;
}

.promo-codes-triangle-icon {
  margin-top: 5px;
  border: 5px solid;
  border-color: #3e6fd9 transparent transparent transparent;
  width: 0;
  height: 0;
}

.promo-codes-triangle-icon.asc {
  margin-top: 0px;
  margin: 0 0 5px;
  border-color: transparent transparent #3e6fd9 transparent;
}

.promo-code-button {
  margin-left: 20px;
  outline: none !important;
  border: none;
  border-radius: 4px;
  background-color: #3e6fd9;
  padding: 0 10px;
  width: auto;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  height: 41px;
}

.promo-code-button span {
  height: 19px;
  color: #ffffff;
  font-size: 15px;
  font-family: "Inter";
  text-transform: uppercase;
}

.promo-codes-inputs .promo-codes-input-container {
  margin: 0px 0 10px 20px;
  white-space: nowrap;
}

.promo-codes-input-container:first-of-type {
  margin-left: 0;
}

.statistics-text {
  height: 22px;
  color: #242c5c;
  font-size: 18px;
  white-space: nowrap;
}

.ml-600 {
  margin-left: 600px;
}

.mr-20 {
  margin-right: 20px;
}

.admin-panel-tabs {
  background-color: #eceef5;
  height: 55px;
}

.message-modal {
  width: 350px;
  height: 300px;
}

.message-modal-header {
  justify-content: space-between;
  align-items: center;
  border-radius: 4px 4px 0 0;
  background-color: #fce8e9;
  padding: 10px 28px;
  height: 25%;
}

.message-modal-header h2 {
  margin: 0;
  color: #cc4b4f;
  font-size: 1.6rem;
}

.message-modal-text {
  height: 100%;
}

.users-table-container {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 720px;
}

.users-header-container-header {
  margin: 10px 0 10px 0;
  padding: 0 20px 0 10px;
}

.users-header-row-header {
  margin-bottom: 2px;
  height: 22px;
}

.users-header-indicator {
  background-color: #cdd1e5;
  min-width: 7px;
  max-width: 7px;
  height: 51px;
}

.users-header-indicator.transparent {
  background-color: transparent;
}

.users-rows-container {
  border-radius: 4px;
  background-color: #ffffff;
  padding: 10px 20px 20px 10px;
  overflow-y: auto;
}

.users-row-text {
  max-width: calc(100% - 10px);
  height: 22px;
  overflow: hidden;
  color: #242c5c;
  font-size: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.users-row-text:hover,
.users-row-full-name-text:hover {
  position: absolute;
  border-right: 8px solid #eceef5;
  background-color: #eceef5;
}

.users-row.disabled .users-row-text:hover,
.users-row.disabled .users-row-full-name-text:hover {
  border-right: 8px solid #d6dbec;
  background-color: #d6dbec;
}

.users-row-full-name-text {
  height: 22px;
  overflow: hidden;
  color: #3e6fd9;
  font-size: 16px;
  font-family: "Inter";
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.users-header-row-date {
  flex: 1 1 120px;
  padding-left: 10px;
  min-width: 9%;
}

.users-header-row-created-by {
  flex: 1 1 160px;
  min-width: 13%;
}

.users-header-row-email {
  flex: 1 1 160px;
  min-width: 13%;
}

.users-header-row-subscription {
  flex: 1 1 80px;
  min-width: 7%;
}

.users-body-row-date {
  flex: 1 1 110px;
  padding-left: 10px;
  min-width: 9%;
}

.users-body-row-created-by {
  flex: 1 1 160px;
  min-width: 13%;
}

.users-body-row-email {
  flex: 1 1 200px;
  min-width: 13%;
}

.users-body-row-subscription {
  flex: 1 1 80px;
  min-width: 7%;
}

.users-button-container {
  position: absolute;
  right: 10px;
  min-width: 30px;
  min-height: 30px;
  -webkit-user-select: none;
          user-select: none;
}

.users-button-container:hover {
  opacity: 1;
  z-index: 100;
}

.users-menu-button {
  outline: none !important;
  border: none;
  border-radius: 4px;
  background-color: #f7f8fa;
  width: 30px;
  height: 30px;
}

.users-button-container .users-menu-button {
  opacity: 0;
}

.users-button-container:hover .users-menu-button {
  opacity: 1;
}

.users-row {
  margin-bottom: 2px;
  border: 2px solid transparent;
  border-radius: 4px;
  background-color: #f3f4f8;
  height: 51px;
}

.users-row:hover {
  border: 2px solid #3e6fd9;
  background-color: #eceef5;
}

.users-row.disabled {
  background-color: #d6dbec;
}

.users-text {
  max-width: calc(100% - 10px);
  height: 22px;
  overflow: hidden;
  color: #242c5c;
  font-size: 16px;
  -webkit-user-select: none;
          user-select: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.users-text:hover {
  position: absolute;
  border-right: 8px solid #f7f8fa;
  background-color: #f7f8fa;
}

.users-text.active {
  color: #3e6fd9;
}

.users-triangle-icon {
  margin-top: 5px;
  border: 5px solid;
  border-color: #3e6fd9 transparent transparent transparent;
  width: 0;
  height: 0;
}

.users-triangle-icon.asc {
  margin-top: 0px;
  margin: 0 0 5px;
  border-color: transparent transparent #3e6fd9 transparent;
}

.custom-search-input-users {
  margin-bottom: 25px;
  max-width: 300px;
}

.pagination-container {
  margin-top: 10px;
}

.pagination-container button:hover {
  background-color: #3061ae;
}

.pagination-next {
  margin-left: 20px;
  outline: none !important;
  border: none;
  border-radius: 4px;
  background-color: #3e6fd9;
  padding: 0 10px;
  width: auto;
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  height: 39px;
  color: white;
}

.pagination-prev {
  outline: none !important;
  border: none;
  border-radius: 4px;
  background-color: #3e6fd9;
  padding: 0 10px;
  width: auto;
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  height: 39px;
  color: white;
}

.admin-panel-wrapper {
  margin: 0 40px;
  font-family: "Inter";
}

.admin-panel-header-top {
  height: 84px;
}

.admin-panel-header-title {
  margin-top: 30px;
  height: 38px;
  color: #242c5c;
  font-size: 30px;
  font-family: "Inter";
}

.admin-panel-wrapper hr {
  margin: 0 0 20px 0;
  width: 100%;
  color: #eceef5;
}

:root {
  --active-route-clr: #303c80;
  --active-route-clr: #303c80;
}

.content-wrapper {
  min-width: 768px;
}

.sidebar {
  position: fixed;
  top: 0;
  z-index: 1000;
  background-color: #242c5c;
  font-family: "Inter", sans-serif;
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
  color: #b4bad8;
  font-weight: 500;
  font-size: 15px !important;
  line-height: 18px;
  font-family: "Inter";
}
.sidebar::-webkit-scrollbar {
  display: none;
}

.sidebar.compact {
  padding-right: 2px;
  width: 74px;
  min-width: 74px;
}

.main-content {
  width: 100%;
  overflow-x: hidden;
}

.sidenav-item {
  margin: 0 10px;
  /* width: 90%; */
  height: 42px;
  -webkit-user-select: none;
          user-select: none;
}

.language-item {
  width: 100%;
}

.tools {
  margin-bottom: 5px;
  margin-left: 20px;
  width: 5ch;
  color: #8a93c3;
  font-weight: 500;
  font-size: 13px;
  font-family: "Inter";
}

.tools.compact {
  margin-left: 15px;
}

.sidenav-item div span {
  display: flex;
  align-items: center;
}

.sidenav-item.compact {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidenav-item.active {
  transition: background-color 0.1s linear;
  background: #303c80;
  background: var(--active-route-clr);
  color: white;
}

.sidenav-item:not(.active):not(.flag-item):not(.sidenav-item .sidenav-item):hover {
  transition: background-color 0.1s linear;
  background: #171e46;
}

.sidenav-item.profile .sidenav-title {
  /* color: #8a93c3; */
  line-height: 20px;
}

.triangle-right-icon {
  margin: 0 0 0 10px;
  border: 5px solid;
  border-color: transparent transparent transparent #5b6493;
}

.sidenav-item.active .triangle-right-icon {
  border-color: transparent transparent transparent #6682ce;
}

.sidenav-title {
  max-width: 105px;
  height: 16px;
  /* color: #f7f8fa; */
  font-weight: 500;
  line-height: 17px;
  text-align: left;
}

.sidenav-title.attribute-dependency,
.sidenav-title.username {
  height: auto;
  /* min-width: 87%; */
  /* max-width: 87%; */
}

.sidenav-item svg {
  margin: 10px 12px;
  width: 21px;
  min-width: 21px;
  height: 21px;
  min-height: 21px;
  pointer-events: none;
}

.sidenav-logo {
  position: fixed;
  top: 0;
  align-self: flex-start;
  z-index: 10;
  background-color: #242c5c;
  width: 243.7px;
  height: 70px;
  -webkit-user-select: none;
          user-select: none;
}

.sidenav-logo.compact {
  align-self: unset;
  margin-left: 0;
  width: 76px;
}

.sidenav-logo img {
  margin-right: 14px;
  pointer-events: none;
}

.sidenav-logo.compact img {
  margin-right: 0px;
  pointer-events: none;
}

.nav-items-container {
  width: 100%;
  -ms-overflow-style: none; /* IE and Edge */
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
}
.nav-items-container::-webkit-scrollbar {
  display: none;
}

.nav-items-container.compact {
  width: 76px;
}

.expand-icon {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  margin: 18px 15px;
  border: 1px solid #8a93c3;
  border-radius: 100%;
  background-color: transparent;
  width: 21px;
  min-width: 21px;
  height: 21px;
  min-height: 21px;
}

.expand-icon.compact {
  -webkit-transform: none;
          transform: none;
}

.expand-icon-triangle {
  position: absolute;
  top: 5px;
  left: 8px;
  border: 5px solid;
  border-color: transparent transparent transparent #8a93c3;
}

.sidenav-item-tooltip {
  position: absolute;
  left: 74px;
  opacity: 0;
  z-index: 1000;
  border-radius: 4px;
  background-color: #161d4a;
  padding: 0 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 30px;
  min-height: 30px;
  pointer-events: none;
  white-space: nowrap;
}

.sidenav-item-tooltip::before {
  position: absolute;
  left: -10px;
  border: 5px solid;
  border-color: transparent #161d4a transparent transparent;
  width: 0;
  height: 0;
  content: "";
}

.sidenav-item-tooltip span {
  color: white;
}

.sidenav-item:hover .sidenav-item-tooltip {
  opacity: 1;
}

.sidenav-item-tooltip-container {
  margin-right: 5px;
}

.support-icon {
  margin: 18px 15px;
  border: 1px solid #8a93c3;
  border-radius: 100%;
  background-color: transparent;
  width: 21px;
  min-width: 21px;
  height: 21px;
  min-height: 21px;
}

.support-icon span {
  height: 30px;
  color: #8a93c3;
  font-size: 16px;
  font-family: "Inter";
}

.flag-icon-nav-bar {
  cursor: pointer;
  margin: 18px 9px 18px 3px;
  width: 30px;
  height: 20px;
}

.flag-icon-nav-bar.compact {
  margin: 18px 12px 18px 5px;
}

.sidenav-templates-label {
  margin-top: 10px;
  width: 166px;
  height: 20px;
  color: #8a93c3;
  font-size: 13px;
  font-family: "Inter";
  text-align: start;
}

.attribute-dependency {
  min-width: 100% !important;
}

.attribute-dependency-nav-item {
  width: var(--idea-nav-item-width);
}

.sidenav-hr {
  margin: 8px 0px 10px 0px;
  border-top: 2px solid #2f3771;
  width: 244px;
  color: #ffffff;
}

.sidenav-hr.compact {
  border-top: 2px solid #2f3771;
  width: 76px;
}

.sidenav-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 87px;
  margin-bottom: 10px;
}

.sidenav-items:not(.compact) {
  width: 244px;
}

.en-flag-label {
  height: 25px;
}

.cn-flag-label {
  height: 22px;
}

.dashed-hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border: none;
  border-top: 1px dashed rgba(239, 241, 255, 0.479);
  width: 100%;
}

.notification-counter {
  left: 5px;
  -webkit-transform: translateY(-28px);
          transform: translateY(-28px);
  border-radius: 50%;
  background-color: #f1666a;
  width: 24px;
  height: 24px;
}
.notification-counter span {
  color: white;
  font-size: 20px;
  font-family: "Inter";
}

.issue-ongoing-counter {
  border-color: #ffcd67;
  border: 1px solid #ffcd67;
  color: #ffcd67;
}

.issues-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.24);
  width: 100%;
  height: 100%;
}
.issues-modal {
  position: fixed;
  top: 45%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 100;
  cursor: auto;
  box-shadow: 0px 9px 26px 13px rgba(34, 60, 80, 0.2);
  border-radius: 10px;
  background-color: #fff;
  padding: 26px;
  width: 720px;
  min-height: 100px;
}

.issues-modal-title {
  color: #242c5c;
  font-size: 22px;
  font-family: "Inter";
}

.issues-modal-text {
  margin-top: 10px;
  color: black;
  font-size: 18px;
  line-height: 24px;
  text-align: justify;
}

.issues-modal-link {
  cursor: pointer;
  color: #3e6fd9;
  font-size: 18px;
}
.issues-modal-button-container {
  margin-top: 10px;
}
.issues-modal-buttons {
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
  margin-left: 10px;
  outline: none !important;
  border: none;
  border-radius: 4px;
  background-color: rgba(62, 111, 217, 0.1);
  padding: 5px 10px 5px 10px;
  color: #3e6fd9;
}
.sidenav-dropdown-item {
  margin: 0;
}

.person-left-panel {
  background-color: #242c5c;
}

.division-nav-item {
  margin-left: 45px;
}

.contact-us-popup {
  border-radius: 4px;
  padding: 20px;
  width: 472px;
  height: 227px;
  overflow: hidden;
  font-family: "Inter";
}

.contact-us-popup button {
  border: none;
  border-radius: 4px;
  background-color: #3e6fd9;
  width: 357px;
  height: 56px;
  color: #fff;
}

.contact-us-popup button span {
  height: 30px;
  font-size: 22px;
  text-transform: uppercase;
}

.contact-us-popup-close {
  justify-content: flex-end;
  min-height: 20px;
}

.contact-us-popup-text {
  margin-bottom: 10px;
  width: 380px;
  color: #465664;
  font-size: 20px;
  line-height: 26px;
}

.contact-us-popup-button {
  outline: none !important;
}

.message-not-found-component {
  padding-top: 30px;
}

.message-not-found-component span {
  height: 38px;
  color: #242c5c;
  font-size: 22px;
  font-family: "Inter";
}

.project-progress-bar {
  border-radius: 4px;
  background-color: #cdd1e5;
  width: 100%;
  height: 7px;
}

.project-progress-colors {
  -webkit-animation: width-animated 1s ease forwards;
          animation: width-animated 1s ease forwards;
  height: 100%;
}

@-webkit-keyframes width-animated {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@keyframes width-animated {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.project-progress {
  border-radius: 4px;
  height: 100%;
}

.project-progress.green {
  background-color: #5ac57a;
}

.project-progress.yellow {
  background-color: #ffcd67;
}

.project-progress.red {
  background-color: #f9ae9f;
}

.initials.cn {
  font-size: 12px;
}

.component-card-initials.flex-center.cn {
  font-size: 12px;
}

.reports-row-initials.flex-center span.cn {
  font-size: 12px;
}

.users-row-initials-block.flex-center span.cn {
  margin-top: 5px;
  font-size: 12px;
}

.project-bar-selector {
  position: fixed;
  top: 0;
  align-items: flex-start;
  z-index: 100;
  background: #f8f9fa;
  width: calc(100% - 205px);
  min-height: 70px;
}

.project-bar-selector.compact {
  width: calc(100% - 74px);
}

.project-bar-selector.public-matrix {
  width: 100%;
}

.projects-bar-selector-title {
  margin-top: 2px;
  width: 100%;
  max-height: 44px;
  overflow: hidden;
  color: #242c5c;
  font-size: 22px;
  line-height: 22px;
  font-family: "Inter";
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-word;
}

.project-bar-selector hr {
  margin: 0 auto;
  margin-top: 5px;
  width: 95%;
  color: #eceef5;
}

.select-triangle-icon {
  opacity: 0.54;
  margin: 5px 0 0 25px;
  border: 5px solid;
  border-color: #242c5c transparent transparent transparent;
}

.addressed-cells-count {
  margin-right: 8px;
  color: #242c5c;
  font-size: 15.5px;
  font-family: "Inter";
}

.progress-bar-container {
  width: 210px;
}

.addressed-cells-counter {
  align-items: center;
  margin-right: 28px;
}

.project-bar-selector-option span {
  color: #242c5c;
  font-size: 15px;
  font-family: "Inter";
}

.project-bar-selector-option {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eceef5;
  background-color: #f7f8fa;
  padding: 0 24px;
  height: 33px;
  min-height: 33px;
  overflow: hidden;
  color: #242c5c;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.project-bar-selector-option:hover {
  background-color: white;
}

.project-bar-selector-option:hover span {
  color: #3c6ad7;
}

.project-bar-header {
  display: flex;
  align-items: baseline;
  height: 70px;
}

.projects-bar-select-container {
  margin-left: 40px;
  border: 2px solid #8a93c3;
  border-radius: 6px;
  padding: 14px;
  width: 23%;
  height: 80%;
  font-size: 10px;
}

.toolbar-border {
  border-bottom: 2px solid #eeee;
  border-radius: 1px;
  padding-top: 0;
  padding-bottom: 80px;
}

.free-height {
  height: auto;
}

.custom-score-component-input-container {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.custom-score-component-input-circle {
  position: relative;
  z-index: 100;
  margin-right: 30px;
  border: white 4px solid;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  color: #3e6fd9;
  font-size: 21px;
  line-height: 38px;
  -webkit-user-select: none;
          user-select: none;
}

.custom-score-component-input-circle:last-of-type {
  margin-right: 0;
}

.custom-score-component-input-circle.active span {
  color: white !important;
}

.custom-score-component-input-circle span {
  height: 30px;
  color: #3e6fd9;
  font-size: 13px;
  line-height: 32px;
  font-family: "Inter";
}

.custom-score-component-selection {
  margin: 0 4px;
  border: 2px solid transparent;
  border-radius: 4px;
  width: 55px;
  height: 55px;
}

.custom-component-sub-label {
  color: black;
  font-size: 12px;
  font-family: "Inter";
}

.grey-line {
  display: flex;
  position: absolute;
  background-color: #eceef5;
  width: 260px;
  height: 17px;
}

.attribute-dialog-wrapper {
  position: relative;
  border-radius: 4px;
  width: 622px;
  height: 497px;
  font-family: "Inter";
}

.attribute-dialog-header {
  justify-content: space-between;
  align-items: center;
  border-radius: 4px 4px 0 0;
  background-color: #eceef5;
  padding: 0 28px;
  width: 100%;
  height: 85px;
}

.attribute-dialog-title {
  margin-left: 10px;
  height: 38px;
  color: #242c5c;
  font-size: 30px;
  font-family: "Inter";
}

.attribute-dialog-title-text {
  margin-left: 10px;
  height: 38px;
  color: #242c5c;
  font-size: 20px;
  font-family: "Inter";
}

.attribute-dialog-body {
  padding: 26px 32px;
  width: 100%;
  height: calc(100% - 165px);
}

.attribute-dialog-label {
  align-self: flex-start;
  color: #465664;
  font-size: 16px;
  line-height: 24px;
}

.attribute-dialog-importance-label {
  color: #465664;
  font-size: 16px;
  line-height: 24px;
}

.attribute-importance {
  height: 36px;
}

.attribute-dialog-buttons {
  position: absolute;
  right: 32px;
  bottom: 32px;
}

.attribute-dialog-button {
  outline: none !important;
  border: none;
  border-radius: 4px;
  width: 105px;
  height: 40px;
  font-size: 14px;
}

.attribute-dialog-button.secondary {
  margin-right: 10px;
  background-color: transparent;
  color: #888da8;
}

.attribute-dialog-input-container {
  justify-content: space-between;
}

.attribute-location-toggle {
  margin: 0 20px 0 10px;
}

.draggable-handle {
  cursor: grab;
}

.attribute-dialog-tooltip {
  margin-top: 5px;
  margin-left: 10px;
}

.add-attribute-component-name {
  margin-right: 10px;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.BuyMoreAICreditsModal_header__28Yrz {
  background-color: #eceef5;
  padding: 0px 42px;
  height: 85px;
}

.BuyMoreAICreditsModal_dialog__1pkep {
  width: 622px;
  height: 566px;
}

.BuyMoreAICreditsModal_next-dialog__1FjG9 {
  width: 622px;
  height: 515px;
}

.BuyMoreAICreditsModal_title-header__Oqcs0 {
  margin-left: 10px;
  color: #242c5c;
  font-weight: 500;
  font-size: 30px;
}

.BuyMoreAICreditsModal_description-span__ChT4N {
  margin-top: 12px;
  width: 400px;
  font-weight: 500;
}

.BuyMoreAICreditsModal_default-color__3tUtg {
  color: #242c5c;
}

.BuyMoreAICreditsModal_confirm-span__2RfFu {
  margin-top: 40px;
}

.BuyMoreAICreditsModal_main-span__FyS05 {
  margin-top: 40px;
  font-weight: 500;
  font-size: 24px;
}

.BuyMoreAICreditsModal_tariff-block__3KUDF {
  margin-top: 30px;
  margin-bottom: 28px;
  border: 2px #cdd1e5 solid;
  border-radius: 5px;
  width: 163px;
  height: 193px;
}

.BuyMoreAICreditsModal_tariff-left-margin__1oTO8 {
  margin-left: 17px;
}

.BuyMoreAICreditsModal_check_mark__1xI-l {
  display: flex;
  margin-bottom: 14px;
  border-width: 2px;
  border-style: solid;
  border-color: #cdd1e5;
  border-radius: 8px;
  width: 16px;
  height: 16px;
}

.BuyMoreAICreditsModal_default-button__2K9Cn {
  width: 524px !important;
  height: 58px !important;
  font-size: 21px;
}

.BuyMoreAICreditsModal_icon-check_mark__1Krrv {
  margin-bottom: 12px;
}

.BuyMoreAICreditsModal_close-icon__5PGjo {
  margin-right: -10px;
  padding: 10px;
}

.BuyMoreAICreditsModal_tariff-block__3KUDF:hover,
.BuyMoreAICreditsModal_close-icon__5PGjo:hover {
  cursor: pointer;
}

.BuyMoreAICreditsModal_tariff-info__2CWYy {
  color: #242c5c;
  font-weight: 500;
  font-size: 18px;
}

.BuyMoreAICreditsModal_total-table__3DS3E {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 42px;
  border: 5px #cdd1e5 solid;
  border-width: 2px;
  border-radius: 4px;
  background: #f7f8fa;
  width: 540px;
  height: 223px;
}

.BuyMoreAICreditsModal_credits-block__2GI67 {
  margin-top: 30px;
  margin-right: 30px;
  margin-left: 30px;
}

.BuyMoreAICreditsModal_credits-block-bottom__2viO4 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.BuyMoreAICreditsModal_total-block__lxzVz {
  margin-right: 30px;
  margin-bottom: 26px;
  margin-left: 30px;
  border-top: 2px #cdd1e5 solid;
  border-radius: 1px;
  padding-top: 15px;
}

.BuyMoreAICreditsModal_credits-block__2GI67 span {
  color: #242c5c;
  font-weight: 500;
  font-size: 18px;
}

.BuyMoreAICreditsModal_total-block__lxzVz span {
  color: #242c5c;
  font-weight: 600;
  font-size: 18px;
}

.BuyMoreAICreditsModal_purchase-button__goLXb {
  margin-top: 28px;
  border: 0;
  min-width: 544px;
}

.BuyMoreAICreditsModal_credits-block-subscription__1L-2C {
  margin-top: 20px;
}

.BuyMoreAICreditsModal_total-table-subscription__3xdpX {
  margin-top: 28px;
}

.checkout-dialog-wrapper {
  position: relative;
  border-radius: 4px;
  width: 622px;
  height: 600px;
  overflow: hidden;
  font-family: "Inter";
}

.checkout-dialog-header {
  justify-content: space-between;
  align-items: center;
  background-color: #eceef5;
  padding: 0 28px;
  width: 100%;
  height: 85px;
}

.checkout-dialog-title {
  height: 33px;
  color: #242c5c;
  font-size: 26px;
  font-family: "Inter";
}

.checkout-dialog-body {
  padding: 26px 32px;
  width: 100%;
  height: calc(100% - 85px);
}

.checkout-dialog-buttons {
  position: absolute;
  right: 32px;
  bottom: 32px;
}

.checkout-dialog-button {
  outline: none !important;
  border: none;
  border-radius: 4px;
  min-width: 105px;
  height: 48px;
  font-size: 14px;
}

.checkout-dialog-label {
  height: 24px;
  color: #465664;
  font-size: 16px;
}

.stripe-error-message {
  margin-top: 10px;
  margin-right: 181px;
  height: 24px;
  color: #f1666a;
  font-size: 16px;
  text-align: end;
}

.checkout-dialog-button.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.billing-dialog-label {
  height: 20px;
  color: #465664;
  font-size: 16px;
}

.billing-dialog-label.total {
  height: 10px;
  font-size: 20px;
}

.custom-switch-container {
  position: relative;
  margin: 0 5px;
  width: 40px;
  height: 20px;
}

.custom-switch-rectangle {
  border-radius: 10px;
  background-color: #9db6ed;
  width: 34px;
  height: 14px;
}

.custom-switch-circle {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  background-color: #3e6fd9;
  width: 20px;
  height: 20px;
}

.custom-switch-circle.active {
  right: 0px;
  left: unset;
}

.custom-switch-label-default {
  height: 24px;
  color: #465664;
  font-size: 16px;
  font-family: "Inter";
}

.custom-switch-label-default.active {
  color: #3e6fd9;
}

.custom-switch-tooltip {
  margin-left: 5px;
}

.custom-switch.disabled {
  opacity: 0.5;
}
.custom-toggle-rectangle-logic {
  border-radius: 10px;
  background-color: #c3c5d3;
  width: 34px;
  height: 14px;
}

.component-dialog-wrapper {
  position: relative;
  border-radius: 4px;
  width: 622px;
  height: 600px;
  font-family: "Inter";
}

.component-dialog-header {
  justify-content: space-between;
  align-items: center;
  border-radius: 4px 4px 0 0;
  background-color: #eceef5;
  padding: 0 28px;
  width: 100%;
  height: 85px;
}

.component-dialog-title {
  margin-left: 10px;
  height: 38px;
  color: #242c5c;
  font-size: 30px;
  font-family: "Inter";
}

.component-dialog-body {
  padding: 26px 32px;
  width: 100%;
  height: calc(100% - 165px);
}

.component-dialog-label {
  align-self: flex-start;
  color: #465664;
  font-size: 16px;
  line-height: 24px;
}

.component-dialog-importance-label {
  color: #465664;
  font-size: 16px;
  line-height: 24px;
}

.component-dialog-label.active {
  color: #3e6fd9;
}

.component-dialog-buttons {
  position: absolute;
  right: 32px;
  bottom: 32px;
}

.component-dialog-button {
  outline: none !important;
  border: none;
  border-radius: 4px;
  width: 105px;
  height: 48px;
  font-size: 14px;
}

.component-dialog-button.secondary {
  margin-right: 10px;
  background-color: transparent;
  color: #888da8;
}

.component-dialog-input-container {
  justify-content: space-between;
}

.location-toggle {
  margin: 0 20px 0 10px;
}

.component-importance {
  height: 36px;
}

.view-tutorial-button {
  margin-right: 15px;
  outline: none !important;
  border: none;
  border-radius: 4px;
  background-color: #fce0a9;
  padding: 0 10px 0 15px;
  width: 200px;
  height: 44px;
  color: #3e6fd9;
}

.view-tutorial-button span {
  height: 22px;
  font-size: 16px;
  line-height: 25px;
  font-family: "Inter";
}

.view-tutorial-button svg {
  width: 28px;
  height: 25px;
}

.view-tutorial-button:hover {
  background-color: #3e6fd9;
  color: #ecf1fc;
}

.suggested-attribute-scrollbar-host {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-around;
  width: 100%;
  height: 90%;
}

.suggested-attribute-modal-title-container {
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  height: 100%;
  letter-spacing: 1.4px;
  text-align: left;
  white-space: nowrap;
}
.suggested-attribute-modal-title {
  top: 5px;
  margin-bottom: 10px;
  width: 95%;
  overflow: hidden;
  color: #242c5c;
  font-weight: bold;
  font-size: 1.6rem;
  text-overflow: ellipsis;
}

.suggest-attribute-dialog-body {
  display: flex;
  flex-grow: 1;
  padding: 37px 32px 26px;
  width: 100%;
  height: 80%;
}

.generate-attribute-dialog-body {
  display: flex;
  flex-grow: 1;
  padding: 26px 32px;
  width: 100%;
  height: 77%;
}

.add-attribute-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 60px;
  text-align: center;
}

.generate-modal-title {
  background-color: #fce8e9;
}

.suggest-attribute-button.generage-button {
  justify-content: center;
  align-items: center;
  background-color: #3e6fd9;
  width: 522px;
  height: 50px;
  color: white;
  font-size: 20px;
}

.add-attribute-button.generate-attribute:hover {
  background-color: #cc4b4f;
  color: white;
}

.attribute-generate-dialog-header {
  justify-content: space-between;
  align-items: center;
  border-radius: 4px 4px 0 0;
  background-color: #fde8e9;
  width: 100%;
  height: 158px;
}

.attribute-project-type {
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
  border: #f4d4d6 2px solid;
  border-radius: 5px;
  padding: 0 28px;
  width: 550px;
  height: 49px;
}

.attribute-project-type div {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.type-separator {
  display: flex;
  position: absolute;
  top: 83px;
  left: 310px;
  width: 22px;
}

.attribute-project-type span {
  margin-left: 10px;
  width: 200px;
  max-width: 200px;
  overflow: hidden;
  color: #242c5c;
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 33px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.suggest-item-attributes {
  flex-grow: 1;
  justify-content: flex-start;
  cursor: pointer;
  margin: 0 5px;
  margin-bottom: 10px;
  box-shadow: 0 1px 1px #c6cbed;
  border: 1px solid #c6cbed;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 1px;
  padding-top: 10px;
  padding-left: 15px;
  width: 47%;
  max-width: 193px;
  height: 50px;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.border-item.suggest-item-attributes,
.suggest-item-attributes:hover {
  border: 2px solid #f1666a;
  padding-top: 9px;
  padding-left: 14px;
  color: #f1666a;
}

.attribute-dialog-wrapper_select-all {
  margin: 0 20px 20px 0;
  box-shadow: 0 1px 1px #3e6fd9;
  border: solid 1px #3e6fd9;
  border-radius: 5px;
  padding: 10px 0 7px 0;
}

.attribute-dialog-wrapper_select-all.disabled {
  opacity: 0.5;
  box-shadow: none;
  border: none;
  background-color: #3e6fd9;
}

.attribute-dialog-wrapper_checkbox {
  display: flex;
  flex-direction: row;
  margin: 0 30px;
  padding: 0;
}

.attribute-dialog-wrapper_label {
  margin: 0 0 0 10px;
  color: #3e6fd9;
}

.attribute-dialog-wrapper_label.disabled {
  color: #ffffff;
}

.attribute-dialog-wrapper_tooltip {
  margin-bottom: 0;
  color: #212529;
  font-size: 16px;
  white-space: nowrap;
}

.suggested-container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  transition: all 0.2s ease;
  margin: 10px 10px 10px 0;
  box-shadow: 0 1px 1px #c6cbed;
  border: 2px solid transparent;
  border-radius: 4px;
  background-color: #eceef5;
  padding: 10px;
  width: 254px;
  min-width: 264px;
  height: 752px;
  overflow: hidden;
  -webkit-user-select: none;
          user-select: none;
}
.suggested-container.compact {
  transition: all 0.1s linear;
  height: 772px;
}
.suggested-scroll-container {
  width: 100%;
  height: 170px;
}
.suggested-scroll-container.compact {
  height: 580px;
}
.suggested-scrollable-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.suggested-container:hover,
.suggest-item:hover {
  border: 2px solid #f1666a;
  padding-top: 9px;
  padding-left: 14px;
}

.suggest-attribute-button:hover svg {
  color: white;
}
.suggest-item {
  flex-grow: 1;
  justify-content: flex-start;
  cursor: pointer;
  margin: 0 5px;
  margin-bottom: 10px;
  box-shadow: 0 1px 1px #c6cbed;
  border: 1px solid #c6cbed;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 1px;
  padding-top: 10px;
  padding-left: 15px;
  width: 47%;
  max-width: 216px;
  height: 50px;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.suggest-input-container {
  margin-top: 5px;
  padding-inline: 8px;
}
.suggest-input {
  border: 2px solid #cdd1e5;
  height: 40px;
}

.suggest-attribute-button {
  justify-content: space-between;
  margin-bottom: 10px;
  outline: none !important;
  border: none;
  border-radius: 4px;
  background-color: #d4def2;
  padding: 5px 10px 5px 20px;
  width: 224px;
  height: 39px;
  color: #3e6fd9;
}

.suggest-attribute-button:hover {
  background-color: #3e6fd9;
  color: white;
}

.suggest-attribute-button span {
  height: 20px;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter";
  text-transform: uppercase;
}

.suggest-attribute-button:disabled {
  opacity: 0.5;
}

.suggest-input-text-container {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  width: 90%;
}

.suggest-component-tip {
  position: relative;
  top: 5px;
  transition: all 0.2s;
  border-radius: 50%;
  background-color: #3e6fd9;
  width: 24px;
  height: 24px;
  color: white;
}

.suggest-component-tip:hover {
  background-color: #d4def2;
  color: #3e6fd9;
}

.border-item {
  border: 2px solid #f1666a;
  padding-top: 9px;
  padding-left: 14px;
}

.component-dialog-wrapper {
  position: relative;
  border-radius: 4px;
  width: 1000px;
  height: 537px;
  font-family: "Inter";
}

.generate-component-items {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  width: 50%;
}

.generate-component-items:first-child {
  border-right: 2px solid #b4b4d8;
}

.generate-component-input {
  margin-top: -25px;
  width: 730px;
  height: 76px;
}

.suggest-attribute-button.generate-component-button {
  justify-content: center;
  align-items: center;
  background-color: #3e6fd9;
  width: 50%;
  height: 50px;
  color: white;
  font-size: 19px;
}

.generate-component-items_component {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
  width: 100%;
}

.suggested-component-modal-title {
  overflow: hidden;
  color: #cc4b4f;
  font-weight: bold;
  font-size: 1.6rem;
  text-overflow: ellipsis;
}
.suggest-input-container.generate-component-input img {
  top: 21px;
  right: 27px;
  height: 30px;
}

.suggested-component-modal-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 21px 28px 0;
  width: 100%;
  height: 20%;
  letter-spacing: 1.4px;
  text-align: left;
  white-space: nowrap;
}

.components-dialog-header {
  justify-content: space-between;
  align-items: center;
  border-radius: 4px 4px 0 0;
  width: 100%;
  height: 200px;
}

.custom-search-input-input.border-color-blue {
  border-color: #fad3d5;
  background-color: #ffe7e9;
  height: 76px;
  color: #242d60;
  font-size: 24px;
  text-align: start;
}

.custom-search-input-input.border-color-blue::-webkit-input-placeholder {
  color: #7385d2;
}

.custom-search-input-input.border-color-blue::placeholder {
  color: #7385d2;
}

.suggested-component-modal-title {
  top: 5px;
  margin-bottom: 10px;
  width: 95%;
  overflow: hidden;
  color: #242d60;
  font-weight: 600;
  font-size: 22px;
  letter-spacing: normal;
  text-overflow: ellipsis;
}

.suggest-component-dialog-body {
  display: flex;
  flex-grow: 1;
  padding: 26px 32px;
  padding-top: 10px;
  width: 100%;
  height: 50%;
}

.generate-loader {
  margin: 9% auto;
}

.type-illustration {
  margin-right: -10px;
  margin-bottom: 25px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background: #fad3d5;
  width: 200px;
  height: 76px;
  color: #242d60;
  font-weight: 600;
  font-size: 24px;
}

.type-illustration span {
  margin-left: 5px;
}

.component-dialog-generate-steps {
  width: 950px;
  height: 817px;
}

.component-dialog-generate-steps .components-dialog-header {
  padding-bottom: 20px;
  height: 214px;
}
.generate-radio-input-component.custom-radio-input,
.generate-radio-input-component.custom-radio-input.active {
  border: none;
  background: #fce8e9;
}

.step-item {
  position: relative;
  top: auto;
  left: auto;
  margin-bottom: 7px;
  box-shadow: 0 1px 1px #c6cbed;
  border: 1px #c6cbed solid;
  border-radius: 3px;
  padding-right: 10px;
  padding-left: 10px;
  width: 333px;
  height: 48px;
  min-height: 48px;
  max-height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.step-item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 323px;
  content: "";
  color: transparent;
}

.step-item:hover {
  border-color: #f1666a;
  color: #f1666a;
}

.step-item.border-generate-steps {
  border-color: #f1666a;
  color: #f1666a;
}

.border-current-steps.step-item::after {
  background-color: #eceef5;
}

.step-item.border-current-steps:hover,
.step-item.border-current-steps {
  border-color: #3e6fd9;
  color: #242c5c;
}

.scrollbar-steps {
  margin-top: 15px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  padding-top: 15px;
  padding-right: 30px;
  padding-bottom: 30px;
  height: 447px;
  color: #242c5c;
}

.steps-container {
  min-height: 300px;
}

.search-generate-image.pointer {
  margin-top: -23px;
  margin-right: -18px;
  padding: 25px;
  width: 80px;
  height: 80px;
  min-height: 80px;
}

.generate-project-type-search {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 38px;
  padding: 0 28px;
  width: 100%;
  height: 90px;
}

.generate-component-items-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.generate-component-items-header_select-all {
  margin: 0 20px 0 0;
  border-radius: 5px;
  padding: 10px 0 7px 0;
}

.generate-component-items-header_select-all__steps {
  margin: 0 0 0 20px;
  box-shadow: 0 1px 1px #3e6fd9;
  border: solid 1px #3e6fd9;
}

.generate-component-items-header_select-all__blue {
  box-shadow: 0 1px 1px #3e6fd9;
  border: solid 1px #3e6fd9;
}

.generate-component-items-header_select-all__blue.disabled,
.generate-component-items-header_select-all__steps.disabled {
  opacity: 0.5;
  box-shadow: none;
  border: none;
  background-color: #3e6fd9;
}

.generate-component-items-header_select-all__red {
  box-shadow: 0 1px 1px #f1666a;
  border: solid 1px #f1666a;
}

.generate-component-items-header_select-all__red.disabled {
  opacity: 0.5;
  box-shadow: none;
  border: none;
  background-color: #f1666a;
}

.generate-component-items-header_checkbox {
  display: flex;
  flex-direction: row;
  margin: 0 30px;
  padding: 0;
}

.generate-component-items-header_label {
  margin: 0 0 0 10px;
}

.generate-component-items-header_label.disabled {
  color: #ffffff;
}

.generate-component-items-header_label__blue {
  color: #3e6fd9;
}

.generate-component-items-header_label__red {
  color: #f1666a;
}

.custom-class-checkbox {
  border: 2px solid #f1666a;
  background-color: #f1666a;
}

.tooltip-inner-text.generate {
  height: 23px;
  font-size: 16px;
}

.scrollbar-steps-tooltip {
  margin-bottom: 0;
  width: 320px;
  overflow: hidden;
  color: #242c5c;
  font-size: 16px;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.generate-component-items_tooltip {
  margin-bottom: 0;
  color: #212529;
  font-size: 16px;
  white-space: nowrap;
}

.components-dashboard-wrapper {
  margin: 95px 40px 0;
  font-family: "Inter";
}

.components-dashboard-title {
  margin-right: 10px;
  min-height: 45px;
  max-height: 90px;
  color: #242c5c;
  font-size: 32px;
  font-family: "Inter";
  word-wrap: normal;
  text-align: left;
}

.search-input-container-components {
  max-width: 250px;
}

.components-dashboard-count {
  margin-left: 10px;
  height: 38px;
  color: #838fdb;
  font-size: 30px;
}

.add-component-button-round {
  margin: 0 20px;
  color: #3e6fd9;
}

.add-component-button-round:hover {
  color: #3061ae;
}

.components-counter-container {
  margin-bottom: 10px;
  height: 38px;
}

.components-tooltip {
  margin-right: auto;
}

.components-cards-wrapper {
  max-height: 100%;
}

.component-card {
  position: relative;
  margin: 0 10px 10px 0;
  box-shadow: 0 1px 1px #c6cbed;
  border: 2px solid transparent;
  border-radius: 4px;
  background-color: #eceef5;
  width: 264px;
  min-width: 264px;
  height: 754px;
  -webkit-user-select: none;
          user-select: none;
}

.component-card:hover {
  border: 2px solid #3e6fd9;
}

.component-card-header {
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 4px;
  background-color: #eceef5;
  padding: 13px 15px 0 15px;
  width: 100%;
  height: 75px;
}

.component-card-header .component-icon {
  margin-right: 10px;
  width: 18px;
  min-width: 18px;
  height: 32px;
  min-height: 32px;
}

.component-icon-step {
  margin-right: 10px;
  width: 36px;
  height: 36px;
}

.component-card-header .link-icon {
  margin-right: 8px;
}

.component-card-header span {
  padding-top: 4px;
}

.component-card-menu-button {
  outline: none !important;
  border: none;
  background-color: transparent;
  width: 20px;
  height: 30px;
}

.importance-block {
  margin-right: 2px;
  border-radius: 2px;
  background-color: #f1666a;
  width: 8px;
  height: 8px;
}

.component-card-initials {
  border-radius: 3px;
  background-color: #fdeeee;
  width: 30px;
  height: 22px;
  color: #f1666a;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  font-family: "Inter";
}

.component-type {
  border-radius: 4px;
  background-color: #f7f8fa;
  width: 59px;
  height: 22px;
  font-family: "Inter";
}

.component-type span {
  height: 18px;
  color: #3e6fd9;
  font-size: 12px;
}

.component-type.external {
  background-color: #fdeeee;
}

.component-type.external span {
  color: #f1666a;
}

.component-card-body-dashboard {
  padding: 0 15px;
  height: 90%;
}

@media (min-height: 320px) {
  .component-card-body-dashboard {
    height: 50% !important;
    min-height: 50%;
    max-height: 50%;
  }
}

@media (min-height: 400px) {
  .component-card-body-dashboard {
    height: 60% !important;
    min-height: 60%;
    max-height: 60%;
  }
}

@media (min-height: 570px) {
  .component-card-body-dashboard {
    height: 70% !important;
    min-height: 70%;
    max-height: 70%;
  }
}

@media (min-height: 700px) {
  .component-card-body-dashboard {
    height: 81% !important;
    min-height: 81%;
    max-height: 81%;
  }
}

@media (min-height: 900px) {
  .component-card-body-dashboard {
    height: 90% !important;
    min-height: 90%;
    max-height: 90%;
  }
}
.attribute-card {
  margin-bottom: 10px;
  box-shadow: 0 1px 1px #c6cbed;
  border: 1px solid #c6cbed;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 1px;
  width: 229px;
  height: 114px;
  min-height: 114px;
  max-height: 114px;
}

.attribute-card.disabled {
  background-color: #d6dbed;
}

.attribute-card.disabled .component-card-initials {
  background-color: rgba(241, 102, 106, 0.11);
}

.component-card.disabled {
  background-color: #d6dbed;
}

.component-card-header.disabled {
  background-color: #d6dbed;
}

.attribute-card:hover {
  border: 2px solid #3e6fd9;
  padding: 0;
}

.attribute-card-container {
  margin-bottom: 10px;
  width: 100%;
  height: 570px;
}

.attribute-card-title {
  height: 23px;
  color: #242c5c;
  font-size: 16px;
  font-family: "Inter";
}

.attribute-card-header {
  margin-top: 6px;
  margin-bottom: 4px;
  padding: 0 10px;
  max-height: 42px;
}

.attribute-card-header svg {
  margin-right: 10px;
  margin-bottom: 3px;
  width: 13px;
  min-width: 13px;
  height: 15px;
  min-height: 15px;
}

.attribute-card-description-container {
  padding: 0 15px;
  max-height: 28px;
  overflow: hidden;
}

.attribute-card-description {
  max-height: 38px;
  color: #888da8;
  font-size: 12px;
  line-height: 15px;
  font-family: "Inter";
  text-align: left;
  word-break: break-word;
}

.attribute-card-top {
  max-height: 78px;
}

.attribute-card-bottom {
  align-items: flex-end;
  padding: 0 15px 10px 15px;
}

.menu-icon {
  width: 12px;
  min-width: 12px;
  height: 25px;
  min-height: 25px;
}

.add-attribute-button {
  margin-bottom: 10px;
  outline: none !important;
  border: none;
  border-radius: 4px;
  background-color: #d4def2;
  padding: 0 10px 0 20px;
  width: 100px;
  height: 39px;
  color: #3e6fd9;
}

.add-attribute-button:hover {
  background-color: #3e6fd9;
  color: white;
}

.add-attribute-button svg {
  width: 13px;
  height: 13px;
}

.add-attribute-button span {
  height: 20px;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter";
  text-transform: uppercase;
}

.importance-block-compact {
  border-radius: 3px;
  background-color: #fde8e9;
  width: 22px;
  height: 22px;
  font-family: "Inter";
}

.importance-block-compact span {
  height: 18px;
  color: #f1666a;
  font-size: 12px;
}

.active-card-element {
  border: 2px solid #3e6fd9;
  padding: 0;
}

.attribute-card.compact {
  margin-bottom: 7px;
  height: 49px;
  min-height: 49px;
}

.attribute-card-header-compact {
  padding: 0 10px;
}

.attribute-card-header-compact svg {
  margin-right: 5px;
  width: 17px;

  min-width: 17px;
  height: 15px;
  min-height: 15px;
}

.component-card-menu-button.compact {
  width: 22px;
}

.component-card.compact {
  height: 370px;
}

.attribute-card-container.compact {
  height: 196px;
}

.component-type-container {
  margin-bottom: 20px;
}

.component-type-container.compact {
  margin-bottom: 10px;
}

.component-card-header.compact {
  margin: 0;
}

.attribute-card-title-container {
  max-width: 100%;
}

.attribute-card-title-container span {
  padding-top: 6px;
  height: auto;
  max-height: 40px;
  overflow: hidden;
  line-height: 18px;
  text-align: left;
  text-overflow: ellipsis;
  word-break: break-word;
}

.attribute-card-title-container.compact span {
  padding-top: 2px;
  max-height: 36px;
  line-height: 18px;
}

.attribute-card-title-container:not(.compact) svg {
  margin-top: 6px;
}

.component-card img {
  pointer-events: none;
}

.component-card-header-text {
  width: calc(100% - 20px);
}

.attribute-card-scroll-container {
  position: absolute;
  right: 5px;
  min-width: 5px;
}

.attribute-card-scroll-handle {
  position: relative;
  border-radius: 6px;
  background-color: #d9dde5;
  min-width: 5px;
}

.card-menu-buttons-container {
  height: 28px;
}

.toggle-container {
  margin: 0 20px 0 0;
  height: 41px;
  white-space: nowrap;
}

.sort-toggle {
  padding-right: 10px;
  color: #465664;
  font-weight: 500;
  font-size: 14px;
}

.mt-10 {
  margin-top: 10px;
}

.component-droppable-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100% - 10px);
}

.sortable-hoc-drag-handle {
  cursor: grab;
}

.component-placeholder {
  padding-top: 30px;
}

.component-placeholder span {
  height: 38px;
  color: #242c5c;
  font-size: 22px;
  font-family: "Inter";
}
.report-placeholder {
  padding-top: 30px;
}

.report-placeholder span {
  height: 38px;
  color: #242c5c;
  font-size: 22px;
  font-family: "Inter";
}

.modal-video-button-exit {
  margin-right: 20px;
  width: 22px;
  height: 30px;
}

.modal-video-content {
  border-radius: 4px;
  background-color: #eceef5;
}

.header-modal-video {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 75px;
}

.modal-video-title {
  margin-left: 20px;
  height: 33px;
  color: #242c5c;
  font-size: 26px;
}

.components-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.add-attribute-button:disabled {
  opacity: 0.5;
}

.add-attribute-button.generate-attribute {
  display: flex;
  justify-content: center;
  background-color: #f9e9ec;
  padding-right: 10px;
  padding: 0px 10px;
  color: #cc4b4f;
}

.add-attribute-button.generate-component {
  margin-right: 10px;
  background-color: #f9e9ec;
  padding-left: 10px;
  width: 124px;
  color: #cc4b4f;
}

.add-attribute-button.generate-component:hover {
  background-color: #cc4b4f;
  color: white;
}

.add-attribute-button.add-new-attribute {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #ebeef5;
  background-color: #c2d4fa;
  width: 50%;
}

.add-attribute-button.add-new-attribute:hover {
  border: 2px #3e6fd9 solid;
  color: #3e6fd9;
}

.generate-openai-count {
  margin-right: 20px;
  margin-bottom: 10px;
  height: 38px;
  color: #cc4b4f;
  font-size: 30px;
}

.add-component-button-title {
  margin-right: 10px;
  margin-left: 10px;
  outline: none !important;
  border: none;
  border-radius: 3px;
  background-color: #c2d4fa;
  padding-right: 40px;
  padding-right: 10px;
  padding-left: 11px;
  /* width: 100px; */
  width: 40%;
  height: 38px;
  color: #3e6fd9;
}

.add-component-button-title:hover {
  border: 2px #3e6fd9 solid;
}

.add-text-attributes {
  margin-bottom: 4px;
  color: #3e6fd9;
  font-size: 15px;
  text-align: start;
}

.warning-delete-modal {
  height: 250px;
}

.warning-delete-modal span {
  height: 100%;
}

.delete-popup.warning-delete-modal button span {
  font-size: 18px;
}

.dashboard-header-vantage-point {
  margin-bottom: 16px;
  width: 80%;
  font-size: 18px;
}

.component-card-tooltip {
  margin-bottom: 0;
  overflow: hidden;
  color: #242c5c;
  font-size: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.components-dashboard-settings {
  margin: 10px 0 10px 0;
}

@media (min-height: 400px) {
  .component-card-body-dashboard {
    height: 60%;
  }
}

@media (min-height: 570px) {
  .component-card-body-dashboard {
    height: 70%;
  }
}

@media (min-height: 700px) {
  .component-card-body-dashboard {
    height: 81%;
  }
}

@media (min-height: 900px) {
  .component-card-body-dashboard {
    height: 90%;
  }
}

.WarningNotTokensModal_dialog__3e13d {
  width: 500px;
  height: 360px;
}

.WarningNotTokensModal_header__ycFSL {
  padding: 0px 42px;
  height: 80px;
}

.WarningNotTokensModal_close-icon__10qFU {
  margin-right: -20px;
  padding: 20px;
}

.WarningNotTokensModal_close-icon__10qFU:hover {
  cursor: pointer;
}

.WarningNotTokensModal_title-header__1bUAS {
  margin-left: 10px;
  color: #242c5c;
  font-weight: 600;
  font-size: 20px;
}

.WarningNotTokensModal_close-button__2Rb5y {
  border: none;
  background: none;
}

.WarningNotTokensModal_buy-button__3iIUR {
  margin: 0 !important;
}

.WarningNotTokensModal_body-label__2j85p {
  padding: 30px 20px 42px 20px;
  font-weight: 500;
  font-size: 20px;
}

.WarningNotTokensModal_purchase-button__2aV8Z {
  margin-top: 18px;
  border: 2px #3e6fd9 solid !important;
  background-color: white !important;
  color: #3e6fd9 !important;
}

.WarningNotTokensModal_purchase-button__2aV8Z:hover {
  background-color: #f5f5f5 !important;
}

.WarningNotTokensModal_default-button__F3UCq {
  width: 80% !important;
  height: 42px !important;
  font-weight: 600;
  text-align: center;
}

.WarningNotTokensModal_icon-generate__2kOmq {
  width: 24px;
  height: 24px;
  color: #cc4b4f;
}

.login-wrapper {
  position: absolute;
  left: 0;
  background-color: #f7f8fa;
  width: 1680px;
  width: 100vw;
  height: 1150px;
  height: 100vh;
  font-family: "Inter";
}

.login-logo {
  position: absolute;
  top: 35px;
  left: 35px;
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
}

.login-illustration-container {
  justify-content: center;
  align-items: flex-end;
  background-color: #242c5c;
  min-width: 50vw;
  max-width: 50vw;
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
}

.login-illustration-container img {
  max-width: 100%;
  max-height: 100%;
}

.success-confirm-email {
  margin-top: 80px;
  padding-bottom: 90px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.subtraction-dialog-wrapper {
  position: relative;
  border-radius: 4px;
  background: white;
  width: 1061px;
  min-width: 768px;
  max-width: 100vw;
  height: 792px;
  min-height: 768px;
  max-height: 100vh;
  overflow: hidden;
  font-family: "Inter";
}

.subtraction-dialog-header {
  position: relative;
  background-color: #eceef5;
  width: 100%;
  height: 257px;
}

.subtraction-dialog-header-icon {
  margin-right: 10px;
  height: 26px;
}

.subtraction-dialog-title-container {
  justify-content: space-between;
  align-items: center;
  align-self: flex-start;
  margin-top: 20px;
  padding: 0 28px;
  width: 100%;
}

.subtraction-dialog-title {
  height: 38px;
  color: #242c5c;
  font-size: 30px;
  font-family: "Inter";
}

.subtraction-dialog-body {
  padding: 40px 140px 0;
  width: 100%;
}

.subtraction-dialog-body.benefits-container {
  padding: 40px 0 0;
}

.eureka-dialog-footer {
  position: absolute;
  bottom: 0;
  border-top: 2px solid #eceef5;
  padding: 0 25px;
  width: 100%;
  height: 60px;
}

.eureka-dialog-button {
  cursor: pointer;
  outline: none !important;
  border: none;
  border-radius: 4px;
  width: 105px;
  height: 48px;
  font-size: 16px;
}

.eureka-dialog-button.disabled {
  background-color: #888da8;
  color: white;
}

.eureka-dialog-button.secondary {
  margin-right: 10px;
  background-color: transparent;
  color: #888da8;
}

.eureka-dialog-button span {
  height: 24px;
  font-size: 18px;
  font-family: "Inter";
}

.eureka-dialog-button.control {
  width: 131px;
}

.eureka-dialog-button.outlined {
  border: 2px solid #3e6fd9;
  background-color: white;
}

.eureka-dialog-button.outlined span {
  color: #3e6fd9;
}

.subtraction-text {
  align-self: center;
  margin-top: 20px;
  margin-bottom: auto;
  width: 100%;
  max-width: 900px;
  height: 24px;
  color: #242c5c;
  font-size: 28px;
  line-height: 22px;
  font-family: "Inter";
}

.subtraction-container {
  z-index: 100;
  border: 2px solid #c0c4de;
  border-radius: 4px;
  background-color: white;
  width: 100%;
  min-width: 320px;
  max-width: 485px;
  height: 52px;
}

.subtraction-dialog-controls img {
  margin-left: 30px;
}

.feasible-idea {
  border-radius: 4px;
  background-color: #eceef5;
  height: 110px;
}

.feasible-idea.negative {
  background-color: rgba(241, 102, 106, 0.29);
}

.feasible-idea.positive {
  background-color: rgba(90, 197, 122, 0.2);
}

.feasible-idea-text {
  margin-bottom: 10px;
  height: 25px;
  color: #242c5c;
  font-size: 18px;
  font-family: "Inter";
}

.flex-1 {
  flex: 1 1;
}

.idea-rating-label {
  height: 42px;
  color: #242c5c;
  font-size: 20px;
  font-family: "Inter";
}

.idea-rating-container {
  align-self: center;
  margin-bottom: 40px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.bgc-white {
  background-color: white !important;
}

.subtraction-tabs-circle {
  position: relative;
  transition: border-color 0.5s;
  border: 3px solid transparent;
  border-radius: 20px;
  background-color: #eceef5;
  width: 25px;
  min-width: 25px;
  height: 25px;
}

.subtraction-tabs-circle.active {
  transition: border-color 0.5s;
  border-color: #3e6fd9;
}

.eureka-tab-button span {
  transition: color 0.5s;
  height: 22px;
  color: #888da8;
  font-size: 16px;
  font-family: "Inter";
  white-space: nowrap;
}

.eureka-tab-button div {
  height: 100%;
}

.subtraction-tab-label.active {
  transition: color 0.5s;
  color: #3e6fd9;
}

.eureka-tab-button {
  position: absolute;
  bottom: 20px;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  cursor: pointer;
  outline: none !important;
  border: none;
  background-color: transparent;
  padding: 0 10px;
  height: 36px;
  line-height: 1.1;
}

.completed-idea-button div {
  height: 25%;
}

.eureka-tabs-line-container {
  width: 210px;
  min-width: 300px;
  height: 3px;
  min-height: 3px;
}

.eureka-tabs-line {
  background-color: transparent;
  width: 300px;
  height: 3px;
  min-height: 3px;
}

.eureka-tabs-container {
  position: absolute;
  bottom: -12px;
  left: -170px;
  justify-content: space-evenly;
  width: 96%;
}

.eureka-tabs-line {
  transition: background-color 0.5s;
  background-color: transparent;
}

.eureka-tabs-line.active {
  transition: background-color 0.5s;
  background-color: #3e6fd9;
  min-width: 300px;
}

.subtraction-tabs-circle svg {
  position: absolute;
  top: -5px;
  left: -5px;
  opacity: 0;
  transition: opacity 0.5s;
  width: 29px;
  min-width: 29px;
  height: 29px;
  min-height: 29px;
}

.subtraction-tabs-circle svg.completed {
  opacity: 1;
  transition: opacity 0.5s;
}

.eureka-tab {
  max-width: 25%;
}

.tab-label-optional {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 12px;
}

.subtraction-description {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.eureka-modal-title {
  flex-wrap: wrap;
  align-self: center;
  margin-top: 5px;
  margin-bottom: auto;
  width: 100%;
  max-width: 900px;
  height: 40px;
  color: #242c5c;
  font-size: 24px;
  line-height: 40px;
  font-family: "Inter";
}

.eureka-modal-title u {
  margin: 0 5px;
  max-width: 800px;
  height: 40px;
  overflow: hidden;
  line-height: 40px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user-initials {
  border-radius: 2px;
  background-color: #fde8e9;
  width: 26px;
  min-width: 26px;
  height: 22px;
  min-height: 22px;
}

.user-initials span {
  height: 17px;
  color: #272727;
  font-size: 13px;
  line-height: 17px;
  text-transform: uppercase;
}

.user-initials span.cn {
  height: 17px;
  color: #272727;
  font-size: 12px;
  line-height: 17px;
  text-transform: uppercase;
}

.user-initials.disabled {
  background-color: rgba(241, 102, 106, 0.11);
}

.roundedInitials {
  border-radius: 50%;
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
}

.issues-input-container textarea {
  background-color: #fff;
}

.bullet-point-container {
  margin-right: 8px;
  width: 20px;
  height: 20px;
}

.bullet-point-item {
  border-radius: 50%;
  background-color: #848ca6;
  width: 16px;
  height: 16px;
}

.custom-input-category-container .custom-input {
  color: #242c5c;
  font-size: 15px;
}

.custom-input-category-container .custom-input::-webkit-input-placeholder {
  color: rgba(36, 44, 92, 0.5);
}

.custom-input-category-container .custom-input::placeholder {
  color: rgba(36, 44, 92, 0.5);
}

.custom-input-category-container {
  margin-right: 8px;
}

.custom-input-category-container .category-button {
  top: 10px;
  right: 5px;
  outline: none !important;
  border: none;
  background: transparent;
  width: 35px;
}

.custom-input-input {
  border: 2px solid #eceef5;
  border-right: none;
  border-radius: 4px 0 0 4px;
  padding: 0 16px;
  width: 100%;
  height: 45px;
  color: #242c5c;
  font-size: 14px;
}

.custom-input-input::-webkit-input-placeholder {
  color: rgba(36, 44, 92, 0.5);
}

.custom-input-input::placeholder {
  color: rgba(36, 44, 92, 0.5);
}

.custom-input-select {
  border: 2px solid #eceef5;
  border-radius: 0 4px 4px 0;
  background-color: #eceef5;
  padding-left: 14px;
  width: 139px;
  min-width: 139px;
  height: 45px;
  color: #242c5c;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter";
}

.custom-input-select-container {
  position: relative;
  width: 270px;
  min-width: 270px;
  height: auto;
  min-height: 45px;
  font-family: "Inter";
  -webkit-user-select: none;
          user-select: none;
}

.custom-input-select-container span {
  -webkit-user-select: none;
          user-select: none;
}

.custom-input-triangle-icon {
  position: absolute;
  right: 10px;
  border: 5px solid;
  border-color: #858aa6 transparent transparent transparent;
}

.custom-input-select-options {
  position: fixed;
  z-index: 100;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 0 0 4px 4px;
  background-color: #eceef5;
  width: 270px;
  min-width: 270px;
  height: 120px;
  min-height: 45px;
  overflow: hidden;
}

.custom-input-container-option {
  padding: 0 25px 0 15px;
  height: 39px;
  min-height: 39px;
}

.custom-input-container-option > img {
  margin-right: 8px;
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
}

.custom-input-container-option:not(.first):not(:last-of-type) {
  border-bottom: 2px solid #e8ebf2;
}

.custom-input-container-option.first {
  margin-bottom: auto;
  border-bottom: 2px solid #e8ebf2;
  height: calc(100% - 8px);
  min-height: 45px;
}

.custom-input-container-option:hover {
  background-color: #dee1eb;
}

.custom-input-first-option .custom-input-container-option {
  border-radius: 0 4px 4px 0;
  background-color: #eceef5;
}

.custom-input-first-option .custom-input-container-option.open {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 0 4px 0 0;
}

.custom-input-first-option {
  position: relative;
  height: 100%;
}

.custom-input-option-text {
  max-width: 80px;
  height: 20px;
  overflow: hidden;
  color: #242c5c;
  font-size: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.custom-input-option-text.placeholder {
  color: #888da8;
  text-overflow: ellipsis;
}

.custom-input-input:hover {
  border: 2px solid #9eb6ec;
}

.custom-input-input:focus {
  border: 2px solid #9eb6ec;
}

.custom-input-input.empty {
  background-color: #f6f7f9;
}

.benefits-textarea-container {
  width: 100%;
  min-height: 45px;
}

.benefits-textarea-container textarea {
  border-radius: 4px 0 0 4px;
  padding: 13px 16px 13px 16px;
  width: 100%;
  max-height: 95px;
  color: #242c5c;
  font-size: 15px;
  line-height: 15px;
}

.benefits-textarea-container textarea::-webkit-input-placeholder {
  color: #75798d;
}

.benefits-textarea-container textarea::placeholder {
  color: #75798d;
}

.custom-category-checkbox {
  margin-right: 10px;
}

.custom-button-panel {
  display: flex;
  grid-gap: 13px;
  gap: 13px;
  margin-right: 20px;
}

.custom-button-panel > button {
  border: none;
  padding: 0;
}

.custom-button-panel > button svg {
  width: 16px;
  height: 16px;
}

.custom-input-container-option .custom-input-category-container input {
  margin: 0;
  padding: 0 5px;
  text-overflow: ellipsis;
}

.custom-score-input-container {
  position: relative;
  z-index: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.custom-score-input-circle {
  position: relative;
  z-index: 100;
  margin-right: 30px;
  border: 2px solid #3e6fd9;
  border-radius: 100%;
  background-color: #ffffff;
  width: 50px;
  height: 50px;
  color: #3e6fd9;
  font-size: 21px;
  line-height: 38px;
  -webkit-user-select: none;
          user-select: none;
}

.custom-score-input-circle:last-of-type {
  margin-right: 0;
}

.custom-score-input-circle.active span {
  color: white !important;
}

.custom-score-input-circle span {
  height: 30px;
  color: #3e6fd9;
  font-size: 29px;
  line-height: 32px;
  font-family: "Inter";
}

.custom-score-selection {
  border: 2px solid transparent;
  border-radius: 4px;
  width: 65px;
  min-width: 65px;
  height: 60px;
  min-height: 60px;
}

.custom-sub-label {
  color: black;
  font-size: 12px;
  font-family: "Inter";
}

.generate-modal-window {
  width: 622px;
  height: 662px;
}

.generate-modal-window-header {
  justify-content: space-between;
  align-items: center;
  border-radius: 4px 4px 0 0;
  background-color: #fce8e9;
  padding: 10px 28px;
  height: 13%;
}

.generate-modal-window-header h2 {
  margin: 0;
  color: #cc4b4f;
  font-size: 1.6rem;
}

.generate-modal-window-scroll {
  overflow: scroll;
}

.generate-modal-window-list {
  margin: 20px;
}

.generate-modal-window-list__with-options {
  margin-top: 0;
  height: 100%;
}

.generate-modal-window-list__option {
  margin: 0 0 15px 0;
  border: 2px solid #eceef5;
  border-radius: 4px;
  padding: 5px 25px;
  width: 100%;
  text-align: left;
}

.generate-modal-window-list__option.selected {
  border: 2px solid #f1666a;
}

.generate-modal-window-list__option.selected p {
  color: #f1666a;
}

.generate-modal-window__button {
  margin: 0 20px 10px;
  border: none;
  border-radius: 4px;
  background-color: #3e6fd9;
  height: 10%;
  color: #fff;
  font-size: 20px;
}

.generate-modal-window__button:disabled {
  opacity: 0.5;
}

.generate-modal-window-list__option-title {
  margin-top: 20px;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 18px;
}

.generate-modal-window-list__option-description {
  margin-bottom: 26px;
  font-weight: normal;
}

.carousel-container {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}

.arrows {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  color: red;
  font-size: 30px;
  -webkit-user-select: none;
          user-select: none;
}

.arrow {
  cursor: pointer;
  margin-bottom: 30px;
  width: 30px;
  height: 30px;
}

.arrow__image {
  width: 30px;
  height: 30px;
}

.arrow__image_left {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  width: 30px;
  height: 30px;
}

.carousel-container__images-container {
  display: flex;
  width: 300px;
  overflow: hidden;
}

.carousel-container__slider-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.carousel-container__slider-image_selected {
  border: 2px solid #cb4252;
}

.carousel-container__image-deletion-button {
  position: relative;
  bottom: 80px;
  left: 65px;
  z-index: 1;
  border: 0px;
  padding: 0px;
  width: 15px;
  height: 15px;
  font-size: 8px;
}

.carousel-container__image-deletion-button > img {
  width: 8px;
  height: 8px;
}

.carousel-container__image-container {
  display: flex;
  flex-direction: column;
}

.dnd-label {
  margin-bottom: 13px;
  height: 24px;
  color: #465664;
  font-weight: 500;
  font-size: 16px;
}

.dnd {
  border: 2px dashed rgba(62, 111, 217, 0.44);
  border-radius: 4px;
  background-color: #f7f8fa;
  width: 100%;
  height: 107px;
  font-family: "Inter";
}

.dnd.preview {
  border: none;
}

.dnd-text {
  height: 20px;
  color: #465664;
  font-size: 16px;
  line-height: 24px;
}

.drop-zone {
  width: 100%;
  height: 100%;
}

.light-blue {
  color: #3e6fd9;
}

.dnd-bottom-text {
  color: #888da8;
  font-size: 14px;
}

.dnd.dnd-progress {
  margin-top: 10px;
  border: none;
  height: 47px;
}

.dnd-cancel-icon {
  margin: 0 15px;
  width: 12px;
  height: 12px;
}

.dnd-progress-bar {
  border-radius: 8px;
  background-color: #cdd1e5;
  width: 59px;
  height: 7px;
}

.dnd-progress-bar.filled {
  background-color: #3e6fd9;
}

.dnd-filename span {
  height: 22px;
  color: #242c5c;
  font-size: 14px;
}

.dnd-filename {
  margin-left: 15px;
}

.dnd-filename svg {
  margin-right: 10px;
}

.dnd-file-info span {
  color: #888da8;
  font-size: 11px;
  line-height: 13px;
  text-align: right;
}

.display-none {
  display: none;
}

.dnd-image-preveiw img {
  max-width: 100%;
  height: 100%;
  max-height: 100%;
}

.dnd-preview-label {
  margin-bottom: 0;
}

.generate-names {
  width: 622px;
  height: 662px;
}

.generate-names-header {
  justify-content: space-between;
  align-items: center;
  border-radius: 4px 4px 0 0;
  background-color: #fce8e9;
  padding: 10px 28px;
  height: 13%;
}

.generate-names-header h2 {
  margin: 0;
  color: #cc4b4f;
  font-size: 1.6rem;
}

.generate-names-scroll {
  overflow: scroll;
}

.generate-names-list {
  margin: 20px;
}

.generate-names-list__name {
  margin: 0 0 15px 0;
  border: 2px solid #eceef5;
  border-radius: 4px;
  padding: 5px 25px;
  width: 100%;
}

.generate-names-list__name p {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  height: 9vh;
  font-weight: 500;
  font-size: 20px;
}

.generate-names-list__name.selected {
  border: 2px solid #f1666a;
}

.generate-names-list__name.selected p {
  color: #f1666a;
}

.generate-names__button {
  margin: 0 20px 10px;
  border: none;
  border-radius: 4px;
  background-color: #3e6fd9;
  height: 10%;
  color: #fff;
  font-size: 20px;
}

.generate-names__button:disabled {
  opacity: 0.5;
}

.image-modal {
  border-radius: 4px;
  background-color: #eceef5;
}

.image-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px;
  width: 100%;
  height: 75px;
}

.image-modal-header__title {
  margin-left: 20px;
  color: #242c5c;
  font-size: 26px;
}

.image-modal-header__button-exit {
  margin-right: 20px;
  width: 22px;
  height: 30px;
}

.image-modal__img {
  min-width: 300px;
  max-width: 80vw;
  min-height: 200px;
  max-height: 80vh;
}

.eureka-modal-label {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
  color: #242c5c;
  font-size: 26px;
  font-family: "Inter";
  text-align: center;
}

.eureka-modal-label span {
  height: 33px;
}

.eureka-modal-label span u {
  max-width: 300px;
  word-wrap: break-word;
  margin-right: 6px;
  margin-bottom: -2px;
  margin-left: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.carousel-container__image-container {
  margin-right: 10px;
}

.describe-new-product-page-one {
  margin-bottom: 20px;
  width: 99%;
  font-size: 26px;
}

.subtraction-modal-input {
  margin-bottom: 15px;
}

.eureka-modal__close-benefit-icon {
  position: relative;
  bottom: 37px;
  width: 12px;
  height: 12px;
}

.eureka-modal__add-issue-icon,
.eureka-modal__add-benefit-icon {
  position: relative;
  bottom: 12px;
  width: 14px;
  height: 14px;
  color: #888da8;
}

.eureka-modal__benefit-button {
  margin-left: 10px;
  border: none;
  background: transparent;
  width: 27px;
  height: 26px;
}

.benefit-input {
  margin-bottom: 3px;
}

.benefits-label {
  align-self: flex-start;
  height: 23px;
  color: #242c5c;
  font-size: 16px;
  font-family: "Inter";
}

.eureka-modal__benefit-input-container {
  max-height: 380px;
  overflow: hidden;
  overflow-y: auto;
}

.eureka-modal__benefit-input-container button {
  cursor: pointer;
}

.mt--8 {
  margin-top: -8px;
}

.not-feasible {
  position: absolute;
  bottom: 110px;
  left: 30px;
  border-radius: 4px;
  background-color: rgba(241, 102, 106, 0.15);
  width: 1000px;
  height: 53px;
}

.not-feasible span {
  height: 25px;
  color: #242c5c;
  font-size: 18px;
  font-family: "Inter";
}

.issues-input-container {
  width: 100%;
}

.issues-input-container textarea {
  border: 2px solid #eceef5;
  padding: 14px 16px 14px 16px;
  width: 100%;
  max-height: 95px;
  color: #3e6fd9;
  font-size: 15px;
  line-height: 15px;
}

.issues-input-container textarea::-webkit-input-placeholder {
  color: rgba(36, 44, 92, 0.5);
}

.issues-input-container textarea::placeholder {
  color: rgba(36, 44, 92, 0.5);
}

.dependency-modal-input {
  margin-bottom: 8px;
}

.eureka-modal-benefit {
  margin-bottom: 3px;
}

.initials-container {
  display: flex;
  position: relative;
  top: -4.5px;
  align-items: flex-start;
  cursor: pointer;
  margin-right: 10px;
}

.eureka-modal__issue-input-container {
  max-height: 380px;
  overflow: hidden;
  overflow-y: auto;
}

.eureka-modal__issue-button {
  margin-left: 10px;
  border: none;
  background: transparent;
  width: 27px;
  height: 26px;
}

.eureka-modal__comment-solve-container {
  display: inline;
  position: relative;
  top: -25px;
  margin-left: 58px;
}

.eureka-modal__comment-solve-container span {
  cursor: pointer;
  color: #75798d;
  font-size: 14px;
}

.thread-container {
  margin-bottom: 7px;
  margin-left: 42px;
  width: calc(100% - 42px);
}

.width-90 {
  width: 90%;
}

.semitransparent {
  opacity: 0.5;
}

.eureka-modal__button-ai {
  border: none;
  border-radius: 4px;
  background-color: #f9e9ec;
  padding: 5px 5px 5px 5px;
  height: 10%;
  color: #cc4b4f;
  font-weight: 500;
}

.eureka-modal__button-ai:hover {
  background-color: #cc4b4f;
  color: #fff;
}

.eureka-modal__button-ai_challenges {
  margin: 0 0 15px 25px;
  width: 230px;
  font-size: 14px;
}

.eureka-modal__button-ai_name-idea,
.eureka-modal__button-ai_image {
  position: relative;
  width: 50%;
  font-size: 14px;
}

.eureka-modal__button-ai_name-idea {
  top: 31px;
  left: 190px;
}

.eureka-modal__button-ai_image {
  top: 28px;
  left: 94px;
}

.eureka-modal__button-ai_image p {
  margin: 0;
}

.eureka-modal__button-ai_disabled,
.eureka-modal__button-ai_disabled:hover {
  background-color: #888da8;
  color: #fff;
}

.eureka-modal-container__label {
  margin-bottom: 15px;
  width: 50%;
  text-align: left;
}

.eureka-modal-container {
  justify-content: space-around;
  align-items: flex-start;
  margin-bottom: 5px;
}

.eureka-modal-container__name,
.eureka-modal-container__describe {
  width: 380px;
}

.eureka-modal-container__describe {
  margin-top: 15px;
  margin-right: 5px;
}

.eureka-modal-benefit__initials {
  position: relative;
  right: 5px;
  bottom: 53px;
  margin-left: 5px;
}

.eureka-modal-challenges__title.textarea,
.eureka-modal-benefit__title.textarea,
.eureka-modal-challenges-input__empty_title {
  font-size: 18px;
}

.eureka-modal-challenges-input__empty_title {
  padding: 7px 16px;
}

.eureka-modal-challenges-input,
.eureka-modal-benefit-input {
  margin-top: -8px;
}

.eureka-modal-challenges-input__empty,
.eureka-modal-benefit-input__empty {
  background-color: white;
}

.eureka-modal-benefit-line {
  margin-top: 20px;
  border-top: 2px solid #c3cde2;
}

.eureka-modal__button-ai_benefits {
  margin: 0 0 15px 25px;
  width: 208px;
  font-size: 14px;
}

.eureka-modal-challenges__initials {
  position: relative;
  right: 5px;
  bottom: 42px;
  cursor: pointer;
  margin-left: 5px;
}

.eureka-modal__close-issue-icon {
  position: relative;
  bottom: 32px;
  width: 12px;
  height: 12px;
}

.eureka-modal__close-solve-icon,
.eureka-modal__add-solve-icon {
  position: relative;
  bottom: 5px;
}

.eureka-modal__close-solve-icon {
  width: 12px;
  height: 12px;
}

.eureka-modal__add-solve-icon {
  width: 14px;
  height: 14px;
  color: #888da8;
}

.eureka-modal__upload-image-button {
  position: relative;
  top: 17px;
  right: 8px;
  border: 0px;
  border-radius: 4px;
  width: 45%;
  height: 55px;
  font-size: 14px;
}

.eureka-modal__upload-image-button:hover {
  background-color: #cc4b4f;
}

.eureka-modal__upload-image-button_disabled,
.eureka-modal__upload-image-button_disabled:hover,
.eureka-modal__button-ai_image-disabled,
.eureka-modal__button-ai_image-disabled:hover {
  background-color: #888da8;
  color: white;
}

.contradictions-wrapper {
  margin: 20px 40px 0;
  min-height: 500px;
  font-family: "Inter";
}

.contradictions-title {
  margin-right: auto;
  height: 42px;
  color: #242c5c;
  font-size: 30px;
  font-family: "Inter";
  white-space: nowrap;
}

.contradiction-matrix-table {
  margin-top: 20px;
  width: calc(100% - 100px);
  max-width: 1200px;
  min-height: 600px;

  th {
    border: 1px black solid;
  }

  td {
    border: 1px #949494 solid;
  }
}

.no-border-th {
  border: none !important;
}

.contradiction_name_table_cell {
  font-weight: 700;
  font-size: 24px;
  text-align: start;
}

.contradiction_requirements_table_cell {
  font-weight: 400;
  font-size: 16px;
  text-align: start;
}

.contradiction_idea_btn {
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 16px;
  padding: 10px;
}

.contradiction_table_idea_cell {
  position: relative;
}

.contradiction_table_dropdown__additional_style_container {
  position: relative;
  top: -20px;
}

.contradiction_table___tooltip_container {
  z-index: 2 !important;
}

.contradiction_table_dropdown__additional_style_container_upper_cell {
  top: -170px;
  .cell-dropdown::after {
    top: 100%;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .cell-dropdown::before {
    top: 100%;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
}

.contradiction_table_dropdown {
  background-color: red;
}

.contradiction_idea_btn_not_finished {
  background-color: rgb(255, 244, 222);
}

.button-ai {
  border: none;
  border-radius: 4px;
  background-color: #f9e9ec;
  padding: 7px 7px 7px 7px;
  height: 60px;
  color: #cc4b4f;
  font-weight: 500;
}

.ai_button_container {
  display: flex;
  justify-content: flex-start;
  grid-gap: 24px;
  gap: 24px;
  margin-top: 40px;
  width: 100%;
}

.add_contradiction_button {
  margin-top: 20px;
  outline: none;
  border: none;
  border-radius: 16px;
  background-color: #d4def2;
  padding: 16px;
  color: #3e6fd9;

  &:hover {
    background-color: #3e6fd9;
    color: white;
  }
}

.editable_content {
  display: inline-block;
  min-width: 100px;
}

.issues-main {
  padding-bottom: 60px;
}
.issue-display-container {
  align-self: center;
  width: 90%;
}
.issue-display-container h2 {
  font-family: "Inter";
}
.issue-display-title {
  align-self: center;
  width: 95%;
  font-size: 24px;
  font-family: "Inter";
  text-align: left;
}

.import-dialog-wrapper {
  position: relative;
  border-radius: 4px;
  width: 622px;
  min-height: 400px;
  overflow: hidden;
  font-family: "Inter";
}

.project-dialog-header {
  background-color: #eceef5;
  padding: 0 28px;
  width: 100%;
  height: 155px;
}

.project-dialog-header-top {
  margin-top: 30px;
}

.project-dialog-header-icon {
  margin-right: 10px;
}

.project-dialog-title {
  height: 33px;
  color: #242c5c;
  font-weight: 600;
  font-size: 24px;
  font-family: "Inter";
}

.project-dialog-body {
  padding: 26px 32px 110px 32px;
  width: 100%;
}

.project-dialog-buttons {
  position: absolute;
  right: 32px;
  bottom: 32px;
}

.project-dialog-button {
  outline: none !important;
  border: none;
  border-radius: 4px;
  width: 105px;
  height: 48px;
  font-weight: 500;
  font-size: 16px;
}

.project-dialog-button.secondary {
  margin-right: 10px;
  background-color: transparent;
  color: #888da8;
}

.project-dialog-tab {
  position: relative;
  align-self: flex-end;
  width: 50%;
  height: 46px;
}

.import-csv-header {
  margin-bottom: 5px;
  font-size: 20px;
  text-align: left;
}

.import-csv-component-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
}

.import-csv-label {
  margin-bottom: 5px;
  font-size: 18px;
  text-align: left;
}

.import-csv-name-container {
  display: flex;
}

.project-dialog-wrapper {
  position: relative;
  border-radius: 4px;
  width: 622px;
  min-height: 731px;
  overflow: hidden;
  font-family: "Inter";
}

.project-dialog-header {
  background-color: #eceef5;
  padding: 0 28px;
  width: 100%;
  height: 155px;
}

.project-dialog-header-top {
  margin-top: 30px;
}

.project-dialog-header-icon {
  margin-right: 10px;
}

.project-dialog-title {
  height: 33px;
  color: #242c5c;
  font-weight: 600;
  font-size: 24px;
  font-family: "Inter";
}

.project-dialog-body {
  padding: 26px 32px 110px 32px;
  width: 100%;
}

.project-dialog-label {
  margin-bottom: 5px;
  height: 24px;
  color: #465664;
  font-weight: 500;
  font-size: 16px;
}

.project-dialog-buttons {
  position: absolute;
  right: 32px;
  bottom: 32px;
}

.project-dialog-button {
  outline: none !important;
  border: none;
  border-radius: 4px;
  width: 105px;
  height: 48px;
  font-weight: 500;
  font-size: 16px;
}

.project-dialog-button.secondary {
  margin-right: 10px;
  background-color: transparent;
  color: #888da8;
}

.project-dialog-tab {
  position: relative;
  align-self: flex-end;
  width: 50%;
  height: 46px;
}

.project-dialog-tab span {
  width: 67px;
  height: 27px;
  color: #888da8;
  font-size: 20px;
  font-family: "Inter";
}

.project-dialog-tab.active span {
  color: #3e6fd9;
}

.project-tab-line {
  position: absolute;
  bottom: 0;
  background-color: #3e6fd9;
  width: 310px;
  height: 3px;
}

.project-tab-line.right {
  left: 0;
}

.project-tab-line.left {
  right: 0;
}

.align-self-start {
  align-self: flex-start;
}

.number-input {
  border: 2px solid #eceef5;
  border-radius: 4px;
  padding-left: 30px;
  width: 110px;
  height: 43px;
}

.project-type-radio {
  font-weight: 600;
}

.project-name span {
  font-weight: 500;
}

.project-name-placeholder {
  font-size: 15px;
}

.project-name-placeholder::-webkit-input-placeholder {
  opacity: 0.7;
  color: #3e6fd9;
}

.project-name-placeholder::placeholder {
  opacity: 0.7;
  color: #3e6fd9;
}

.delete-popup.modal-type-error {
  height: 250px;
}

.border-error {
  border: 1px solid rgb(203, 26, 26);
}

.project-edit-modal__button-ai_image {
  position: relative;
  top: 31px;
  left: 80px;
  width: 40%;
  font-size: 14px;
}

.project-edit-modal__button-ai_image p {
  margin: 0;
}

.project-edit-modal__button-ai {
  border: none;
  border-radius: 4px;
  background-color: #f9e9ec;
  padding: 7px 7px 7px 7px;
  height: 10%;
  color: #cc4b4f;
  font-weight: 500;
}

.project-edit-modal__button-ai:hover {
  background-color: #cc4b4f;
  color: #fff;
}

.project-edit-modal__button-ai_disabled,
.project-edit-modal__button-ai_disabled:hover {
  background-color: #888da8;
  color: #fff;
}

.project-edit-modal__upload-image-button {
  position: relative;
  top: 44px;
  right: 55px;
  border: 0px;
  border-radius: 4px;
  width: 40%;
  height: 59px;
  font-size: 14px;
}
.project-edit-modal__upload-image-button:hover {
  background-color: #cc4b4f;
}
.project-edit-modal__upload-image-button_disabled,
.project-edit-modal__upload-image-button_disabled:hover,
.project-edit-modal__button-ai_image-disabled,
.project-edit-modal__button-ai_image-disabled:hover {
  background-color: #888da8;
  color: white;
}

.folder-rename-dialog-wrapper {
  position: relative;
  border-radius: 4px;
  background: white;
  min-width: 400px;
  max-width: 100vw;
  min-height: 300px;
  max-height: 100vh;
  overflow: hidden;
  font-family: "Inter";
}
.folder-input-container {
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
}
.folder-input-label {
  text-align: left;
}

.projects-title {
  height: 41px;
  color: #242c5c;
  font-size: 30px;
  font-family: "Inter";
}

.add-project-button-round {
  margin-left: 20px;
  color: #3e6fd9;
}

.add-project-button-round:hover {
  color: #3061ae;
}

.dashboard-project-count {
  margin-left: 10px;
  height: 41px;
  color: #838fdb;
  font-size: 30px;
  font-family: "Inter";
}

.dashboard-wrapper {
  margin: 0 40px;
  min-width: 560px;
  font-family: "Inter";
}

.input-label {
  margin-right: 10px;
  height: 22px;
  color: #888da8;
  font-size: 16px;
  font-family: "Inter";
}

.project-input-container {
  margin-left: 20px;
  white-space: nowrap;
}

.search-input-container.project-search {
  margin-left: 20px;
}

.dashboard-input {
  border: 2px solid #cdd1e5;
  border-radius: 4px;
  background-color: transparent;
  padding: 0 14px;
  min-width: 190px;
  height: 41px;
  color: #242c5c;
  font-size: 15px;
  line-height: 41px;
  font-family: "Inter";
}

.dashboard-input::-webkit-input-placeholder {
  color: #242c5c;
}

.dashboard-input::placeholder {
  color: #242c5c;
}

.header-image-container {
  position: relative;
  margin: -12px 0 30px;
  height: 170px;
}

.blue-rectangle {
  align-self: flex-end;
  border-radius: 4px;
  background-color: #3e6fd9;
  width: 100%;
  height: 143px;
}

.welcome-container {
  align-items: flex-start;
  align-self: center;
  z-index: 10;
  margin-left: 36px;
  text-align: left;
}

.welcome-title {
  color: #ffffff;
  font-size: 30px;
  font-family: "Inter";
}

.welcome-text {
  color: #b3c6f0;
  font-size: 26px;
  line-height: 28px;
  font-family: "Inter";
}

.info-container {
  align-items: flex-start;
  align-self: center;
  border: 2px solid #3e6fd9;
  border-radius: 4px;
  background-color: #d4def2;
  padding: 20px;
  width: 100%;
  height: auto;
  color: #242c5c;
  font-size: 22px;
  line-height: 24px;
  font-family: "Inter";
  text-align: left;
}

.image-container {
  position: absolute;
  right: 0;
  margin-top: 26px;
  margin-right: -24px;
  width: 397px;
  height: 144px;
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: 100%;
}

.project-container {
  margin-right: 13px;
  margin-bottom: 15px;
}

.search-input {
  padding: 0px 40px 0 14px;
  font-size: 17px;
}

.white {
  color: white;
}

.project-input-container.first {
  margin-left: auto;
}

.create-folder-icon {
  margin-left: 10px;
  border-radius: 50%;
  background-color: #3e6fd9;
  width: 32px;
  height: 32px;
}

.reverse-sort-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  outline: none !important;
  border: 1px solid #3e6fd9;
  border: 2px solid #cdd1e5;
  border-radius: 4px;
  background-color: #f7f8fa;
  padding: 0 10px 0 15px;
  height: 41px;
  color: #242c5c;
}
.reverse-sort-button:hover {
  background-color: white;
}
.reverse-sort-button svg {
  position: relative;
  left: -3px;
}

.my-projects-tooltip {
  width: auto !important;
}

.folder-icon-class {
  position: relative;
  top: 2px;
}

.invitation-button {
  outline: none !important;
  border: none;
  border-radius: 4px;
  background-color: #3e6fd9;
  width: 142px;
  height: 36px;
}

.invitation-button-container {
  padding: 0 18px;
}

.invitation-button.gray {
  background-color: #888da8;
}

.invitation-button.gray:hover {
  background-color: #666a86;
}

.invitation-button span {
  height: 19px;
  color: #ffffff;
  font-size: 16px;
  font-family: "Inter";
  text-transform: uppercase;
}


.folder {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  transition: all 0.2s ease;
  cursor: pointer;
  background-color: #ffe9a2;
}

.folder-header {
  display: flex;
  justify-content: flex-end;
  padding-inline: 20px;
  padding-top: 20px;
  width: 100%;
}

.folder-rectangle {
  position: absolute;
  top: -74px;
  left: -145px;
  transition: all 0.2s ease;
  border-right: 50px solid transparent;
  border-bottom: 100px solid #e9d48f;
  border-left: 50px solid transparent;
}

.folder-rectangle-rotated {
  top: -74px;
  left: -145px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  transition: all 0.2s ease;
}

.folder-square {
  position: absolute;
  top: -24px;
  left: -283px;
  transition: all 0.2s ease;
  background-color: #e9d48f;
  width: 138px;
  height: 50px;
}
.folder-title {
  margin-bottom: 10px;
  width: 100%;
}

.folder-title > span {
  display: block;
  margin-inline: 20px;
  width: calc(100% - 40px);
  overflow: hidden;
  -webkit-user-select: none;
          user-select: none;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.project-count-container {
  position: relative;
}
.project-count-text {
  position: absolute;
  top: -176px;
  left: 12px;
  opacity: 0.5;
  font-size: 24px;
}
.folder.isDragged {
  background-color: #3e6fd9;
}

.folder.isDragged .folder-rectangle-rotated {
  border-bottom: 100px solid #3e6fd9;
}

.folder.isDragged .folder-square {
  background-color: #3e6fd9;
}

.folder.isDragged > * > span {
  color: white;
}

.folder.isDragged .project-count-text {
  opacity: 1;
  color: white;
}

.folder-context-button {
  background-color: #00000000;
}

.add-project {
  border: 1px solid #3e6fd9;
  border-radius: 4px;
  width: 333px;
  height: 217px;
  color: #3e6fd9;
}

.isDragged {
  -webkit-animation: isDragged 1s infinite;
          animation: isDragged 1s infinite;
}

@-webkit-keyframes isDragged {
  25% {
    -webkit-transform: rotate(0.5deg);
            transform: rotate(0.5deg);
  }
  50% {
    -webkit-transform: rotate(-0.5deg);
            transform: rotate(-0.5deg);
  }
  75% {
    -webkit-transform: rotate(0.5deg);
            transform: rotate(0.5deg);
  }
  100% {
    -webkit-transform: rotate(-0.5deg);
            transform: rotate(-0.5deg);
  }
}

@keyframes isDragged {
  25% {
    -webkit-transform: rotate(0.5deg);
            transform: rotate(0.5deg);
  }
  50% {
    -webkit-transform: rotate(-0.5deg);
            transform: rotate(-0.5deg);
  }
  75% {
    -webkit-transform: rotate(0.5deg);
            transform: rotate(0.5deg);
  }
  100% {
    -webkit-transform: rotate(-0.5deg);
            transform: rotate(-0.5deg);
  }
}

.add-project span {
  color: #3e6fd9;
  font-size: 24px;
  font-family: "Inter";
}

.add-project-icon {
  margin-bottom: 15px;
}

.add-project:hover {
  background-color: #3e6fd9;
  color: white;
}

.add-project:hover span {
  color: white;
}

.project {
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s;
  margin: 0 13px 15px 0;
  border: 1px solid #c7ccec;
  border-radius: 4px;
  width: 333px;
  height: 217px;
  overflow: hidden;
}

.project img {
  pointer-events: none;
}

.project:hover {
  border-color: #3e6fd9;
}

.project-title-container {
  position: relative;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0 0;
  padding: 0 17px;
  width: 100%;
  text-align: left;
}

.menu-button {
  outline: none !important;
  border: none;
  background-color: #f7f8fa;
  width: 30px;
  height: 30px;
}

.project-title {
  width: 70%;
  max-height: 44px;
  overflow: hidden;
  color: #242c5c;
  font-size: 20px;
  line-height: 22px;
  font-family: "Inter";
  text-overflow: ellipsis;
  word-break: break-word;
}

.project-title-name {
  margin-bottom: 0;
  width: 50%;
  color: #3e6fd9;
}

.project-info {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  padding: 0 10px 0 10px;
  width: 100%;
  width: 98%;
  min-height: 20px;
}

.icons-left {
  min-width: 80px;
  max-width: 110px;
}

.flex.icons-left {
  justify-content: space-evenly;
}

.components {
  display: flex;
  justify-content: space-evenly;
  width: 50%;
}

.project-info-tooltip {
  margin-bottom: 0;
  overflow: hidden;
  color: #242c5c;
  font-size: 22px;
  font-family: "Inter";
  text-overflow: ellipsis;
  white-space: nowrap;
}

.project-info-tooltip-message {
  background-color: white;
  width: auto;
  max-width: 300px;
  color: #3e6fd9;
}

.project-info-tooltip-message.top::after {
  border-color: white transparent transparent transparent;
}

.initials {
  border-radius: 4px;
  background-color: #fdeeee;
  width: 32px;
  height: 26px;
  color: #f1666a;
  font-size: 16px;
  line-height: 26px;
  font-family: "Inter";
}

.green-button {
  background-color: #e9f7ed;
  color: #47b869;
  font-size: 15px;
}

.blue-button {
  background-color: #ecf1fb;
  color: #3e6fd9;
  font-size: 14px;
}

.blue-button.project-button {
  justify-content: space-between;
  align-items: center;
}

.blue-button.project-button span {
  height: 21px;
  font-size: 16px;
  line-height: 21px;
}

.green-button.project-button span {
  height: 21px;
  font-size: 17px;
  line-height: 21px;
}

.project-button {
  outline: none !important;
  border: none;
  border-radius: 4px;
  padding: 0 15px 0 15px;
  width: 125px;
  height: 36px;
  font-family: "Inter";
}

.project-info-icons {
  justify-content: space-around;
  margin: 0 10px 0 10px;
  width: 98%;
}

.project-button img {
  margin-right: 8px;
}

.ideas-count-projects {
  display: flex;
  align-items: center;
  width: 187px;
}

.ideas-count-projects svg {
  margin-left: 8px;
}

.max-width-50 {
  max-width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.folder-delete-confirmation-modal-text {
  height: auto !important;
}

.folder-delete-popup {
  height: auto !important;
}

.report-print-button-container {
  display: flex;
  align-items: start;
  padding-left: 10px;
  width: 100%;
}

.report-print-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid #cbcaca;
  padding: 5px;
  color: #3e6fd9;
}

.project-dashboard-tabs {
  background-color: #eceef5;
  height: 55px;
}

.project-dashboard-tab {
  border-bottom: 3px solid transparent;
  width: 200px;
  height: 100%;
  overflow: hidden;
}

.project-dashboard-tab-counter {
  margin-left: 10px;
  height: 28px;
  height: 28px;
  color: #838fdb;
  font-size: 20px;
}

.project-dashboard-tab-title {
  height: 28px;
  overflow: hidden;
  color: #242c5c;
  font-size: 20px;
  font-family: "Inter";
  text-overflow: ellipsis;
  white-space: nowrap;
}

.project-dashboard-tab.active .project-dashboard-tab-title {
  color: #3e6fd9;
}

.project-dashboard-tab.active {
  border-bottom: 3px solid #3e6fd9;
}

.matrix-dialog-wrapper {
  position: relative;
  border-radius: 4px;
  background: white;
  width: 1061px;
  min-width: 768px;
  max-width: 100vw;
  height: 792px;
  min-height: 768px;
  max-height: 100vh;
  overflow: hidden;
  font-family: "Inter";
}

.matrix-dependency img {
  pointer-events: none;
}

.matrix-dialog-header {
  position: relative;
  background-color: #eceef5;
  width: 100%;
  height: 300px;
}

.matrix-dialog-header-icon {
  margin-right: 10px;
  width: 26px;
}

.matrix-dialog-title-container {
  justify-content: space-between;
  align-items: center;
  align-self: flex-start;
  margin-top: 20px;
  padding: 0 28px;
  width: 100%;
}

.matrix-dialog-title {
  height: 38px;
  color: #242c5c;
  font-size: 26px;
  line-height: 38px;
  font-family: "Inter";
}

.matrix-dialog-body {
  padding: 20px 140px 0;
  width: 100%;
}

.matrix-dialog-body.benefits-container {
  padding: 40px 0 0;
}

.matrix-dialog-label {
  align-self: flex-start;
  height: 42px;
  color: #242c5c;
  font-size: 20px;
  font-family: "Inter";
}

.matrix-dialog-footer {
  position: absolute;
  bottom: 0;
  border-top: 2px solid #eceef5;
  padding: 0 25px;
  width: 100%;
  height: 60px;
}

.matrix-dialog-button {
  outline: none !important;
  border: none;
  border-radius: 4px;
  width: 105px;
  height: 48px;
  font-size: 14px;
}

.matrix-dialog-button.disabled {
  background-color: #888da8;
  color: white;
}

.matrix-dialog-button.secondary {
  margin-right: 10px;
  background-color: transparent;
  color: #888da8;
}

.matrix-dialog-button span {
  height: 20px;
  font-size: 18px;
  font-family: "Inter";
}

.dialog-buttons-arrow {
  line-height: 20px;
  font-family: "Inter";
}

.dialog-buttons-arrow-right {
  margin-bottom: 4px;
  margin-left: 10px;
  font-size: 20px;
}

.dialog-buttons-arrow-left {
  margin-right: 10px;
  margin-bottom: 4px;
  font-size: 20px;
}

.dialog-buttons-arrow-right {
  margin-bottom: 4px;
  margin-left: 10px;
  font-size: 20px;
}

.dialog-buttons-arrow-left {
  margin-right: 10px;
  margin-bottom: 4px;
  font-size: 20px;
}

.matrix-dialog-button.control {
  width: 131px;
}

.matrix-dialog-button.outlined {
  border: 2px solid #3e6fd9;
  background-color: white;
}

.matrix-dialog-button.outlined span {
  color: #3e6fd9;
}

.dependency-text {
  display: -webkit-box;
  align-self: center;
  width: 90%;
  overflow: hidden;
  color: #242c5c;
  font-size: 20px;
  font-family: "Inter";
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 60px;
  line-height: 30px;
}

.dependency-container {
  z-index: 100;
  border: 2px solid #c0c4de;
  border-radius: 4px;
  background-color: white;
  width: 100%;
  min-width: 320px;
  max-width: 485px;
  height: 52px;
}

.dependency-component {
  width: 240px;
  height: 50px;
}

.dependency-component img {
  margin-right: 10px;
  margin-left: 10px;
}

.dependency-component span,
.dependency-attribute span {
  max-width: calc(100% - 40px);
  height: 26px;
  overflow: hidden;
  color: #242c5c;
  font-size: 18px;
  font-family: "Inter";
  letter-spacing: -0.3px;
  text-overflow: ellipsis;
}

.dependency-attribute {
  border-right: 2px solid #c0c4de;
  width: 240px;
  height: 50px;
}

.dependency-attribute img {
  margin-right: 10px;
  margin-left: 10px;
}

.matrix-dependency {
  padding: 0 28px;
}

.matrix-dialog-controls img {
  margin-left: 30px;
}

.feasible-idea {
  border-radius: 4px;
  background-color: #eceef5;
  height: 110px;
}

.feasible-idea.negative {
  background-color: rgba(241, 102, 106, 0.29);
}

.feasible-idea.positive {
  background-color: rgba(90, 197, 122, 0.2);
}

.feasible-idea-text {
  margin-bottom: 10px;
  height: 25px;
  color: #242c5c;
  font-size: 18px;
  font-family: "Inter";
}

.flex-1 {
  flex: 1 1;
}

.idea-rating-label {
  height: 42px;
  color: #242c5c;
  font-size: 20px;
  font-family: "Inter";
}

.idea-rating-container {
  align-self: center;
  margin-bottom: 40px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.dependency-influence {
  background-color: #cdd1e5;
  width: 100%;
  min-width: 10px;
  max-width: 35px;
  min-height: 3px;
}

.dependency-influence span {
  color: #242c5c;
  font-size: 18px;
  font-family: "Inter";
}

.dependency-tabs-circle {
  position: relative;
  transition: border-color 0.5s;
  border: 3px solid transparent;
  border-radius: 20px;
  background-color: #eceef5;
  width: 25px;
  min-width: 25px;
  height: 25px;
}

.dependency-tabs-circle.active {
  transition: border-color 0.5s;
  border-color: #3e6fd9;
}

.dependency-tab-button span {
  transition: color 0.5s;
  height: 22px;
  color: #888da8;
  font-size: 16px;
  font-family: "Inter";
  white-space: nowrap;
}

.dependency-tab-label.active {
  transition: color 0.5s;
  color: #3e6fd9;
}

.dependency-tab-button {
  position: absolute;
  bottom: 20px;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  outline: none !important;
  border: none;
  background-color: transparent;
  padding: 0 10px;
  height: 36px;
  line-height: 1.1;
}

.dependency-tabs-line-container {
  width: 178px;
  min-width: 300px;
  height: 3px;
  min-height: 3px;
}

.dependency-tabs-line {
  background-color: transparent;
  width: 178px;
  min-width: 235px;
  height: 3px;
  min-height: 3px;
}

.dependency-tabs-container {
  position: absolute;
  bottom: -12px;
  left: -170px;
  justify-content: space-evenly;
  width: 96%;
}

.dependency-tabs-line {
  transition: background-color 0.5s;
  background-color: transparent;
  min-width: 315px;
}

.dependency-tabs-line.active {
  transition: background-color 0.5s;
  background-color: #3e6fd9;
}

.dependency-tabs-circle svg {
  position: absolute;
  top: -5px;
  left: -5px;
  opacity: 0;
  transition: opacity 0.5s;
  width: 29px;
  min-width: 29px;
  height: 29px;
  min-height: 29px;
}

.dependency-tabs-circle svg.completed {
  opacity: 1;
  transition: opacity 0.5s;
}

.dependency-tab {
  min-width: 235px;
  max-width: 20%;
}

.matrix-dialog-header-imagine {
  margin: 0 10px 0 10px;
  width: 98%;
  color: #242c5c;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
}

.matrix-dialog-header-imagine u {
  margin: 0 10px 0 10px;
  padding-bottom: 20px;
}

.dependency_tooltip {
  margin-bottom: 0;
  color: #242c5c;
  font-size: 18px;
  white-space: nowrap;
}

.dependency-modal-label {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
  color: #242c5c;
  font-size: 26px;
  font-family: "Inter";
  text-align: center;
}

.dependency-modal-label span {
  height: 33px;
}

.dependency-modal-label span u {
  max-width: 300px;
  word-wrap: break-word;
  margin-right: 6px;
  margin-bottom: -2px;
  margin-left: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dependency-modal-input {
  margin-bottom: 8px;
}

.dependency-modal__close-issue-icon,
.dependency-modal__close-benefit-icon {
  position: relative;
  bottom: 44px;
  width: 12px;
  height: 12px;
}

.dependency-modal__add-issue-icon,
.dependency-modal__add-benefit-icon {
  position: relative;
  bottom: 20px;
  width: 14px;
  height: 14px;
  color: #888da8;
}

.dependency-modal__benefit-button {
  margin-left: 10px;
  border: none;
  background: transparent;
  width: 27px;
  height: 26px;
}

.benefits-label {
  align-self: flex-start;
  height: 23px;
  color: #242c5c;
  font-size: 16px;
  font-family: "Inter";
}

.dependency-modal-benefit-input-container {
  padding: 0 140px;
  max-height: 380px;
  overflow: hidden;
  overflow-y: auto;
}

.not-feasible {
  position: absolute;
  bottom: 110px;
  left: 30px;
  border-radius: 4px;
  background-color: rgba(241, 102, 106, 0.15);
  width: 1000px;
  height: 53px;
}

.not-feasible span {
  height: 25px;
  color: #242c5c;
  font-size: 18px;
  font-family: "Inter";
}

.issues-input-container {
  width: 100%;
}

.dependency-modal-challenges__initials {
  position: relative;
  right: 5px;
  bottom: 42px;
  cursor: pointer;
  margin-left: 5px;
}

.issue-input-container {
  padding: 0 140px;
  max-height: 380px;
  overflow: hidden;
  overflow-y: auto;
}

.dependency-modal__issue-button {
  margin-left: 10px;
  border: none;
  background: transparent;
  width: 27px;
  height: 26px;
}

.dependency-modal__comment-solve-container {
  display: inline;
  position: relative;
  top: -25px;
  margin-left: 58px;
}

.dependency-modal__comment-solve-container span {
  cursor: pointer;
  color: #75798d;
  font-size: 14px;
}

.thread-container {
  margin-bottom: 7px;
  margin-left: 42px;
  width: calc(100% - 42px);
}

.width-90 {
  width: 90%;
}

.semitransparent {
  opacity: 0.5;
}

.dependency-modal-description {
  color: #242c5c;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.dependency-modal-description u {
  margin: 0 10px 0 10px;
}

.dependency-modal__button-ai {
  border: none;
  border-radius: 4px;
  background-color: #f9e9ec;
  padding: 7px 7px 7px 7px;
  height: 10%;
  color: #cc4b4f;
  font-weight: 500;
}

.dependency-modal__button-ai:hover {
  background-color: #cc4b4f;
  color: #fff;
}

.dependency-modal-container {
  justify-content: space-around;
  align-items: flex-start;
  margin-bottom: 5px;
}

.dependency-modal__button-ai_benefits {
  margin: 0 0 15px 25px;
  width: 208px;
  font-size: 14px;
}

.dependency-modal__button-ai_challenges {
  margin: 0 0 15px 25px;
  width: 230px;
  font-size: 14px;
}

.dependency-modal__button-ai_name-idea,
.dependency-modal__button-ai_image {
  position: relative;
  top: 31px;
  width: 50%;
  font-size: 14px;
}

.dependency-modal__button-ai_name-idea {
  left: 190px;
}

.dependency-modal__button-ai_image {
  left: 10px;
}

.dependency-modal__button-ai_image p {
  margin: 0;
}

.dependency-modal-container__name,
.dependency-modal-container__describe {
  width: 380px;
}

.dependency-modal-container__label {
  margin-bottom: 13px;
  width: 50%;
  text-align: left;
}

.dependency-modal-container__describe {
  margin-top: 15px;
  margin-right: 5px;
}

.dependency-modal__challenges-header {
  margin-bottom: 15px;
  height: 33px;
  color: #242c5c;
  font-size: 26px;
  font-family: "Inter";
}

.dependency-modal-challenges-input,
.dependency-modal-benefit-input {
  margin-top: -8px;
}

.dependency-modal-benefit-input__empty,
.dependency-modal-challenges-input__empty {
  background-color: white;
}

.dependency-modal-benefit__title.textarea,
.dependency-modal-challenges-input__empty_title {
  font-size: 18px;
}

.dependency-modal-challenges-input__empty_title {
  padding: 7px 16px;
}

.dependency-modal-benefit {
  margin-bottom: 3px;
}

.dependency-modal-benefit__initials {
  position: relative;
  right: 5px;
  bottom: 53px;
  margin-left: 5px;
}

.dependency-modal-benefit-line {
  margin-top: 20px;
  border-top: 2px solid #c3cde2;
}

.dependency-modal__close-solve-icon,
.dependency-modal__add-solve-icon {
  position: relative;
  bottom: 5px;
}

.dependency-modal__close-solve-icon {
  width: 12px;
  height: 12px;
}

.dependency-modal__add-solve-icon {
  width: 14px;
  height: 14px;
  color: #888da8;
}

.initials-container {
  display: flex;
  position: relative;
  top: -4.5px;
  align-items: flex-start;
  cursor: pointer;
  margin-right: 10px;
}

.dependency-modal__upload-image-button {
  position: relative;
  top: 44px;
  right: 8px;
  border: 0px;
  border-radius: 4px;
  width: 45%;
  height: 59px;
  font-size: 14px;
}

.dependency-modal__upload-image-button:hover {
  background-color: #cc4b4f;
}

.dependency-modal__upload-image-button_disabled,
.dependency-modal__upload-image-button_disabled:hover,
.dependency-modal__button-ai_image-disabled,
.dependency-modal__button-ai_image-disabled:hover {
  background-color: #888da8;
  color: white;
}

.carousel-container__image-container {
  margin-right: 10px;
}

.dependency-matrix-wrapper {
  margin: 20px 40px 0;
  min-height: 500px;
  font-family: "Inter";
}

.component-cell-container:hover span {
  color: #3e6fd9;
}

.dependency-matrix-header-tutorial {
  margin: 10px 0 20px 0;
}

.dependency-matrix-title {
  margin-right: auto;
  height: 42px;
  color: #242c5c;
  font-size: 30px;
  font-family: "Inter";
  white-space: nowrap;
}

.dependency-matrix-table-wrapper {
  margin-top: 50px;
  width: 100%;
  height: calc(100vh - 190px);
  overflow: hidden;
}

.dependency-matrix-container {
  -webkit-transform-origin: left top;
          transform-origin: left top;
  overflow-x: auto;
  overflow-y: auto;
}

.table-cell-container {
  border: 1px solid #d7dbe8;
  width: 63px;
  height: 34px;
}

.table-cell {
  border-radius: 4px;
  background-color: #f7f8fa;
  width: 63px;
  height: 34px;
}

.table-cell.pointer.hover {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
  z-index: 3;
  box-shadow: 0 3px 6px rgba(36, 44, 92, 0.4);
  border: 1px solid #3e6fd9;
}

.attribute-cell {
  justify-content: flex-start;
  z-index: 1;
  border: 1px solid transparent;
  width: 120px;
  height: 34px;
}

.attribute-cell:hover {
  border: 1px solid #3e6fd9;
  border-radius: 4px;
}

.matrix-attribute-title {
  max-width: 100%;
  height: auto;
  min-height: 13px;
  max-height: 34px;
  overflow: hidden;
  color: #5b6185;
  font-size: 12px;
  line-height: 12px;
  font-family: "Inter";
  text-align: left;
  word-break: break-word;
}

.matrix-attribute-title.column {
  text-align: center;
}

.component-cell {
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  width: 123px;
  height: 100%;
}

.component-cell:hover {
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid #3e6fd9;
  border-radius: 4px;
  background-color: white;
  color: #3e6fd9;
  text-decoration: underline;
}

.matrix-component-title {
  display: block;
  max-width: 130px;
  height: auto;
  min-height: 16px;
  max-height: 34px;
  color: #5b6185;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  text-overflow: ellipsis;
  word-break: break-word;
}

.matrix-component-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  height: 34px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.matrix-component-title-container:hover {
  cursor: pointer;
  border: 1px solid #3e6fd9;
  border-radius: 4px;
  background-color: white;
  color: #3e6fd9;
  text-decoration: underline;
}

.matrix-component-title-container:hover .matrix-component-title {
  color: #3e6fd9;
}

.matrix-attribute-title-container {
  z-index: 1;
  width: 63px;
  height: 34px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.matrix-attribute-title-container:hover {
  cursor: pointer;
  border: 1px solid #3e6fd9;
  border-radius: 4px;
  background-color: white;
  color: #3e6fd9;
  text-decoration: underline;
}

.matrix-attribute-title-container:hover span {
  color: #3e6fd9;
}

.dependency-matrix-table td {
  border: 1px solid #d7dbe8;
}

.dependency-matrix-table tr td:last-of-type {
  border-right: none;
}

.dependency-matrix-table tr td:first-of-type {
  border-right: none;
  border-left: none;
}

.dependency-matrix-table tr:first-of-type td {
  z-index: 3;
  border-top: none;
  border-bottom: none;
}

.dependency-matrix-table tr:nth-of-type(2) td {
  border-top: none;
}

.dependency-matrix-table tr:last-of-type td {
  border-bottom: none;
}

.component-type-indicator-container {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
  margin: 0;
  background: #f7f8fa;
  padding-top: 72px;
  width: 87px;
  min-width: 87px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.dependency-matrix-table tr:nth-of-type(1) td:not(:first-of-type) {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 87px;
  background-color: #f7f8fa;
}

.dependency-matrix-table tr:nth-of-type(2) td {
  position: -webkit-sticky;
  position: sticky;
  top: 36px;
  left: 87px;
  box-shadow: 0px 2px 0px -1px #d7dbe8;
  background-color: #f7f8fa;
}

td.component-cell-container {
  position: -webkit-sticky;
  position: sticky;
  left: 87px;
  z-index: 1;
  background-color: #f7f8fa;
  overflow: hidden;
  text-overflow: ellipsis;
}

tr td:first-of-type.component-cell-container {
  border-bottom: none;
}

td.attribute-cell-container {
  position: -webkit-sticky;
  position: sticky;
  left: 209px;
  z-index: 1;
  box-shadow: 2px 0px 0px -1px #d7dbe8;
  background-color: #f7f8fa;
}

.attribute-cell-container:hover {
  cursor: pointer;
  background-color: white;
  color: #3e6fd9;
  text-decoration: underline;
}

.attribute-cell-container:hover span {
  color: #3e6fd9;
}

.internal-type-indicator {
  border-radius: 4px;
  background-color: #eceef5;
  width: 27px;
  height: 120px;
}

.external-type-indicator {
  border-radius: 4px;
  background-color: #dfe4f4;
  width: 27px;
  height: 120px;
}

.component-type-text span {
  height: 49px;
  color: rgba(36, 44, 92, 0.75);
  font-size: 14px;
}

.transform-90-deg {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.component-type-text:hover {
  position: absolute;
}

td.highlighted {
  background-color: #d9e2f6 !important;
}

.matrix-corner-cell {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 87px;
  z-index: 1;
  box-shadow: 2px 2px 0px -1px #d7dbe8;
  background-color: #f7f8fa;
}

.table-cell-container {
  position: relative;
}

.cell-mark span {
  color: black;
  font-size: 18px;
  font-family: "Inter";
}

.matrix-input-container {
  margin: 0 0 10px 20px;
}

.matrix-input-container.first {
  margin-left: auto;
}

@media only screen and (max-width: 980px) {
  .matrix-input-container .custom-search-input .custom-search-input-input {
    max-width: 220px;
  }

  .matrix-input-container {
    margin-left: 10px;
  }
}

.cell-mark span.cell-binary-text {
  color: #242c5c;
  white-space: pre-wrap;
}

.matrix-placeholder {
  padding-top: 30px;
}

.matrix-placeholder span {
  height: 38px;
  color: #242c5c;
  font-size: 22px;
  font-family: "Inter";
}

.cell-dropdown {
  position: absolute;
  top: 50px;
  left: calc(50% - 415px / 2);
  z-index: 3;
  border: 2px solid lightblue;
  border-radius: 5px;
  background-color: white;
  width: 415px;
}

.cell-dropdown::before {
  position: absolute;
  top: -21px;
  left: calc(50% - 17px);
  border: 17px solid transparent;
  border-top: 0;
  border-bottom: 20px solid lightblue;
  width: 0;
  height: 0;
  content: "";
}

.cell-dropdown::after {
  position: absolute;
  top: -18px;
  left: calc(50% - 15px);
  border: 15px solid transparent;
  border-top: 0;
  border-bottom: 18px solid white;
  width: 0;
  height: 0;
  content: "";
}

.cell-dropdown-row {
  position: relative;
  margin-top: 5px;
  border: 2px solid transparent;
  border-radius: 4px;
  background-color: #e8f6ec;
  width: 400px;
  height: 40px;
}

.cell-dropdown-row-text {
  max-width: calc(100% - 10px);
  height: 21px;
  overflow: hidden;
  color: #242c5c;
  font-weight: 700;
  font-size: 13px;
  font-family: "Inter";
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cell-dropdown-row.green {
  background-color: #e9f6ed;
}

.cell-dropdown-row.yellow {
  background-color: #fff4de;
}

.cell-dropdown-row.red {
  background-color: #fde6e1;
}

.cell-dropdown-row.white {
  border: 1px solid #cdd1e5;
  background-color: #ffffff;
}

.cell-dropdown-row:hover {
  border: 2px solid #3e6fd9;
}

.cell-dropdown-row:hover.green {
  background-color: #d0e9d8;
}

.cell-dropdown-row:hover.yellow {
  background-color: #f3e3c4;
}

.cell-dropdown-row:hover.red {
  background-color: #f0cec7;
}

.cell-dropdown-row-rating {
  display: flex;
  flex: 1 1 50px;
  justify-content: center;
  z-index: 3;
  min-width: 5%;
}

.cell-dropdown-row-name {
  flex: 1 1 345px;
  min-width: 25%;
}

.cell-dropdown-idea-score {
  border-radius: 50%;
  background-color: #5ac57a;
  width: 30px;
  min-width: 30px;
  height: 30px;
}

.cell-dropdown-idea-score span {
  margin-left: 1.5px;
  height: 26px;
  color: #ffffff;
  font-size: 20px;
}

.cell-dropdown-row-color-rating {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  width: 7px;
  min-width: 7px;
  height: 100%;
}

.cell-dropdown-row:hover .cell-dropdown-row-color-rating {
  border-radius: 2px 0 0 2px;
  height: 100%;
}

.cell-dropdown-row-buttons-container {
  position: absolute;
  right: 0;
  opacity: 0;
  height: 51px;
}

.cell-dropdown-row:hover .cell-dropdown-row-buttons-container {
  opacity: 1;
}

.open-details-button {
  justify-content: space-between;
  align-items: center;
  outline: none;
  border: none;
  border-radius: 4px;
  background-color: #dfe7fa;
  padding: 0 10px 0 20px;
  width: 400px;
  height: 40px;
}

.open-details-button span {
  color: #3e6fd9;
  font-size: 14px;
  font-family: "Inter";
}

.open-details-button svg {
  color: #3e6fd9;
}

.open-details-button:hover {
  background-color: #3e6fd9;
}

.open-details-button:hover span {
  color: white;
}

.open-details-button:hover svg {
  color: white;
}
.open-details-button .button-triangle-icon {
  margin-left: 5px;
}

.open-details-button:hover .button-triangle-icon {
  border-color: transparent transparent transparent white;
}

.open-details-button.edit {
  margin-right: 10px;
  outline: none;
  padding: 0 10px 0 10px;
  width: 77px;
  height: 30px;
}

.open-details-button.medium {
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
  outline: none;
  border: 1px solid #3e6fd9;
}

.terms-of-service-container {
  padding: 50px;
  text-align: left;
}

.center {
  text-align: center;
}

.terms-of-service-container p:not(.center) {
  text-align: justify;
}

.image-container-faq {
  top: 0;
  right: 3.6em;
  margin: 40px 150px;
  border-radius: 3px;
  width: 90mm;
  height: 85mm;
}

.image-container-faq img {
  max-width: 100%;
  max-height: 100%;
}
.article-title {
  font-size: 30px;
}

.privacy-policy-container {
  padding: 50px;
  text-align: left;
}

.privacy-policy-header {
  font-weight: bolder;
  text-decoration: underline;
}

.privacy-policy-container p:not(.center) {
  text-align: justify;
}

.terms-of-service-container {
  padding: 50px;
  text-align: left;
}

.center {
  text-align: center;
}

.terms-of-service-container p:not(.center) {
  text-align: justify;
}

.subtraction-dialog-wrapper {
  position: relative;
  border-radius: 4px;
  background: white;
  width: 1061px;
  min-width: 768px;
  max-width: 100vw;
  height: 792px;
  min-height: 768px;
  max-height: 100vh;
  overflow: hidden;
  font-family: "Inter";
}

.subtraction-dialog-header {
  position: relative;
  background-color: #eceef5;
  width: 100%;
  height: 257px;
}

.subtraction-dialog-header-icon {
  margin-right: 10px;
  height: 26px;
}

.subtraction-dialog-title-container {
  justify-content: space-between;
  align-items: center;
  align-self: flex-start;
  margin-top: 20px;
  padding: 0 28px;
  width: 100%;
}

.subtraction-dialog-title {
  height: 38px;
  color: #242c5c;
  font-size: 30px;
  font-family: "Inter";
}

.subtraction-dialog-body {
  padding: 40px 140px 0;
  width: 100%;
}

.subtraction-dialog-body.benefits-container {
  padding: 40px 0 0;
}

.eureka-dialog-footer {
  position: absolute;
  bottom: 0;
  border-top: 2px solid #eceef5;
  padding: 0 25px;
  width: 100%;
  height: 60px;
}

.eureka-dialog-button {
  cursor: pointer;
  outline: none !important;
  border: none;
  border-radius: 4px;
  width: 105px;
  height: 48px;
  font-size: 16px;
}

.eureka-dialog-button.disabled {
  background-color: #888da8;
  color: white;
}

.eureka-dialog-button.secondary {
  margin-right: 10px;
  background-color: transparent;
  color: #888da8;
}

.eureka-dialog-button span {
  height: 24px;
  font-size: 18px;
  font-family: "Inter";
}

.eureka-dialog-button.control {
  width: 131px;
}

.eureka-dialog-button.outlined {
  border: 2px solid #3e6fd9;
  background-color: white;
}

.eureka-dialog-button.outlined span {
  color: #3e6fd9;
}

.subtraction-text {
  align-self: center;
  margin-top: 20px;
  margin-bottom: auto;
  width: 100%;
  max-width: 900px;
  height: 24px;
  color: #242c5c;
  font-size: 28px;
  line-height: 22px;
  font-family: "Inter";
}

.subtraction-container {
  z-index: 100;
  border: 2px solid #c0c4de;
  border-radius: 4px;
  background-color: white;
  width: 100%;
  min-width: 320px;
  max-width: 485px;
  height: 52px;
}

.subtraction-dialog-controls img {
  margin-left: 30px;
}

.feasible-idea {
  border-radius: 4px;
  background-color: #eceef5;
  height: 110px;
}

.feasible-idea.negative {
  background-color: rgba(241, 102, 106, 0.29);
}

.feasible-idea.positive {
  background-color: rgba(90, 197, 122, 0.2);
}

.feasible-idea-text {
  margin-bottom: 10px;
  height: 25px;
  color: #242c5c;
  font-size: 18px;
  font-family: "Inter";
}

.flex-1 {
  flex: 1 1;
}

.idea-rating-label {
  height: 42px;
  color: #242c5c;
  font-size: 20px;
  font-family: "Inter";
}

.idea-rating-container {
  align-self: center;
  margin-bottom: 40px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.bgc-white {
  background-color: white !important;
}

.subtraction-tabs-circle {
  position: relative;
  transition: border-color 0.5s;
  border: 3px solid transparent;
  border-radius: 20px;
  background-color: #eceef5;
  width: 25px;
  min-width: 25px;
  height: 25px;
}

.subtraction-tabs-circle.active {
  transition: border-color 0.5s;
  border-color: #3e6fd9;
}

.eureka-tab-button span {
  transition: color 0.5s;
  height: 22px;
  color: #888da8;
  font-size: 16px;
  font-family: "Inter";
  white-space: nowrap;
}

.eureka-tab-button div {
  height: 100%;
}

.subtraction-tab-label.active {
  transition: color 0.5s;
  color: #3e6fd9;
}

.eureka-tab-button {
  position: absolute;
  bottom: 20px;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  cursor: pointer;
  outline: none !important;
  border: none;
  background-color: transparent;
  padding: 0 10px;
  height: 36px;
  line-height: 1.1;
}

.completed-idea-button div {
  height: 25%;
}

.eureka-tabs-line-container {
  width: 210px;
  min-width: 300px;
  height: 3px;
  min-height: 3px;
}

.eureka-tabs-line {
  background-color: transparent;
  width: 300px;
  height: 3px;
  min-height: 3px;
}

.eureka-tabs-container {
  position: absolute;
  bottom: -12px;
  left: -170px;
  justify-content: space-evenly;
  width: 96%;
}

.eureka-tabs-line {
  transition: background-color 0.5s;
  background-color: transparent;
}

.eureka-tabs-line.active {
  transition: background-color 0.5s;
  background-color: #3e6fd9;
  min-width: 300px;
}

.subtraction-tabs-circle svg {
  position: absolute;
  top: -5px;
  left: -5px;
  opacity: 0;
  transition: opacity 0.5s;
  width: 29px;
  min-width: 29px;
  height: 29px;
  min-height: 29px;
}

.subtraction-tabs-circle svg.completed {
  opacity: 1;
  transition: opacity 0.5s;
}

.eureka-tab {
  max-width: 25%;
}

.tab-label-optional {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 12px;
}

.subtraction-description {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.eureka-modal-title {
  flex-wrap: wrap;
  align-self: center;
  margin-top: 5px;
  margin-bottom: auto;
  width: 100%;
  max-width: 900px;
  height: 40px;
  color: #242c5c;
  font-size: 24px;
  line-height: 40px;
  font-family: "Inter";
}

.eureka-modal-title u {
  margin: 0 5px;
  max-width: 800px;
  height: 40px;
  overflow: hidden;
  line-height: 40px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.eureka-modal-label {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
  color: #242c5c;
  font-size: 26px;
  font-family: "Inter";
  text-align: center;
}

.eureka-modal-label span {
  height: 33px;
}

.eureka-modal-label span u {
  max-width: 300px;
  word-wrap: break-word;
  margin-right: 6px;
  margin-bottom: -2px;
  margin-left: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.carousel-container__image-container {
  margin-right: 10px;
}

.describe-new-product-page-one {
  margin-bottom: 20px;
  width: 99%;
  font-size: 26px;
}

.subtraction-modal-input {
  margin-bottom: 15px;
}

.eureka-modal__close-benefit-icon {
  position: relative;
  bottom: 37px;
  width: 12px;
  height: 12px;
}

.eureka-modal__add-issue-icon,
.eureka-modal__add-benefit-icon {
  position: relative;
  bottom: 12px;
  width: 14px;
  height: 14px;
  color: #888da8;
}

.eureka-modal__benefit-button {
  margin-left: 10px;
  border: none;
  background: transparent;
  width: 27px;
  height: 26px;
}

.benefit-input {
  margin-bottom: 3px;
}

.benefits-label {
  align-self: flex-start;
  height: 23px;
  color: #242c5c;
  font-size: 16px;
  font-family: "Inter";
}

.eureka-modal__benefit-input-container {
  max-height: 380px;
  overflow: hidden;
  overflow-y: auto;
}

.eureka-modal__benefit-input-container button {
  cursor: pointer;
}

.mt--8 {
  margin-top: -8px;
}

.not-feasible {
  position: absolute;
  bottom: 110px;
  left: 30px;
  border-radius: 4px;
  background-color: rgba(241, 102, 106, 0.15);
  width: 1000px;
  height: 53px;
}

.not-feasible span {
  height: 25px;
  color: #242c5c;
  font-size: 18px;
  font-family: "Inter";
}

.issues-input-container {
  width: 100%;
}

.issues-input-container textarea {
  border: 2px solid #eceef5;
  padding: 14px 16px 14px 16px;
  width: 100%;
  max-height: 95px;
  color: #3e6fd9;
  font-size: 15px;
  line-height: 15px;
}

.issues-input-container textarea::-webkit-input-placeholder {
  color: rgba(36, 44, 92, 0.5);
}

.issues-input-container textarea::placeholder {
  color: rgba(36, 44, 92, 0.5);
}

.dependency-modal-input {
  margin-bottom: 8px;
}

.eureka-modal-benefit {
  margin-bottom: 3px;
}

.initials-container {
  display: flex;
  position: relative;
  top: -4.5px;
  align-items: flex-start;
  cursor: pointer;
  margin-right: 10px;
}

.eureka-modal__issue-input-container {
  max-height: 380px;
  overflow: hidden;
  overflow-y: auto;
}

.eureka-modal__issue-button {
  margin-left: 10px;
  border: none;
  background: transparent;
  width: 27px;
  height: 26px;
}

.eureka-modal__comment-solve-container {
  display: inline;
  position: relative;
  top: -25px;
  margin-left: 58px;
}

.eureka-modal__comment-solve-container span {
  cursor: pointer;
  color: #75798d;
  font-size: 14px;
}

.thread-container {
  margin-bottom: 7px;
  margin-left: 42px;
  width: calc(100% - 42px);
}

.width-90 {
  width: 90%;
}

.semitransparent {
  opacity: 0.5;
}

.eureka-modal__button-ai {
  border: none;
  border-radius: 4px;
  background-color: #f9e9ec;
  padding: 5px 5px 5px 5px;
  height: 10%;
  color: #cc4b4f;
  font-weight: 500;
}

.eureka-modal__button-ai:hover {
  background-color: #cc4b4f;
  color: #fff;
}

.eureka-modal__button-ai_challenges {
  margin: 0 0 15px 25px;
  width: 230px;
  font-size: 14px;
}

.eureka-modal__button-ai_name-idea,
.eureka-modal__button-ai_image {
  position: relative;
  width: 50%;
  font-size: 14px;
}

.eureka-modal__button-ai_name-idea {
  top: 31px;
  left: 190px;
}

.eureka-modal__button-ai_image {
  top: 28px;
  left: 94px;
}

.eureka-modal__button-ai_image p {
  margin: 0;
}

.eureka-modal__button-ai_disabled,
.eureka-modal__button-ai_disabled:hover {
  background-color: #888da8;
  color: #fff;
}

.eureka-modal-container__label {
  margin-bottom: 15px;
  width: 50%;
  text-align: left;
}

.eureka-modal-container {
  justify-content: space-around;
  align-items: flex-start;
  margin-bottom: 5px;
}

.eureka-modal-container__name,
.eureka-modal-container__describe {
  width: 380px;
}

.eureka-modal-container__describe {
  margin-top: 15px;
  margin-right: 5px;
}

.eureka-modal-benefit__initials {
  position: relative;
  right: 5px;
  bottom: 53px;
  margin-left: 5px;
}

.eureka-modal-challenges__title.textarea,
.eureka-modal-benefit__title.textarea,
.eureka-modal-challenges-input__empty_title {
  font-size: 18px;
}

.eureka-modal-challenges-input__empty_title {
  padding: 7px 16px;
}

.eureka-modal-challenges-input,
.eureka-modal-benefit-input {
  margin-top: -8px;
}

.eureka-modal-challenges-input__empty,
.eureka-modal-benefit-input__empty {
  background-color: white;
}

.eureka-modal-benefit-line {
  margin-top: 20px;
  border-top: 2px solid #c3cde2;
}

.eureka-modal__button-ai_benefits {
  margin: 0 0 15px 25px;
  width: 208px;
  font-size: 14px;
}

.eureka-modal-challenges__initials {
  position: relative;
  right: 5px;
  bottom: 42px;
  cursor: pointer;
  margin-left: 5px;
}

.eureka-modal__close-issue-icon {
  position: relative;
  bottom: 32px;
  width: 12px;
  height: 12px;
}

.eureka-modal__close-solve-icon,
.eureka-modal__add-solve-icon {
  position: relative;
  bottom: 5px;
}

.eureka-modal__close-solve-icon {
  width: 12px;
  height: 12px;
}

.eureka-modal__add-solve-icon {
  width: 14px;
  height: 14px;
  color: #888da8;
}

.eureka-modal__upload-image-button {
  position: relative;
  top: 17px;
  right: 8px;
  border: 0px;
  border-radius: 4px;
  width: 45%;
  height: 55px;
  font-size: 14px;
}

.eureka-modal__upload-image-button:hover {
  background-color: #cc4b4f;
}

.eureka-modal__upload-image-button_disabled,
.eureka-modal__upload-image-button_disabled:hover,
.eureka-modal__button-ai_image-disabled,
.eureka-modal__button-ai_image-disabled:hover {
  background-color: #888da8;
  color: white;
}

.components-dashboard-wrapper {
  margin: 20px 40px 0;
  font-family: "Inter";
}

.search-input-container-subtraction-components {
  max-width: 326px;
}

.components-dashboard-count {
  margin-left: 10px;
  height: 38px;
  color: #838fdb;
  font-size: 30px;
}

.add-component-button-round {
  margin: 0 20px;
  color: #3e6fd9;
}

.add-component-button-round:hover {
  color: #3061ae;
}

.components-counter-container {
  padding-bottom: 10px;
  height: 45px;
}

.components-tooltip {
  margin-right: auto;
}

.components-cards-wrapper {
  max-height: 100%;
}

.add-component-button {
  margin-bottom: 10px;
  outline: none !important;
  border: none;
  border-radius: 4px;
  background-color: #d4def2;
  padding: 0 17px 0 20px;
  width: 346px;
  height: 49px;
  color: #3e6fd9;
}

.add-component-button:hover {
  background-color: #3e6fd9;
  color: white;
}

.add-component-button svg {
  width: 13px;
  height: 13px;
}

.add-component-button span {
  height: 24px;
  font-size: 16px;
  font-family: "Inter";
  text-transform: uppercase;
}

.eureka-component-card {
  position: relative;
  margin: 0 10px 10px 0;
  box-shadow: 0 1px 1px #c6cbed;
  border: 2px solid transparent;
  border-radius: 4px;
  background-color: #eceef5;
  width: 600px;
  min-width: 264px;
  height: 754px;
  -webkit-user-select: none;
          user-select: none;
}

.eureka-component-card:hover {
  border: 2px solid #3e6fd9;
}

.eureka-component-card-header {
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 4px;
  background-color: #eceef5;
  padding: 0px;
  width: 100%;
  height: 30px;
}

.eureka-component-card-header .link-icon {
  margin-right: 8px;
}

.subtract-component-card-rating-menu-wrapper .link-icon {
  margin-right: 8px;
}

.cant-create-idea {
  margin-top: 5px;
  margin-bottom: 5px;
  color: #8a93c3;
  font-size: 15px;
  font-family: "Inter";
  text-align: center;
}

.eureka-component-card-title {
  margin-right: 3px;
  max-height: 23px;
  overflow: hidden;
  color: #242c5c;
  font-size: 22px;
  line-height: 23px;
  font-family: "Inter";
  letter-spacing: -0.36px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.subtract-component-card-menu-button {
  outline: none !important;
  border: none;
  background-color: transparent;
  width: 20px;
  height: 30px;
}

.component-type {
  border-radius: 4px;
  background-color: #f7f8fa;
  width: 59px;
  height: 22px;
  font-family: "Inter";
}

.component-type span {
  height: 18px;
  color: #3e6fd9;
  font-weight: 500;
  font-size: 14px;
}

.component-type.external {
  background-color: #fdeeee;
}

.component-type.external span {
  color: #f1666a;
}

.component-card-body {
  padding: 0 15px;
}

.subtract-component-card {
  margin-bottom: 10px;
  box-shadow: 0 1px 1px #c6cbed;
  border: 1px solid #c6cbed;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 1px;
  width: 346px;
  height: 114px;
  min-height: 114px;
  max-height: 114px;
}

.subtract-component-card.disabled {
  background-color: #d6dbed;
}

.subtract-component-card.disabled {
  background-color: rgba(241, 102, 106, 0.11);
}

.component-card.disabled {
  background-color: #d6dbed;
}

.eureka-component-card-header.disabled {
  background-color: #d6dbed;
}

.eureka-component-card.disabled {
  background-color: #d6dbed;
}

.dropdown-last-idea {
  margin-bottom: 5px;
}

.subtract-component-card:hover {
  border: 2px solid #3e6fd9;
  padding: 0;
}

.component-card-container {
  margin-bottom: 10px;
  width: 100%;
  height: 600px;
}

.subtract-component-card-container {
  margin-bottom: 10px;
  width: 100%;
  height: 570px;
}

.subtract-component-card-container.compact {
  height: 196px;
}

.subtract-component-card-title {
  margin-right: 5px;
  width: 100%;
  height: 25px;
  color: #242c5c;
  font-size: 16px;
  font-family: "Inter";
}

.eureka-header-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.eureka-header-container-tutorial,
.eureka-header-container-settings {
  margin: 10px 0 10px 0;
}

.subtract-component-card.compact {
  position: relative;
  margin-bottom: 7px;
  height: 49px;
  min-height: 49px;
}

.subtract-component-card-header-compact {
  padding: 0 10px;
}

.subtract-component-card-rating-menu-wrapper {
  display: flex;
  flex-direction: row;
}

.subtract-component-card-menu-button.compact {
  width: 22px;
}

.component-card.compact {
  height: 370px;
}

.subtraction-component-type-container {
  margin-top: 16px;
}

.eureka-component-card-header.compact {
  margin: 0;
}

.eureka-idea-card-title-container {
  display: flex;
  align-items: center;
  margin: 0 20px;
  max-width: 100%;
}

.eureka-idea-card-title-container span {
  padding-top: 6px;
  max-width: 269px;
  max-height: 40px;
  overflow: hidden;
  line-height: 18px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-word;
}

.eureka-idea-card-title-container.compact span {
  padding-top: 2px;
  max-height: 36px;
  line-height: 20px;
}

.component-card img {
  pointer-events: none;
}

.eureka-component-card-header-text {
  width: 100%;
  height: 30px;
}

.mt-10 {
  margin-top: 10px;
}

.eureka-ideas-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  min-height: calc(100% - 20px);
}

.component-placeholder {
  padding-top: 30px;
}

.component-placeholder span {
  height: 38px;
  color: #242c5c;
  font-size: 22px;
  font-family: "Inter";
}

.eureka-component-card-header-top {
  justify-content: space-between;
  width: 363px;
  min-width: 363px;
  height: 32px;
}

.row-dropdown-container {
  position: absolute;
  right: 0;
  opacity: 0;
  height: 51px;
}

.cell-dropdown-row:hover .row-dropdown-container {
  opacity: 1;
}

.open-details-button-subtraction {
  justify-content: space-between;
  align-items: center;
  outline: none;
  border: none;
  border-radius: 4px;
  background-color: #dfe7fa;
  padding: 0 10px 0 20px;
  width: 400px;
  height: 40px;
}

.open-details-button-subtraction span {
  color: #3e6fd9;
  font-size: 16px;
  font-family: "Inter";
}

.open-details-button-subtraction svg {
  color: #3e6fd9;
}

.open-details-button-subtraction:hover {
  background-color: #3e6fd9;
}

.open-details-button-subtraction:hover span {
  color: white;
}

.open-details-button-subtraction:hover svg {
  color: white;
}
.open-details-button-subtraction .button-triangle-icon {
  margin-left: 5px;
}

.open-details-button-subtraction:hover .button-triangle-icon {
  border-color: transparent transparent transparent white;
}

.open-details-button-subtraction.edit {
  margin-right: 10px;
  outline: none;
  padding: 0 10px 0 10px;
  width: 77px;
  height: 30px;
}

.open-details-button-subtraction.medium {
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
  outline: none;
  border: 1px solid #3e6fd9;
}

.active-eureka-idea {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  box-shadow: 0 3px 6px rgba(36, 44, 92, 0.4);
  border: 1px solid #3e6fd9;
}

.top-2 {
  position: relative !important;
  top: 2px;
}

.eureka-idea-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 6px;
  border-radius: 4px;
  background-color: rgba(90, 197, 122, 0.17);
  width: 550px;
  height: 51px;
  min-height: 51px;
}

.eureka-idea-name {
  margin-right: 10px;
  width: 400px;
  height: 50px;
  overflow: hidden;
  color: #242c5c;
  font-size: 16px;
  line-height: 48px;
  font-family: "Inter";
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.eureka-idea-score-container {
  display: flex;
  align-items: center;
  margin-right: 10px;
  width: 35px;
}

.eureka-idea-score {
  border-radius: 50%;
  background-color: #5ac57a;
  width: 35px;
  height: 35px;
}

.eureka-idea-score span {
  color: #ffffff;
  font-size: 21px;
}

.custom-login-input {
  outline: none;
  border: 2px solid #d9dcf5;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 4px 17px 0 17px;
  width: 100%;
  height: 41px;
  color: #242c5c;
  font-size: 17px;
}

.custom-login-input.has-errors {
  border: 2px solid #f1666a;
}

.custom-login-input::-webkit-input-placeholder {
  color: #8a93c3;
}

.custom-login-input::placeholder {
  color: #8a93c3;
}

.custom-login-input-errors {
  border-radius: 0 0 4px 4px;
  background-color: #f6e2e5;
  width: 100%;
  height: 41px;
  min-height: 41px;
}

.custom-login-input-errors span {
  height: 23px;
  color: #f1666a;
  font-size: 17px;
}

.custom-password-input {
  outline: none;
  border: 2px solid #d9dcf5;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 4px 17px 0 17px;
  width: 100%;
  height: 41px;
  color: #242c5c;
  font-size: 17px;
}

.custom-password-input.has-errors {
  border: 2px solid #f1666a;
}

.custom-password-input::-webkit-input-placeholder {
  color: #8a93c3;
}

.custom-password-input::placeholder {
  color: #8a93c3;
}

.custom-password-input-errors {
  border-radius: 0 0 4px 4px;
  background-color: #f6e2e5;
  width: 100%;
  height: 41px;
  min-height: 41px;
}

.custom-password-input-errors span {
  height: 23px;
  color: #f1666a;
  font-size: 17px;
}

.login-form {
  margin-top: 80px;
  padding-bottom: 90px;
  width: 357px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.login-title {
  height: 38px;
  color: #242c5c;
  font-size: 30px;
  font-family: "Inter";
}

.login-text span,
.login-text a {
  height: 23px;
  color: #242c5c;
  font-size: 17px;
}

.login-text .custom-checkbox {
  margin-right: 20px;
}

.login-links {
  padding: 0 5px;
}

.login-links .login-link {
  height: 23px;
  color: #888da8;
  font-size: 17px;
  white-space: nowrap;
}

.login-button {
  outline: none !important;
  border: none;
  border-radius: 4px;
  background-color: #3e6fd9;
  width: 100%;
  height: 48px;
}

.login-button span {
  height: 24px;
  color: #ffffff;
  font-size: 18px;
  font-family: "Inter";
}

.login-form-container {
  width: 100%;
}

.div-notification {
  position: fixed;
  top: 20px;
  right: 20px;
}

.error-message {
  text-align: left;
}

.visibility-button {
  position: absolute;
  top: 0;
  right: 0;
  outline: none !important;
  border: none;
  background-color: transparent;
  padding-right: 12px;
  width: 41px;
  height: 41px;
}

.visibility-button img {
  width: 23px;
  height: 19px;
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
}

.language-login {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 17px;
}

.flag-icon-login {
  cursor: pointer;
  margin-left: 5px;
  width: 30px;
  height: 20px;
}

.language-title {
  cursor: pointer;
  margin-right: 5px;
  margin-left: 7px;
  text-decoration: underline;
}

.right-flag {
  margin-left: 5px;
}

.other-application-login {
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  padding-bottom: 20px;
  width: 100%;
}

.other-application-login__title-text {
  position: relative;
  bottom: 14px;
  background-color: white;
  height: 0;
}

.component-multiplication-dialog-wrapper {
  position: relative;
  border-radius: 4px;
  width: 600px;
  height: 540px;
  font-family: "Inter";
}

.component-multiplication-dialog-header {
  justify-content: space-between;
  align-items: center;
  border-radius: 4px 4px 0 0;
  background-color: #eceef5;
  padding: 0 28px;
  width: 100%;
  height: 130px;
}

.component-dialog-title {
  margin-left: 10px;
  height: 38px;
  color: #242c5c;
  font-size: 30px;
  font-family: "Inter";
}

.component-multiply-dialog-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 26px 32px;
  width: 100%;
  height: calc(100% - 165px);
}

.component-multiplication-dialog-scrollbar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 190px;
}

.component-dialog-label {
  align-self: flex-start;
  color: #465664;
  font-size: 16px;
  line-height: 24px;
}

.component-dialog-importance-label {
  color: #465664;
  font-size: 16px;
  line-height: 24px;
}

.component-dialog-label.active {
  color: #3e6fd9;
}

.component-replace-dialog-buttons {
  display: flex;
  position: absolute;
  right: 32px;
  bottom: 32px;
}

.component-dialog-button {
  outline: none !important;
  border: none;
  border-radius: 4px;
  width: 105px;
  height: 48px;
  font-size: 14px;
}

.component-dialog-button.secondary {
  margin-right: 10px;
  background-color: transparent;
  color: #888da8;
}

.component-dialog-input-container {
  justify-content: space-between;
}

.location-toggle {
  margin: 0 20px 0 10px;
}

.component-importance {
  height: 36px;
}

.component-to-multiply-in-title {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  cursor: default;
  margin-left: 15px;
  width: 475px;
  font-size: 18px;
}

.component-to-multiply-in-title u {
  margin: 0 5px;
  margin-top: 4px;
  max-width: 216px;
  height: 20px;
  overflow: hidden;
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.button-disabled {
  background-color: rgb(141, 141, 141);
  pointer-events: none;
}

.button-not-allowed {
  cursor: not-allowed;
}

.component-to-replace-in-button {
  max-width: 420px;
  overflow: hidden;
  font-size: 15.5px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.attributes-multiply-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 1px 1px #c6cbed;
  border-radius: 5px;
  background-color: rgb(243, 243, 243);
  background-color: #eceef5;
  padding-top: 4px;
  width: 90%;
  height: 280px;
}

.attributes-list-element {
  cursor: pointer;
  border-radius: 5px;
  background-color: #fff;
  padding: 5px 15px;
  width: 90%;
  min-height: 30px;
  overflow: hidden;
  line-height: 20px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.attributes-list-element.active-attribute {
  -webkit-transform: scale(1.04);
          transform: scale(1.04);
  box-shadow: 0 3px 6px rgb(36 44 92 / 40%);
  border: 1px solid #3e6fd9;
  padding: 4px 14px;
}

.attributes-list-element:hover {
  border: 2px solid #3e6fd9;
  padding: 3px 13px;
}

.create-new-multiplication-attribute {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90% !important;
  height: 80px !important;
  font-size: 17px;
  text-align: center;
}

.keepAttributes {
  background-color: rgb(209, 206, 206);
}

.component-to-multiply-in-title_tooltip {
  margin-bottom: 0;
  color: #212529;
  font-size: 18px;
  white-space: nowrap;
}

.subtraction-dialog-wrapper {
  position: relative;
  border-radius: 4px;
  background: white;
  width: 1061px;
  min-width: 768px;
  max-width: 100vw;
  height: 792px;
  min-height: 768px;
  max-height: 100vh;
  overflow: hidden;
  font-family: "Inter";
}

.multiplication-dialog-header {
  display: flex;
  position: relative;
  background-color: #eceef5;
  width: 100%;
  height: 257px;
}

.subtraction-dialog-header-icon {
  margin-right: 10px;
  height: 26px;
}

.multiplication-dialog-title-container {
  justify-content: space-between;
  align-items: center;
  align-self: flex-start;
  margin-top: 20px;
  margin-top: 20px;
  padding: 0 28px;
  width: 100%;
}

.subtraction-dialog-title {
  height: 38px;
  color: #242c5c;
  font-size: 30px;
  font-family: "Inter";
}

.subtraction-dialog-body {
  padding: 40px 140px 0;
  width: 100%;
}

.subtraction-dialog-body-eureka {
  padding: 30px 140px 0;
  width: 100%;
}

.subtraction-dialog-body.benefits-container {
  padding: 40px 0 0;
}

.multiplication-dialog-footer {
  position: absolute;
  bottom: 0;
  border-top: 2px solid #eceef5;
  padding: 0 25px;
  width: 100%;
  height: 60px;
}

.multiplication-dialog-button {
  cursor: pointer;
  outline: none !important;
  border: none;
  border-radius: 4px;
  width: 105px;
  height: 48px;
  font-size: 16px;
}

.multiplication-dialog-button.disabled {
  background-color: #888da8;
  color: white;
}

.multiplication-dialog-button.secondary {
  margin-right: 10px;
  background-color: transparent;
  color: #888da8;
}

.multiplication-dialog-button span {
  height: 24px;
  font-size: 18px;
  font-family: "Inter";
}

.multiplication-dialog-button.control {
  width: 131px;
}

.multiplication-dialog-button.outlined {
  border: 2px solid #3e6fd9;
  background-color: white;
}

.multiplication-dialog-button.outlined span {
  color: #3e6fd9;
}

.subtraction-container {
  z-index: 100;
  border: 2px solid #c0c4de;
  border-radius: 4px;
  background-color: white;
  width: 100%;
  min-width: 320px;
  max-width: 485px;
  height: 52px;
}

.subtraction-dialog-controls img {
  margin-left: 30px;
}

.feasible-idea {
  border-radius: 4px;
  background-color: #eceef5;
  height: 110px;
}

.feasible-idea.negative {
  background-color: rgba(241, 102, 106, 0.29);
}

.feasible-idea.positive {
  background-color: rgba(90, 197, 122, 0.2);
}

.feasible-idea-text {
  margin-bottom: 10px;
  height: 25px;
  color: #242c5c;
  font-size: 18px;
  font-family: "Inter";
}

.flex-1 {
  flex: 1 1;
}

.idea-rating-label {
  height: 42px;
  color: #242c5c;
  font-size: 20px;
  font-family: "Inter";
}

.idea-rating-container {
  align-self: center;
  margin-bottom: 40px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.bgc-white {
  background-color: white !important;
}

.subtraction-tabs-circle {
  position: relative;
  transition: border-color 0.5s;
  border: 3px solid transparent;
  border-radius: 20px;
  background-color: #eceef5;
  width: 25px;
  min-width: 25px;
  height: 25px;
}

.subtraction-tabs-circle.active {
  transition: border-color 0.5s;
  border-color: #3e6fd9;
}

.multiplication-tab-button span {
  transition: color 0.5s;
  height: 22px;
  color: #888da8;
  font-size: 16px;
  font-family: "Inter";
  white-space: nowrap;
}

.multiplication-tab-button div {
  height: 100%;
}

.subtraction-tab-label.active {
  transition: color 0.5s;
  color: #3e6fd9;
}

.multiplication-tab-button {
  position: absolute;
  bottom: 20px;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  cursor: pointer;
  outline: none !important;
  border: none;
  background-color: transparent;
  padding: 0 10px;
  height: 36px;
  line-height: 1.1;
}

.completed-idea-button div {
  height: 25%;
}

.multiplication-tabs-line-container {
  width: 178px;
  min-width: 300px;
  height: 3px;
  min-height: 3px;
}

.multiplication-tabs-line {
  background-color: transparent;
  width: 178px;
  min-width: 300px;
  height: 3px;
  min-height: 3px;
}

.multiplication-tabs-container {
  position: absolute;
  bottom: -12px;
  left: -170px;
  justify-content: space-evenly;
  width: 96%;
}

.multiplication-tabs-line {
  transition: background-color 0.5s;
  background-color: transparent;
}

.multiplication-tabs-line.active {
  transition: background-color 0.5s;
  background-color: #3e6fd9;
  min-width: 300px;
}

.subtraction-tabs-circle svg {
  position: absolute;
  top: -5px;
  left: -5px;
  opacity: 0;
  transition: opacity 0.5s;
  width: 29px;
  min-width: 29px;
  height: 29px;
  min-height: 29px;
}

.subtraction-tabs-circle svg.completed {
  opacity: 1;
  transition: opacity 0.5s;
}

.subtraction-tab {
  min-width: 235px;
  max-width: 20%;
}

.tab-label-optional {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 12px;
}

.multiplication-description {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: #242c5c;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.multiplication-description u {
  margin: 0 10px 0 10px;
}

.multiplication-dialog-header-image {
  margin: 0 10px 0 10px;
  width: 98%;
  color: #242c5c;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
}

.multiplication-dialog-header-image u {
  margin: 0 10px 0 10px;
}

.multiplication-modal-label {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
  color: #242c5c;
  font-size: 26px;
  font-family: "Inter";
  text-align: center;
}

.multiplication-modal-label span {
  height: 33px;
}

.multiplication-modal__button-ai {
  border: none;
  border-radius: 4px;
  background-color: #f9e9ec;
  padding: 5px 5px 5px 5px;
  color: #cc4b4f;
  font-weight: 500;
}

.multiplication-modal-label span u {
  max-width: 300px;
  word-wrap: break-word;
  margin-right: 6px;
  margin-bottom: -2px;
  margin-left: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.multiplication-modal__button-ai:hover {
  background-color: #cc4b4f;
  color: #fff;
}

.multiplication-modal__upload-image-button:hover {
  background-color: #cc4b4f;
}

.multiplication-modal__upload-image-button_disabled,
.multiplication-modal__upload-image-button_disabled:hover,
.multiplication-modal__button-ai_image-disabled,
.multiplication-modal__button-ai_image-disabled:hover {
  background-color: #888da8;
  color: white;
}

.multiplication-modal__upload-image-button {
  position: relative;
  top: 17px;
  right: 8px;
  border: 0px;
  border-radius: 4px;
  width: 45%;
  height: 55px;
  font-size: 14px;
}

.carousel-container__image-container {
  margin-right: 10px;
}

.describe-new-product-page-one {
  margin-bottom: 20px;
  width: 99%;
  font-size: 26px;
}

.subtraction-modal-input {
  margin-bottom: 15px;
}

.multiplication-modal__close-benefit-icon {
  position: relative;
  bottom: 37px;
  width: 12px;
  height: 12px;
}

.multiplication-modal__add-issue-icon,
.multiplication-modal__add-benefit-icon {
  position: relative;
  bottom: 12px;
  width: 14px;
  height: 14px;
  color: #888da8;
}

.multiplication-modal__benefit-button {
  margin-left: 10px;
  border: none;
  background: transparent;
  width: 27px;
  height: 26px;
}

.benefit-input {
  margin-bottom: 3px;
}

.benefits-label {
  align-self: flex-start;
  height: 23px;
  color: #242c5c;
  font-size: 16px;
  font-family: "Inter";
}

.benefit-input-container {
  padding: 0 140px;
  max-height: 380px;
  overflow: hidden;
  overflow-y: auto;
}

.benefit-input-container button {
  cursor: pointer;
}

.mt--8 {
  margin-top: -8px;
}

.not-feasible {
  position: absolute;
  bottom: 110px;
  left: 30px;
  border-radius: 4px;
  background-color: rgba(241, 102, 106, 0.15);
  width: 1000px;
  height: 53px;
}

.not-feasible span {
  height: 25px;
  color: #242c5c;
  font-size: 18px;
  font-family: "Inter";
}

.issues-input-container {
  width: 100%;
}

.issues-input-container textarea {
  border: 2px solid #eceef5;
  padding: 14px 16px 14px 16px;
  width: 100%;
  max-height: 95px;
  color: #3e6fd9;
  font-size: 15px;
  line-height: 15px;
}

.issues-input-container textarea::-webkit-input-placeholder {
  color: rgba(36, 44, 92, 0.5);
}

.issues-input-container textarea::placeholder {
  color: rgba(36, 44, 92, 0.5);
}

.dependency-modal-input {
  margin-bottom: 8px;
}

.multiplication-modal-benefit {
  margin-bottom: 3px;
}

.initials-container {
  display: flex;
  position: relative;
  top: -4.5px;
  align-items: flex-start;
  cursor: pointer;
  margin-right: 10px;
}

.issue-input-container {
  padding: 0 140px;
  max-height: 380px;
  overflow: hidden;
  overflow-y: auto;
}

.multiplication-modal__issue-button {
  margin-left: 10px;
  border: none;
  background: transparent;
  width: 27px;
  height: 26px;
}

.multiplication-modal__comment-solve-container {
  display: inline;
  position: relative;
  top: -25px;
  margin-left: 58px;
}

.multiplication-modal__comment-solve-container span {
  cursor: pointer;
  color: #75798d;
  font-size: 14px;
}

.thread-container {
  margin-bottom: 7px;
  margin-left: 42px;
  width: calc(100% - 42px);
}

.width-90 {
  width: 90%;
}

.semitransparent {
  opacity: 0.5;
}

.multiplication-modal__button-ai_challenges {
  margin: 0 0 15px 25px;
  width: 230px;
  font-size: 14px;
}

.multiplication-modal__button-ai_name-idea,
.multiplication-modal__button-ai_image {
  position: relative;
  width: 50%;
  font-size: 14px;
}

.multiplication-modal__button-ai_name-idea {
  top: 31px;
  left: 190px;
}

.multiplication-modal__button-ai_image {
  top: 28px;
  left: 94px;
}

.multiplication-modal__button-ai_image p {
  margin: 0;
}

.multiplication-modal-container__label {
  margin-bottom: 15px;
  width: 50%;
  text-align: left;
}

.multiplication-modal-container {
  justify-content: space-around;
  align-items: flex-start;
  margin-bottom: 5px;
}

.multiplication-modal-container__name,
.multiplication-modal-container__describe {
  width: 380px;
}

.multiplication-modal-container__describe {
  margin-top: 15px;
  margin-right: 5px;
}

.multiplication-modal-benefit__initials {
  position: relative;
  right: 5px;
  bottom: 53px;
  margin-left: 5px;
}

.multiplication-modal-challenges__title.textarea,
.multiplication-modal-benefit__title.textarea,
.multiplication-modal-challenges-input__empty_title {
  font-size: 18px;
}

.multiplication-modal-challenges-input__empty_title {
  padding: 7px 16px;
}

.multiplication-modal-challenges-input,
.multiplication-modal-benefit-input {
  margin-top: -8px;
}

.multiplication-modal-challenges-input__empty,
.multiplication-modal-benefit-input__empty {
  background-color: white;
}

.multiplication-modal-benefit-line {
  margin-top: 20px;
  border-top: 2px solid #c3cde2;
}

.multiplication-modal__button-ai_benefits {
  margin: 0 0 15px 25px;
  width: 208px;
  font-size: 14px;
}

.multiplication-modal-challenges__initials {
  position: relative;
  right: 5px;
  bottom: 42px;
  cursor: pointer;
  margin-left: 5px;
}

.multiplication-modal__close-issue-icon {
  position: relative;
  bottom: 32px;
  width: 12px;
  height: 12px;
}

.multiplication-modal__close-solve-icon,
.multiplication-modal__add-solve-icon {
  position: relative;
  bottom: 5px;
}

.multiplication-modal__close-solve-icon {
  width: 12px;
  height: 12px;
}

.multiplication-modal__add-solve-icon {
  width: 14px;
  height: 14px;
  color: #888da8;
}

.components-dashboard-wrapper {
  margin: 20px 40px 0;
  font-family: "Inter";
}

.search-input-container-subtraction-components {
  max-width: 326px;
}

.components-dashboard-count {
  margin-left: 10px;
  height: 38px;
  color: #838fdb;
  font-size: 30px;
}

.add-component-button-round {
  margin: 0 20px;
  color: #3e6fd9;
}

.add-component-button-round:hover {
  color: #3061ae;
}

.components-counter-container {
  padding-bottom: 10px;
  height: 45px;
}

.components-tooltip {
  margin-right: auto;
}

.components-cards-wrapper {
  max-height: 100%;
}

.add-component-button {
  margin-bottom: 10px;
  outline: none !important;
  border: none;
  border-radius: 4px;
  background-color: #d4def2;
  padding: 0 17px 0 20px;
  width: 346px;
  height: 49px;
  color: #3e6fd9;
}

.add-component-button:hover {
  background-color: #3e6fd9;
  color: white;
}

.add-component-button svg {
  width: 13px;
  height: 13px;
}

.add-component-button span {
  height: 24px;
  font-size: 16px;
  font-family: "Inter";
  text-transform: uppercase;
}

.subtraction-component-card {
  position: relative;
  margin: 0 10px 10px 0;
  box-shadow: 0 1px 1px #c6cbed;
  border: 2px solid transparent;
  border-radius: 4px;
  background-color: #eceef5;
  width: 398px;
  min-width: 264px;
  height: 754px;
  -webkit-user-select: none;
          user-select: none;
}

.subtraction-component-card:hover {
  border: 2px solid #3e6fd9;
}

.subtraction-component-card-header {
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 4px;
  background-color: #eceef5;
  padding: 0px;
  width: 100%;
  height: 30px;
}

.subtraction-component-card-header .link-icon {
  margin-right: 8px;
}

.subtract-component-card-rating-menu-wrapper .link-icon {
  margin-right: 8px;
}

.cant-create-idea {
  margin-top: 5px;
  margin-bottom: 5px;
  color: #8a93c3;
  font-size: 15px;
  font-family: "Inter";
  text-align: center;
}

.subtraction-component-card-title {
  margin-right: 3px;
  max-height: 23px;
  overflow: hidden;
  color: #242c5c;
  font-size: 22px;
  line-height: 23px;
  font-family: "Inter";
  letter-spacing: -0.36px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.subtract-component-card-menu-button {
  outline: none !important;
  border: none;
  background-color: transparent;
  width: 20px;
  height: 30px;
}

.component-type {
  border-radius: 4px;
  background-color: #f7f8fa;
  width: 59px;
  height: 22px;
  font-family: "Inter";
}

.component-type span {
  height: 18px;
  color: #3e6fd9;
  font-weight: 500;
  font-size: 14px;
}

.component-type.external {
  background-color: #fdeeee;
}

.component-type.external span {
  color: #f1666a;
}

.component-card-body {
  padding: 0 15px;
}

.subtract-component-card {
  margin-bottom: 10px;
  box-shadow: 0 1px 1px #c6cbed;
  border: 1px solid #c6cbed;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 1px;
  width: 346px;
  height: 114px;
  min-height: 114px;
  max-height: 114px;
}

.subtract-component-card.disabled {
  background-color: #d6dbed;
}

.subtract-component-card.disabled {
  background-color: rgba(241, 102, 106, 0.11);
}

.component-card.disabled {
  background-color: #d6dbed;
}

.subtraction-component-card-header.disabled {
  background-color: #d6dbed;
}

.subtraction-component-card.disabled {
  background-color: #d6dbed;
}

.dropdown-last-idea {
  margin-bottom: 5px;
}

.subtract-component-card:hover {
  border: 2px solid #3e6fd9;
  padding: 0;
}

.component-card-container {
  margin-bottom: 10px;
  width: 100%;
  height: 600px;
}

.subtract-component-card-container {
  margin-bottom: 10px;
  width: 100%;
  height: 570px;
}

.subtract-component-card-container.compact {
  height: 196px;
}

.multiplication-component-card-title {
  margin-right: 5px;
  width: 100%;
  height: 25px;
  color: #242c5c;
  font-size: 16px;
  font-family: "Inter";
}

.multiplication-header-container-tutorial,
.multiplication-header-container-settings {
  margin: 10px 0 10px 0;
}

.multiplication-header-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.subtract-component-card.compact {
  position: relative;
  margin-bottom: 7px;
  height: 49px;
  min-height: 49px;
}

.subtract-component-card-header-compact {
  padding: 0 10px;
}

.subtract-component-card-rating-menu-wrapper {
  display: flex;
  flex-direction: row;
}

.subtract-component-card-menu-button.compact {
  width: 22px;
}

.component-card.compact {
  height: 370px;
}

.subtraction-component-type-container {
  margin-top: 16px;
}

.subtraction-component-card-header.compact {
  margin: 0;
}

.subtract-component-card-title-container {
  max-width: 100%;
}

.subtract-component-card-title-container span {
  padding-top: 6px;
  max-width: 269px;
  max-height: 40px;
  overflow: hidden;
  line-height: 18px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-word;
}

.subtract-component-card-title-container.compact span {
  padding-top: 2px;
  max-height: 36px;
  line-height: 20px;
}

.component-card img {
  pointer-events: none;
}

.subtraction-component-card-header-text {
  width: 100%;
  height: 30px;
}

.mt-10 {
  margin-top: 10px;
}

.component-droppable-container {
  min-height: calc(100% - 10px);
}

.component-placeholder {
  padding-top: 30px;
}

.component-placeholder span {
  height: 38px;
  color: #242c5c;
  font-size: 22px;
  font-family: "Inter";
}

.subtraction-component-card-header-top {
  justify-content: space-between;
  width: 363px;
  min-width: 363px;
  height: 32px;
}

.row-dropdown-container {
  position: absolute;
  right: 0;
  opacity: 0;
  height: 51px;
}

.cell-dropdown-row:hover .row-dropdown-container {
  opacity: 1;
}

.open-details-button-subtraction {
  justify-content: space-between;
  align-items: center;
  outline: none;
  border: none;
  border-radius: 4px;
  background-color: #dfe7fa;
  padding: 0 10px 0 20px;
  width: 400px;
  height: 40px;
}

.open-details-button-subtraction span {
  color: #3e6fd9;
  font-size: 16px;
  font-family: "Inter";
}

.open-details-button-subtraction svg {
  color: #3e6fd9;
}

.open-details-button-subtraction:hover {
  background-color: #3e6fd9;
}

.open-details-button-subtraction:hover span {
  color: white;
}

.open-details-button-subtraction:hover svg {
  color: white;
}
.open-details-button-subtraction .button-triangle-icon {
  margin-left: 5px;
}

.open-details-button-subtraction:hover .button-triangle-icon {
  border-color: transparent transparent transparent white;
}

.open-details-button-subtraction.edit {
  margin-right: 10px;
  outline: none;
  padding: 0 10px 0 10px;
  width: 77px;
  height: 30px;
}

.open-details-button-subtraction.medium {
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
  outline: none;
  border: 1px solid #3e6fd9;
}

.active-component {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  box-shadow: 0 3px 6px rgba(36, 44, 92, 0.4);
  border: 1px solid #3e6fd9;
}

.top-2 {
  position: relative !important;
  top: 2px;
}

.username-dialog-wrapper {
  position: relative;
  border-radius: 4px;
  width: 622px;
  height: 600px;
  font-family: "Inter";
}

.username-dialog-header {
  justify-content: space-between;
  align-items: center;
  border-radius: 4px 4px 0 0;
  background-color: #eceef5;
  padding: 0 28px;
  width: 100%;
  height: 85px;
}

.username-dialog-title {
  margin-left: 10px;
  height: 38px;
  color: #242c5c;
  font-size: 30px;
  font-family: "Inter";
}

.username-dialog-body {
  padding: 26px 32px;
  width: 100%;
  height: calc(100% - 165px);
}

.username-dialog-buttons {
  position: absolute;
  right: 32px;
  bottom: 32px;
}

.username-dialog-button {
  outline: none !important;
  border: none;
  border-radius: 4px;
  width: 105px;
  height: 48px;
  font-size: 14px;
}

.ChangeUserPasswordModal_dialog__3oGQs {
  width: 622px;
  height: 600px;
}

.ChangeUserPasswordModal_body__Ug8dG {
  height: 515px !important;
}

.CancelConformationSubscriptionModal_header-body__3o_E3 {
  margin-top: 39px;
  color: #242c5c;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 24px;
  text-align: center;
}

.CancelConformationSubscriptionModal_table__3PNHD {
  margin-top: 23px;
  border-width: 2px;
  border-style: solid;
  border-color: #cdd1e5;
  border-radius: 5px;
  background: #f7f8fa;
  padding-top: 38px;
  width: 539px;
  height: 258px;
}

.CancelConformationSubscriptionModal_table-item__203gC {
  display: flex;
  margin-left: 49px;
  max-width: 420px;
  color: #242c5c;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
}

.CancelConformationSubscriptionModal_circle__HDqio {
  margin-right: 16px;
  border-radius: 8px;
  background: #cdd1e5;
  width: 16px;
  min-width: 16px;
  height: 16px;
}

.CancelConformationSubscriptionModal_cancel-button__1QvT7 {
  margin-top: 40px;
  width: 539px;
  height: 58px;
  font-size: 20px;
}

.CancelConformationSubscriptionModal_dialog__m1L4J {
  width: 662px;
  height: 551px;
}

.MyAccount_line__2MEZG {
  margin-right: 30px;
  margin-left: 30px;
  background: #eceef5;
  min-width: 2px;
  height: 87vh;
}

.Profile_profile__h1ML9 {
  padding-left: 10px;
}

.Profile_text-color__1NE4p {
  color: #242c5c;
}

.Profile_title__VMsNG {
  font-size: 26px;
}

.Profile_name-title__3KE82 {
  margin-top: 20px;
  font-size: 11px;
}

.Profile_email-title__3pBmw {
  margin-top: 12px;
  font-size: 11px;
}

.Profile_email-info__1VjLz {
  margin-top: -6px;
  font-weight: 500;
  font-size: 16px;
}

.Profile_name-info__3_uHW {
  margin-top: -6px;
  font-weight: 500;
  font-size: 20px;
}

.Profile_default-button__3KUPD {
  width: auto;
  min-width: auto;
  color: #3e6fd9;
}

.Profile_default-button__3KUPD:hover {
  color: white;
}

.Profile_reset-password-button__2gHfK {
  margin-top: 15px;
}

.Profile_edit-profile-button__1wZoF {
  margin-top: 20px;
}

.profile-wrapper {
  margin: 0 40px;
  font-family: "Inter";
}

.profile-wrapper .header-image-container {
  margin-top: 10px;
}

.profile-header-top {
  height: 84px;
}

.profile-header-title {
  margin-top: 30px;
  height: 40px;
  color: #242c5c;
  font-size: 30px;
  font-family: "Inter";
}

.profile-wrapper hr {
  margin: 0 0 20px 0;
  width: 100%;
  color: #eceef5;
}

.users-in-project-info {
  margin-bottom: 20px;
  border-radius: 4px;
  padding: 20px;
  width: 520px;
  height: 321px;
  overflow: hidden;
  font-family: "Inter";
  text-align: left;
}

.users-in-project-info-close {
  justify-content: flex-end;
  min-height: 20px;
}

.users-in-project-info-main-content {
  display: flex;
  align-items: baseline;
  padding-bottom: 5px;
  width: 100%;
}

.users-in-project-info-icon {
  margin-right: 5px;
  margin-left: 3px;
}

.users-in-project-info-div-content {
  padding-bottom: 10px;
}

.users-in-project-info-text {
  width: 100%;
  color: #465664;
  font-size: 22px;
  line-height: 26px;
  white-space: wrap;
  word-break: break-word;
}

.users-in-project-info-zero-users {
  margin-top: 100px;
  width: 100%;
  color: #465664;
  font-size: 26px;
  line-height: 26px;
  text-align: center;
}

.users-in-project-info-error {
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
  color: #465664;
  font-size: 26px;
  line-height: 26px;
  text-align: center;
}

.copy-link-button {
  outline: none !important;
  border: 1px solid #3e6fd9;
  border-radius: 4px;
  background-color: #ecf1fc;
  padding: 0 10px 0 15px;
  width: 200px;
  height: 44px;
}

.copy-link-button span {
  height: 22px;
  color: #3e6fd9;
  font-size: 16px;
  font-family: "Inter";
  text-transform: uppercase;
  white-space: nowrap;
}

.copy-link-button:hover {
  background-color: #3e6fd9;
}

.copy-link-button:hover span {
  color: white;
}

.copy-link-button:hover svg {
  fill: white;
}

.copy-link-tooltip {
  margin-left: 5px;
}

.copy-link {
  line-break: anywhere;
}

.copied-link {
  background-color: #498bf7;
  color: white;
}

.projects-dashboard-wrapper {
  margin: 100px 40px 0;
  font-family: "Inter";
}

.project-dashboard-header {
  margin-bottom: 40px;
  min-height: 250px;
}

.idea-container {
  border-radius: 6px;
  width: 415px;
  min-width: 415px;
  height: 270px;
  max-height: 270px;
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
}

.idea-container-with-default-image {
  background-color: #e2eafc;
}

.idea-placeholder-icon {
  margin-left: 30px;
}

.idea-image {
  max-width: 100%;
  max-height: 100%;
}

.project-header-container {
  align-items: flex-start;
  margin-right: 33px;
  min-height: 270px;
}

.project-header-container hr {
  margin: 0 0 20px 0;
  width: 100%;
  color: #eceef5;
}

.projects-type-label {
  height: 14px;
  color: #8a93c3;
  font-size: 15px;
}

.projects-header-title {
  height: 38px;
  color: #242c5c;
  font-size: 30px;
  font-family: "Inter";
}

.project-description {
  align-items: flex-start;
}

.project-description-text {
  min-width: 234px;
  height: 110px;
  overflow-y: auto;
  color: #242c5c;
  font-size: 15px;
  line-height: 22px;
  font-family: "Inter";
  text-align: left;
  word-break: break-word;
}

.projects-dashboard-label {
  margin-bottom: 8px;
  height: 21px;
  color: #8a93c3;
  font-size: 15px;
}

.project-details-info-container {
  align-items: flex-start;
}

.project-details-info-container hr {
  margin: 15px 0 0 -13px;
  width: 147px;
  color: #eceef5;
}

.project-details-info {
  margin-left: 8px;
  height: 21px;
  color: #242c5c;
  font-size: 15px;
}

.no-users {
  margin-left: 0px;
  text-align: left;
}

.project-details-icon {
  margin-left: 3px;
}

.project-details {
  display: grid;
  grid-template-rows: 80px 80px;
  grid-template-columns: 180px 143px;
  margin: 0px 50px 0 5px;
}

.project-dashboard-card {
  justify-content: space-between;
  margin-right: 10px;
  margin-bottom: 10px;
  box-shadow: 0 1px 1px #c6cbed;
  border: 1px solid #c6cbed;
  border-radius: 6px;
  background-color: #ffffff;
  width: 100%;
  min-width: 460px;
  height: 277px;
}

.card-image-container {
  margin-right: 20px;
}

.project-dashboard-card img {
  margin: 0 20px;
  max-width: 100%;
}

.project-dashboard-card-title-container {
  margin: 25px 0 0 25px;
}

.project-dashboard-card-title {
  margin-right: 10px;
  margin-left: 10px;
  height: 36px;
  color: #242c5c;
  font-size: 26px;
  font-family: "Inter";
}

.project-card-content {
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.project-card-content img {
  width: calc(100% - 440px);
}

.dashboard-card-button span {
  border: 1px solid rgba(0, 0, 0, 0);
  height: 30px;
  color: #3e6fd9;
  font-size: 21px;
  font-family: "Inter";
}

.dashboard-card-button {
  justify-content: space-between;
  align-items: center;
  outline: none !important;
  border: none;
  border-radius: 4px;
  background-color: rgba(62, 111, 217, 0.1);
  padding: 0 10px 0 20px;
  width: 363px;
  height: 52px;
  color: #3e6fd9;
}

.dashboard-card-button svg {
  color: #3e6fd9;
}

.dashboard-card-button:hover {
  background-color: #3e6fd9;
}

.dashboard-card-button:hover span {
  color: white;
}

.dashboard-card-button.medium:hover span {
  color: white;
}

.dashboard-card-button.medium:hover svg {
  color: white;
}

.dashboard-card-button:hover svg {
  color: white;
}

.dashboard-card-button:hover .button-triangle-icon {
  border-color: transparent transparent transparent white;
}

.dashboard-card-button-container {
  margin: 0 0 45px 45px;
  max-width: 363px;
}

.dashboard-card-description {
  color: #242c5c;
  font-size: 18px;
  line-height: 22px;
  font-family: "Inter";
}

.mt-20 {
  margin-top: 20px;
}

.project-diagram-block {
  border-radius: 4px;
  background-color: #3e6fd9;
  width: 67px;
  height: 50px;
}

.project-diagram-block span {
  height: 34px;
  color: #ffffff;
  font-weight: 500;
  font-size: 24px;
}

.project-diagram-block-label {
  margin-left: 10px;
  height: 24px;
  color: #242c5c;
  font-size: 18px;
}

.diagram-tooltip {
  margin-left: 10px;
}

.project-diagram-block-big {
  margin-right: 20px;
  border-radius: 4px;
  background-color: #5ac57a;
  width: 139px;
  height: 115px;
}

.project-diagram-block-big span:first-child {
  height: 45px;
  color: #ffffff;
  font-size: 38px;
}

.project-diagram-block-big span:last-child {
  height: 24px;
  color: #ffffff;
  font-size: 18px;
}

.diagram-block-top {
  margin-bottom: 15px;
}

.dashboard-card-button.medium {
  margin: 25px 25px 0 0;
  width: auto;
  width: 244px;
  height: 36px;
  white-space: nowrap;
}

.dashboard-card-button.medium span {
  height: 22px;
  color: #3e6fd9;
  font-size: 14px;
  text-transform: uppercase;
}

.dashboard-card-button.medium svg {
  width: 13px;
  height: 13px;
}

.progress-diagram-container {
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 158px;
  height: 155px;
}

.card-right-diagram-container {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.card-content-container {
  padding-top: 10px;
}

.dependency-card-content-container {
  padding-top: 20px;
}

.progress-diagram-label {
  margin-right: 20px;
  height: 75px;
  color: #242c5c;
  font-size: 55px;
  font-family: "Inter";
}

.progress-diagram-block {
  margin-bottom: 5px;
  border-radius: 4px;
  background-color: #cdd1e5;
  width: 27px;
  height: 27px;
}

.progress-diagram-block.active {
  background-color: #3e6fd9;
}

.donut-diagram-container {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 185px;
  height: 185px;
}

.donut-diagram-container circle {
  fill: transparent;
}

.donut-diagram-container svg {
  position: absolute;
  top: 0;
  left: 0;
}

.donut-svg-chart {
  border-radius: 50%;
  width: 185px;
  height: 185px;
}

.donut-svg-chart-highlighted {
  margin: -5px 0 0 -5px;
  border-radius: 50%;
  width: 195px;
  height: 195px;
}

.donut-diagram-circle {
  border: 15px solid #3e6fd9;
  border-radius: 50%;
  background-color: white;
  width: 185px;
  height: 185px;
}

.donut-diagram-label {
  height: 75px;
  color: #242c5c;
  font-size: 55px;
  font-family: "Inter";
}

.ideas-right-diagram {
  margin-bottom: -60px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.dashboard-card-button.ideas-button {
  width: 130px;
}

.dashboard-card-button.dependency-button {
  width: 180px;
}

.project-dashboard-card.ideas {
  margin-right: 0;
  min-width: 560px;
  height: auto;
}

.ideas-container {
  padding: 0 30px;
}

.ideas-tab-label {
  height: 24px;
  color: #242c5c;
  font-size: 18px;
}

.ideas-tab-header {
  width: 50%;
  height: 36px;
}

.selection-line {
  background-color: #5ac57a;
  width: 100%;
  height: 2px;
}

.selection-line.yellow {
  background-color: #ffcd66;
}

.ideas-content {
  padding: 10px 30px;
  max-height: 500px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.ideas-content::-webkit-scrollbar {
  display: none;
}

.tab-idea-container {
  margin-bottom: 2px;
  border-radius: 4px;
  background-color: rgba(90, 197, 122, 0.17);
  width: 100%;
  height: 51px;
  min-height: 51px;
}

.tab-idea-name {
  margin-left: 10px;
  max-width: 100%;
  height: 22px;
  overflow: hidden;
  color: #242c5c;
  font-size: 16px;
  font-family: "Inter";
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tab-idea-container-row {
  width: 90%;
}

.idea-score {
  margin-right: 15px;
  border-radius: 50%;
  background-color: #5ac57a;
  width: 35px;
  min-width: 35px;
  height: 35px;
}

.idea-score span {
  height: 34px;
  color: #ffffff;
  font-size: 21px;
}

.tab-idea-container.in-progress {
  background-color: rgba(255, 205, 103, 0.35);
}

.button-triangle-icon {
  border: 5px solid;
  border-color: transparent transparent transparent #3e6fd9;
}

.dashboard-project-title-container {
  min-width: 150px;
  height: 38px;
  text-align: left;
}

.dashboard-project-title {
  margin-right: 5px;
  height: 40px;
  color: #242c5c;
  font-size: 30px;
  font-family: "Inter";
}

.dashboard-project-button {
  margin-right: 15px;
  outline: none !important;
  border: 1px solid #3e6fd9;
  border-radius: 4px;
  background-color: #ecf1fc;
  padding: 0 10px 0 15px;
  width: 200px;
  height: 44px;
}

.dashboard-project-button span {
  height: 22px;
  color: #3e6fd9;
  font-size: 16px;
  font-family: "Inter";
  text-transform: uppercase;
}

.dashboard-project-button:not(:last-of-type) {
  margin-right: 15px;
}

.dashboard-project-buttons {
  position: absolute;
  top: 240px;
}

.dashboard-project-button:hover {
  background-color: #3e6fd9;
}

.dashboard-project-button:hover span {
  color: white;
}

.dashboard-project-button:hover .button-triangle-icon {
  border-color: transparent transparent transparent white;
}

.project-description-container {
  margin-top: 10px;
}

.project-dashboard-cards-container {
  margin-right: 10px;
  width: 100%;
}

.project-dashboard-card .attributes-icon {
  margin-right: 49px;
}

.idea-placeholder-icon-scaled.placeholder {
  -webkit-transform: scale(0.88);
          transform: scale(0.88);
}

.idea-container.small {
  width: 370px;
  min-width: 370px;
  height: 240px;
  min-height: 240px;
}

@media only screen and (max-width: 1340px) {
  .project-dashboard-header {
    flex-wrap: wrap;
  }

  .project-description-text {
    width: 100%;
    min-width: 140px;
    height: 370px;
  }

  .project-description-text.no-edit {
    height: 390px;
  }

  .project-dashboard-body {
    flex-wrap: wrap;
  }

  .project-header-container,
  .project-dashboard-cards-container {
    margin-right: 0;
  }

  .project-header-container {
    min-height: 420px;
  }

  .project-header-container.no-edit {
    min-height: 500px;
  }

  .project-dashboard-card {
    margin-right: 0;
  }
}

.project-dashboard-card:last-of-type {
  margin-right: 0;
}

.circulate-button {
  margin-right: 15px;
}

.people-in-project {
  cursor: pointer;
  text-decoration: underline;
  white-space: nowrap;
}

.people-in-project-tooltip {
  white-space: nowrap;
}

.project-edit-buttons {
  margin-top: 10px;
}

.canvas svg {
  width: 185px;
  height: 185px;
}

.ideas-donut-diagram {
  fill: none;
}

.green-sector-shadow-0 {
  -webkit-filter: drop-shadow(-1px 1px 0.7px rgba(0, 0, 0, 0.116));
          filter: drop-shadow(-1px 1px 0.7px rgba(0, 0, 0, 0.116));
}

.green-sector-shadow-50 {
  -webkit-filter: drop-shadow(0px 1px 0.7px rgba(0, 0, 0, 0.116));
          filter: drop-shadow(0px 1px 0.7px rgba(0, 0, 0, 0.116));
}

.green-sector-shadow-100 {
  -webkit-filter: drop-shadow(0px 0px 0.3px rgba(0, 0, 0, 0.274));
          filter: drop-shadow(0px 0px 0.3px rgba(0, 0, 0, 0.274));
}

.dashboard-card-donut-diagram {
  margin-bottom: 55px;
}

.ideas-count {
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
  text-align: left;
}

.ideas-count span {
  max-width: 100px;
  height: 20px;
  overflow: hidden;
  color: #242c5c;
  font-size: 16px;
  font-family: "Inter";
  text-overflow: ellipsis;
  white-space: nowrap;
}

.donut-ideas-diagram-label {
  margin-bottom: 8px;
  height: 75px;
  color: #242c5c;
  font-size: 55px;
  font-family: "Inter";
}

.tab-idea-text-container {
  margin-left: 10px;
}

.tab-idea-text {
  margin-left: 5px;
  max-width: calc(100% - 10px);
  height: 25px;
  overflow: hidden;
  color: #242c5c;
  font-size: 16px;
  line-height: 25px;
  font-family: "Inter";
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mr-10 {
  margin-right: 10px;
}

.header {
  background-color: rgb(36, 44, 92);
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
  width: 100%;
  color: white;
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
}

.header-logo {
  img {
    margin-top: 3px;
    margin-right: 5px;
    width: 25px;
    height: 25px;
  }
}

.header-title {
  font-weight: 500;
}

.dependency-matrix-wrapper {
  margin: 90px 40x 0;
  min-height: 500px;
  font-family: "Inter";
}

.view-dependency-matrix-wrapper {
  margin: 10px 0 0;
}

.component-cell-container:hover span {
  color: #3e6fd9;
}

.dependency-matrix-header-tutorial {
  margin: 10px 0 20px 0;
}

.dependency-matrix-title {
  margin-right: auto;
  height: 42px;
  color: #242c5c;
  font-size: 30px;
  font-family: "Inter";
  white-space: nowrap;
}

.dependency-matrix-table-wrapper {
  margin-top: 50px;
  width: 100%;
  height: calc(100vh - 190px);
  overflow: hidden;
}

.dependency-matrix-container {
  -webkit-transform-origin: left top;
          transform-origin: left top;
  overflow-x: auto;
  overflow-y: auto;
}

.table-cell-container {
  border: 1px solid #d7dbe8;
  width: 63px;
  height: 34px;
}

.table-cell {
  border-radius: 4px;
  background-color: #f7f8fa;
  width: 63px;
  height: 34px;
}

.table-cell.pointer.hover {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
  z-index: 3;
  box-shadow: 0 3px 6px rgba(36, 44, 92, 0.4);
  border: 1px solid #3e6fd9;
}

.attribute-cell {
  justify-content: flex-start;
  z-index: 1;
  border: 1px solid transparent;
  width: 120px;
  height: 34px;
}

.attribute-cell:hover {
  border: 1px solid #3e6fd9;
  border-radius: 4px;
}

.matrix-attribute-title {
  max-width: 100%;
  height: auto;
  min-height: 13px;
  max-height: 34px;
  overflow: hidden;
  color: #5b6185;
  font-size: 12px;
  line-height: 12px;
  font-family: "Inter";
  text-align: left;
  word-break: break-word;
}

.matrix-attribute-title.column {
  text-align: center;
}

.component-cell {
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  width: 123px;
  height: 100%;
}

.component-cell:hover {
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid #3e6fd9;
  border-radius: 4px;
  background-color: white;
  color: #3e6fd9;
  text-decoration: underline;
}

.matrix-component-title {
  display: block;
  max-width: 130px;
  height: auto;
  min-height: 16px;
  max-height: 34px;
  color: #5b6185;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  text-overflow: ellipsis;
  word-break: break-word;
}

.matrix-component-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  height: 34px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.matrix-component-title-container:hover {
  cursor: pointer;
  border: 1px solid #3e6fd9;
  border-radius: 4px;
  background-color: white;
  color: #3e6fd9;
  text-decoration: underline;
}

.matrix-component-title-container:hover .matrix-component-title {
  color: #3e6fd9;
}

.matrix-attribute-title-container {
  z-index: 1;
  width: 63px;
  height: 34px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.matrix-attribute-title-container:hover {
  cursor: pointer;
  border: 1px solid #3e6fd9;
  border-radius: 4px;
  background-color: white;
  color: #3e6fd9;
  text-decoration: underline;
}

.matrix-attribute-title-container:hover span {
  color: #3e6fd9;
}

.dependency-matrix-table td {
  border: 1px solid #d7dbe8;
}

.dependency-matrix-table tr td:last-of-type {
  border-right: none;
}

.dependency-matrix-table tr td:first-of-type {
  border-right: none;
  border-left: none;
}

.dependency-matrix-table tr:first-of-type td {
  z-index: 3;
  border-top: none;
  border-bottom: none;
}

.dependency-matrix-table tr:nth-of-type(2) td {
  border-top: none;
}

.dependency-matrix-table tr:last-of-type td {
  border-bottom: none;
}

.component-type-indicator-container {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
  margin: 0;
  background: #f7f8fa;
  padding-top: 72px;
  width: 87px;
  min-width: 87px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.dependency-matrix-table tr:nth-of-type(1) td:not(:first-of-type) {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 87px;
  background-color: #f7f8fa;
}

.dependency-matrix-table tr:nth-of-type(2) td {
  position: -webkit-sticky;
  position: sticky;
  top: 36px;
  left: 87px;
  box-shadow: 0px 2px 0px -1px #d7dbe8;
  background-color: #f7f8fa;
}

td.component-cell-container {
  position: -webkit-sticky;
  position: sticky;
  left: 87px;
  z-index: 1;
  background-color: #f7f8fa;
  overflow: hidden;
  text-overflow: ellipsis;
}

tr td:first-of-type.component-cell-container {
  border-bottom: none;
}

td.attribute-cell-container {
  position: -webkit-sticky;
  position: sticky;
  left: 209px;
  z-index: 1;
  box-shadow: 2px 0px 0px -1px #d7dbe8;
  background-color: #f7f8fa;
}

.attribute-cell-container:hover {
  cursor: pointer;
  background-color: white;
  color: #3e6fd9;
  text-decoration: underline;
}

.attribute-cell-container:hover span {
  color: #3e6fd9;
}

.internal-type-indicator {
  border-radius: 4px;
  background-color: #eceef5;
  width: 27px;
  height: 120px;
}

.external-type-indicator {
  border-radius: 4px;
  background-color: #dfe4f4;
  width: 27px;
  height: 120px;
}

.component-type-text span {
  height: 49px;
  color: rgba(36, 44, 92, 0.75);
  font-size: 14px;
}

.transform-90-deg {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.component-type-text:hover {
  position: absolute;
}

td.highlighted {
  background-color: #d9e2f6 !important;
}

.matrix-corner-cell {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 87px;
  z-index: 1;
  box-shadow: 2px 2px 0px -1px #d7dbe8;
  background-color: #f7f8fa;
}

.table-cell-container {
  position: relative;
}

.cell-mark span {
  color: black;
  font-size: 18px;
  font-family: "Inter";
}

.matrix-input-container {
  margin: 0 0 10px 20px;
}

.matrix-input-container.first {
  margin-left: auto;
}

@media only screen and (max-width: 980px) {
  .matrix-input-container .custom-search-input .custom-search-input-input {
    max-width: 220px;
  }

  .matrix-input-container {
    margin-left: 10px;
  }
}

.cell-mark span.cell-binary-text {
  color: #242c5c;
  white-space: pre-wrap;
}

.matrix-placeholder {
  padding-top: 30px;
}

.matrix-placeholder span {
  height: 38px;
  color: #242c5c;
  font-size: 22px;
  font-family: "Inter";
}

.cell-dropdown {
  position: absolute;
  top: 50px;
  left: calc(50% - 415px / 2);
  z-index: 3;
  border: 2px solid lightblue;
  border-radius: 5px;
  background-color: white;
  width: 415px;
}

.cell-dropdown::before {
  position: absolute;
  top: -21px;
  left: calc(50% - 17px);
  border: 17px solid transparent;
  border-top: 0;
  border-bottom: 20px solid lightblue;
  width: 0;
  height: 0;
  content: "";
}

.cell-dropdown::after {
  position: absolute;
  top: -18px;
  left: calc(50% - 15px);
  border: 15px solid transparent;
  border-top: 0;
  border-bottom: 18px solid white;
  width: 0;
  height: 0;
  content: "";
}

.cell-dropdown-row {
  position: relative;
  margin-top: 5px;
  border: 2px solid transparent;
  border-radius: 4px;
  background-color: #e8f6ec;
  width: 400px;
  height: 40px;
}

.cell-dropdown-row-text {
  max-width: calc(100% - 10px);
  height: 21px;
  overflow: hidden;
  color: #242c5c;
  font-weight: 700;
  font-size: 13px;
  font-family: "Inter";
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cell-dropdown-row.green {
  background-color: #e9f6ed;
}

.cell-dropdown-row.yellow {
  background-color: #fff4de;
}

.cell-dropdown-row.red {
  background-color: #fde6e1;
}

.cell-dropdown-row.white {
  border: 1px solid #cdd1e5;
  background-color: #ffffff;
}

.cell-dropdown-row:hover {
  border: 2px solid #3e6fd9;
}

.cell-dropdown-row:hover.green {
  background-color: #d0e9d8;
}

.cell-dropdown-row:hover.yellow {
  background-color: #f3e3c4;
}

.cell-dropdown-row:hover.red {
  background-color: #f0cec7;
}

.cell-dropdown-row-rating {
  display: flex;
  flex: 1 1 50px;
  justify-content: center;
  z-index: 3;
  min-width: 5%;
}

.cell-dropdown-row-name {
  flex: 1 1 345px;
  min-width: 25%;
}

.cell-dropdown-idea-score {
  border-radius: 50%;
  background-color: #5ac57a;
  width: 30px;
  min-width: 30px;
  height: 30px;
}

.cell-dropdown-idea-score span {
  margin-left: 1.5px;
  height: 26px;
  color: #ffffff;
  font-size: 20px;
}

.cell-dropdown-row-color-rating {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  width: 7px;
  min-width: 7px;
  height: 100%;
}

.cell-dropdown-row:hover .cell-dropdown-row-color-rating {
  border-radius: 2px 0 0 2px;
  height: 100%;
}

.cell-dropdown-row-buttons-container {
  position: absolute;
  right: 0;
  opacity: 0;
  height: 51px;
}

.cell-dropdown-row:hover .cell-dropdown-row-buttons-container {
  opacity: 1;
}

.open-details-button {
  justify-content: space-between;
  align-items: center;
  outline: none;
  border: none;
  border-radius: 4px;
  background-color: #dfe7fa;
  padding: 0 10px 0 20px;
  width: 400px;
  height: 40px;
}

.open-details-button span {
  color: #3e6fd9;
  font-size: 14px;
  font-family: "Inter";
}

.open-details-button svg {
  color: #3e6fd9;
}

.open-details-button:hover {
  background-color: #3e6fd9;
}

.open-details-button:hover span {
  color: white;
}

.open-details-button:hover svg {
  color: white;
}
.open-details-button .button-triangle-icon {
  margin-left: 5px;
}

.open-details-button:hover .button-triangle-icon {
  border-color: transparent transparent transparent white;
}

.open-details-button.edit {
  margin-right: 10px;
  outline: none;
  padding: 0 10px 0 10px;
  width: 77px;
  height: 30px;
}

.open-details-button.medium {
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
  outline: none;
  border: 1px solid #3e6fd9;
}

.component-dialog-wrapper {
  position: relative;
  border-radius: 4px;
  width: 1000px;
  height: 537px;
  font-family: "Inter";
}

.component-dialog-header {
  justify-content: space-between;
  align-items: center;
  border-radius: 4px 4px 0 0;
  background-color: #eceef5;
  padding: 0 28px;
  width: 100%;
  height: 85px;
}

.component-dialog-title {
  margin-left: 10px;
  height: 38px;
  color: #242c5c;
  font-size: 30px;
  font-family: "Inter";
}

.component-replace-dialog-body {
  display: flex;
  padding: 26px 70px 80px;
  width: 100%;
  height: calc(100% - 165px);
}

.component-header-replacement-top {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.component-header-replacement-label {
  display: flex;
  justify-content: space-between;
  height: 70px;
}

.component-replace-dialog-scrollbar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 235px;
}

.component-dialog-label {
  align-self: flex-start;
  color: #465664;
  font-size: 16px;
  line-height: 24px;
}

.component-dialog-importance-label {
  color: #465664;
  font-size: 16px;
  line-height: 24px;
}

.component-dialog-label.active {
  color: #3e6fd9;
}

.component-replace-dialog-buttons {
  display: flex;
  position: absolute;
  right: 32px;
  bottom: 32px;
}

.component-dialog-button {
  outline: none !important;
  border: none;
  border-radius: 4px;
  width: 105px;
  height: 40px;
  font-size: 14px;
}

.component-dialog-button.secondary {
  margin-right: 10px;
  background-color: transparent;
  color: #888da8;
}

.component-dialog-input-container {
  justify-content: space-between;
}

.location-toggle {
  margin: 0 20px 0 10px;
}

.component-importance {
  height: 36px;
}

.component-to-replace-in-title {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  cursor: default;
  margin-bottom: 30px;
  margin-left: 15px;
  color: #242c5c;
  font-size: 26px;
}

.component-to-replace-in-title u {
  margin: 0 5px;
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.button-disabled {
  background-color: rgb(141, 141, 141);
  pointer-events: none;
}

.button-not-allowed {
  cursor: not-allowed;
}

.component-to-replace-in-button {
  max-width: 420px;
  overflow: hidden;
  font-size: 15.5px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.internal-components-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 1px 1px #c6cbed;
  border-radius: 5px;
  background-color: rgb(243, 243, 243);
  background-color: #eceef5;
  width: 235px;
  height: 270px;
}

.external-components-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;
  box-shadow: 0 1px 1px #c6cbed;
  border-radius: 5px;
  background-color: rgb(243, 243, 243);
  background-color: #eceef5;
  width: 235px;
  height: 270px;
}

.components-list-element {
  flex-grow: 1;
  align-items: center;
  cursor: pointer;
  margin: 0 5px;
  box-shadow: 0 1px 1px #c6cbed;
  border: 1px solid #c6cbed;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 1px;
  padding-top: 10px;
  padding-right: 5px;
  padding-left: 15px;
  width: 200px;
  max-width: 197px;
  height: 50px;
  max-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.components-list-element.active-component {
  -webkit-transform: none;
          transform: none;
  border: 2px solid #3e6fd9;
  padding-top: 9px;
  padding-left: 14px;
}

.components-list-element:hover {
  border: 2px solid #3e6fd9;
  padding-top: 9px;
  padding-left: 14px;
}

.create-new-replacement-component {
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  outline: none !important;
  border: none;
  border-radius: 4px;
  background-color: #3e6fd9;
  width: 260px;
  min-width: 260px;
  height: 44px;
  color: white;
  font-size: 18px;
}

.select-replacement-component {
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  outline: none !important;
  border: none;
  border-radius: 4px;
  background-color: #3e6fd9;
  padding: 5px 10px 5px 20px;
  width: 420px;
  height: 44px;
  color: white;
  font-size: 18px;
}

.replace-component-items {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  width: 49%;
  height: 100%;
}

.replace-component-items_component {
  margin-bottom: 10px;
  margin-left: 5px;
  width: 100%;
}
.replace-component-items:first-child {
  border-right: 1px solid #c5c5de;
}

.custom-replace-label {
  margin-bottom: 5px;
  height: 33px;
  color: #242c5c;
  font-size: 26px;
  text-align: start;
}

.replace-components-container {
  margin-top: 30px;
  height: 79%;
  text-align: start;
}

.replace-scrollbar-host {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-around;
  width: 100%;
  height: 90%;
}

.component-to-replace-in-title_tooltip {
  margin-bottom: 0;
  color: #212529;
  font-size: 26px;
  white-space: nowrap;
}

.replace-component-items_tooltip {
  margin-bottom: 0;
  color: #212529;
  font-size: 16px;
  white-space: nowrap;
}

.subtraction-dialog-wrapper {
  position: relative;
  border-radius: 4px;
  background: white;
  width: 1061px;
  min-width: 768px;
  max-width: 100vw;
  height: 792px;
  min-height: 768px;
  max-height: 100vh;
  overflow: hidden;
  font-family: "Inter";
}

.replacement-dialog-header {
  position: relative;
  background-color: #eceef5;
  width: 100%;
  height: 257px;
}

.subtraction-dialog-header-icon {
  margin-right: 10px;
  height: 26px;
}

.subtraction-dialog-title-container {
  justify-content: space-between;
  align-items: center;
  align-self: flex-start;
  margin-top: 20px;
  padding: 0 28px;
  width: 100%;
}

.replacement-dialog-title {
  margin-left: 10px;
  height: 38px;
  color: #242c5c;
  font-size: 30px;
  font-family: "Inter";
}

.subtraction-dialog-body {
  padding: 40px 140px 0;
  width: 100%;
}

.subtraction-dialog-body.benefits-container {
  padding: 40px 0 0;
}

.replacement-dialog-footer {
  position: absolute;
  bottom: 0;
  border-top: 2px solid #eceef5;
  padding: 0 25px;
  width: 100%;
  height: 60px;
}

.replacement-dialog-button {
  cursor: pointer;
  outline: none !important;
  border: none;
  border-radius: 4px;
  width: 105px;
  height: 48px;
  font-size: 16px;
}

.replacement-dialog-button.disabled {
  background-color: #888da8;
  color: white;
}

.replacement-dialog-button.secondary {
  margin-right: 10px;
  background-color: transparent;
  color: #888da8;
}

.replacement-dialog-button span {
  height: 24px;
  font-size: 18px;
  font-family: "Inter";
}

.replacement-dialog-button.control {
  width: 131px;
}

.replacement-dialog-button.outlined {
  border: 2px solid #3e6fd9;
  background-color: white;
}

.replacement-dialog-button.outlined span {
  color: #3e6fd9;
}

.subtraction-container {
  z-index: 100;
  border: 2px solid #c0c4de;
  border-radius: 4px;
  background-color: white;
  width: 100%;
  min-width: 320px;
  max-width: 485px;
  height: 52px;
}

.subtraction-dialog-controls img {
  margin-left: 30px;
}

.feasible-idea {
  border-radius: 4px;
  background-color: #eceef5;
  height: 110px;
}

.feasible-idea.negative {
  background-color: rgba(241, 102, 106, 0.29);
}

.feasible-idea.positive {
  background-color: rgba(90, 197, 122, 0.2);
}

.feasible-idea-text {
  margin-bottom: 10px;
  height: 25px;
  color: #242c5c;
  font-size: 18px;
  font-family: "Inter";
}

.flex-1 {
  flex: 1 1;
}

.idea-rating-label {
  height: 42px;
  color: #242c5c;
  font-size: 20px;
  font-family: "Inter";
}

.idea-rating-container {
  align-self: center;
  margin-bottom: 40px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.bgc-white {
  background-color: white !important;
}

.subtraction-tabs-circle {
  position: relative;
  transition: border-color 0.5s;
  border: 3px solid transparent;
  border-radius: 20px;
  background-color: #eceef5;
  width: 25px;
  min-width: 25px;
  height: 25px;
}

.subtraction-tabs-circle.active {
  transition: border-color 0.5s;
  border-color: #3e6fd9;
}

.replacement-tab-button span {
  transition: color 0.5s;
  height: 22px;
  color: #888da8;
  font-size: 16px;
  font-family: "Inter";
  white-space: nowrap;
}

.replacement-tab-button div {
  height: 100%;
}

.subtraction-tab-label.active {
  transition: color 0.5s;
  color: #3e6fd9;
}

.replacement-tab-button {
  position: absolute;
  bottom: 20px;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  cursor: pointer;
  outline: none !important;
  border: none;
  background-color: transparent;
  padding: 0 10px;
  height: 36px;
  line-height: 1.1;
}

.completed-idea-button div {
  height: 25%;
}

.replacement-tabs-line-container {
  width: 178px;
  min-width: 300px;
  height: 3px;
  min-height: 3px;
}

.replacement-tabs-line {
  background-color: transparent;
  width: 178px;
  min-width: 300px;
  height: 3px;
  min-height: 3px;
}

.replacement-tabs-container {
  position: absolute;
  bottom: -12px;
  left: -170px;
  justify-content: space-evenly;
  width: 96%;
}

.replacement-tabs-line {
  transition: background-color 0.5s;
  background-color: transparent;
}

.replacement-tabs-line.active {
  transition: background-color 0.5s;
  background-color: #3e6fd9;
  min-width: 300px;
}

.subtraction-tabs-circle svg {
  position: absolute;
  top: -5px;
  left: -5px;
  opacity: 0;
  transition: opacity 0.5s;
  width: 29px;
  min-width: 29px;
  height: 29px;
  min-height: 29px;
}

.subtraction-tabs-circle svg.completed {
  opacity: 1;
  transition: opacity 0.5s;
}

.subtraction-tab {
  min-width: 235px;
  max-width: 20%;
}

.tab-label-optional {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 12px;
}

.replacement-description {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: #242c5c;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.replacement-description u {
  margin: 0 10px 0 10px;
}

.replacement-dialog-header-imagine {
  margin: 0 10px 0 10px;
  width: 98%;
  color: #242c5c;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
}

.replacement-dialog-header-imagine u {
  margin: 0 10px 0 10px;
}

.replacement-modal-label {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
  color: #242c5c;
  font-size: 26px;
  font-family: "Inter";
  text-align: center;
}

.replacement-modal-label span {
  height: 33px;
}

.replacement-modal-label span u {
  word-wrap: break-word;
  margin-right: 6px;
  margin-bottom: -2px;
  margin-left: 6px;
}

.carousel-container__image-container {
  margin-right: 10px;
}

.describe-new-product-page-one {
  margin-bottom: 20px;
  width: 99%;
  font-size: 26px;
}

.subtraction-modal-input {
  margin-bottom: 15px;
}

.replacement-modal__close-benefit-icon {
  position: relative;
  bottom: 37px;
  width: 12px;
  height: 12px;
}

.replacement-modal__add-issue-icon,
.replacement-modal__add-benefit-icon {
  position: relative;
  bottom: 20px;
  width: 14px;
  height: 14px;
  color: #888da8;
}

.replacement-modal__benefit-button {
  margin-left: 10px;
  border: none;
  background: transparent;
  width: 27px;
  height: 26px;
}

.benefits-label {
  align-self: flex-start;
  height: 23px;
  color: #242c5c;
  font-size: 16px;
  font-family: "Inter";
}

.replacement-benefit-input-container {
  padding: 0 140px;
  max-height: 380px;
  overflow: hidden;
  overflow-y: auto;
}

.benefit-input-container button {
  cursor: pointer;
}

.mt--8 {
  margin-top: -8px;
}

.not-feasible {
  position: absolute;
  bottom: 110px;
  left: 30px;
  border-radius: 4px;
  background-color: rgba(241, 102, 106, 0.15);
  width: 1000px;
  height: 53px;
}

.not-feasible span {
  height: 25px;
  color: #242c5c;
  font-size: 18px;
  font-family: "Inter";
}

.issues-input-container {
  width: 100%;
}

.issues-input-container textarea {
  border: 2px solid #eceef5;
  padding: 14px 16px 14px 16px;
  width: 100%;
  max-height: 95px;
  color: #3e6fd9;
  font-size: 15px;
  line-height: 15px;
}

.issues-input-container textarea::-webkit-input-placeholder {
  color: rgba(36, 44, 92, 0.5);
}

.issues-input-container textarea::placeholder {
  color: rgba(36, 44, 92, 0.5);
}

.dependency-modal-input {
  margin-bottom: 8px;
}

.initials-container {
  display: flex;
  position: relative;
  top: -4.5px;
  align-items: flex-start;
  cursor: pointer;
  margin-right: 10px;
}

.issue-input-container {
  padding: 0 140px;
  max-height: 380px;
  overflow: hidden;
  overflow-y: auto;
}

.replacement-modal__issue-button {
  margin-left: 10px;
  border: none;
  background: transparent;
  width: 27px;
  height: 26px;
}

.replacement-modal__comment-solve-container {
  display: inline;
  position: relative;
  top: -25px;
  margin-left: 58px;
}

.replacement-modal__comment-solve-container span {
  cursor: pointer;
  color: #75798d;
  font-size: 14px;
}

.thread-container {
  margin-bottom: 7px;
  margin-left: 42px;
  width: calc(100% - 42px);
}

.width-90 {
  width: 90%;
}

.semitransparent {
  opacity: 0.5;
}

.replacement-modal__button-ai {
  border: none;
  border-radius: 4px;
  background-color: #f9e9ec;
  padding: 5px 5px 5px 5px;
  height: 10%;
  color: #cc4b4f;
  font-weight: 500;
}

.replacement-modal__button-ai:hover {
  background-color: #cc4b4f;
  color: #fff;
}

.replacement-modal__button-ai_name-idea,
.replacement-modal__button-ai_image {
  position: relative;
  width: 50%;
  font-size: 14px;
}

.replacement-modal__button-ai_name-idea {
  top: 31px;
  left: 190px;
}

.replacement-modal__button-ai_image {
  top: 29px;
  left: 94px;
}

.replacement-modal__button-ai_image p {
  margin: 0;
}

.replacement-modal__button-ai_challenges {
  margin: 0 0 15px 25px;
  width: 230px;
  font-size: 14px;
}

.replacement-modal-container__label {
  margin-bottom: 15px;
  width: 50%;
  text-align: left;
}

.replacement-modal-container {
  justify-content: space-around;
  align-items: flex-start;
  margin-bottom: 5px;
}

.replacement-modal-container__name,
.replacement-modal-container__describe {
  width: 380px;
}

.replacement-modal-container__describe {
  margin-top: 15px;
  margin-right: 5px;
}

.replacement-modal-benefit__initials {
  position: relative;
  right: 5px;
  bottom: 53px;
  margin-left: 5px;
}

.replacement-modal-challenges__title.textarea,
.replacement-modal-benefit__title.textarea,
.replacement-modal-challenges-input__empty_title {
  font-size: 18px;
}

.replacement-modal-challenges-input__empty_title {
  padding: 7px 16px;
}

.replacement-modal-challenges-input,
.replacement-modal-benefit-input {
  margin-top: -8px;
}

.replacement-modal-challenges-input__empty,
.replacement-modal-benefit-input__empty {
  background-color: white;
}

.replacement-modal-benefit-line {
  margin-top: 20px;
  border-top: 2px solid #c3cde2;
}

.replacement-modal__button-ai_benefits {
  margin: 0 0 15px 25px;
  width: 208px;
  font-size: 14px;
}

.replacement-modal-benefit {
  margin-bottom: 3px;
}

.replacement-modal-challenges__initials {
  position: relative;
  right: 5px;
  bottom: 42px;
  cursor: pointer;
  margin-left: 5px;
}

.replacement-modal__close-issue-icon {
  position: relative;
  bottom: 32px;
  width: 12px;
  height: 12px;
}

.replacement-modal__close-solve-icon,
.replacement-modal__add-solve-icon {
  position: relative;
  bottom: 5px;
}

.replacement-modal__close-solve-icon {
  width: 12px;
  height: 12px;
}

.replacement-modal__add-solve-icon {
  width: 14px;
  height: 14px;
  color: #888da8;
}

.replacement-modal__upload-image-button {
  position: relative;
  top: 42px;
  right: 8px;
  border: 0px;
  border-radius: 4px;
  width: 45%;
  height: 55px;
  font-size: 14px;
}

.replacement-modal__upload-image-button:hover {
  background-color: #cc4b4f;
}

.replacement-modal__upload-image-button_disabled,
.replacement-modal__upload-image-button_disabled:hover,
.replacement-modal__button-ai_image-disabled,
.replacement-modal__button-ai_image-disabled:hover {
  background-color: #888da8;
  color: white;
}

.components-dashboard-wrapper {
  margin: 20px 40px 0;
  font-family: "Inter";
}

.search-input-container-subtraction-components {
  max-width: 326px;
}

.components-dashboard-count {
  margin-left: 10px;
  height: 38px;
  color: #838fdb;
  font-size: 30px;
}

.add-component-button-round {
  margin: 0 20px;
  color: #3e6fd9;
}

.add-component-button-round:hover {
  color: #3061ae;
}

.components-counter-container {
  padding-bottom: 10px;
  height: 45px;
}

.components-tooltip {
  margin-right: auto;
}

.components-cards-wrapper {
  max-height: 100%;
}

.add-component-button {
  margin-bottom: 10px;
  outline: none !important;
  border: none;
  border-radius: 4px;
  background-color: #d4def2;
  padding: 0 17px 0 20px;
  width: 346px;
  height: 49px;
  color: #3e6fd9;
}

.add-component-button:hover {
  background-color: #3e6fd9;
  color: white;
}

.add-component-button svg {
  width: 13px;
  height: 13px;
}

.add-component-button span {
  height: 24px;
  font-size: 16px;
  font-family: "Inter";
  text-transform: uppercase;
}

.subtraction-component-card {
  position: relative;
  margin: 0 10px 10px 0;
  box-shadow: 0 1px 1px #c6cbed;
  border: 2px solid transparent;
  border-radius: 4px;
  background-color: #eceef5;
  width: 398px;
  min-width: 264px;
  height: 754px;
  -webkit-user-select: none;
          user-select: none;
}

.subtraction-component-card:hover {
  border: 2px solid #3e6fd9;
}

.subtraction-component-card-header {
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 4px;
  background-color: #eceef5;
  padding: 0px;
  width: 100%;
  height: 30px;
}

.subtraction-component-card-header .link-icon {
  margin-right: 8px;
}

.subtract-component-card-rating-menu-wrapper .link-icon {
  margin-right: 8px;
}

.cant-create-idea {
  margin-top: 5px;
  margin-bottom: 5px;
  color: #8a93c3;
  font-size: 15px;
  font-family: "Inter";
  text-align: center;
}

.subtraction-component-card-title {
  margin-right: 3px;
  max-height: 23px;
  overflow: hidden;
  color: #242c5c;
  font-size: 22px;
  line-height: 23px;
  font-family: "Inter";
  letter-spacing: -0.36px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.subtract-component-card-menu-button {
  outline: none !important;
  border: none;
  background-color: transparent;
  width: 20px;
  height: 30px;
}

.component-type {
  border-radius: 4px;
  background-color: #f7f8fa;
  width: 59px;
  height: 22px;
  font-family: "Inter";
}

.component-type span {
  height: 18px;
  color: #3e6fd9;
  font-weight: 500;
  font-size: 14px;
}

.component-type.external {
  background-color: #fdeeee;
}

.component-type.external span {
  color: #f1666a;
}

.component-card-body {
  padding: 0 15px;
}

.subtract-component-card {
  margin-bottom: 10px;
  box-shadow: 0 1px 1px #c6cbed;
  border: 1px solid #c6cbed;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 1px;
  width: 346px;
  height: 114px;
  min-height: 114px;
  max-height: 114px;
}

.subtract-component-card.disabled {
  background-color: #d6dbed;
}

.subtract-component-card.disabled {
  background-color: rgba(241, 102, 106, 0.11);
}

.component-card.disabled {
  background-color: #d6dbed;
}

.subtraction-component-card-header.disabled {
  background-color: #d6dbed;
}

.subtraction-component-card.disabled {
  background-color: #d6dbed;
}

.dropdown-last-idea {
  margin-bottom: 5px;
}

.subtract-component-card:hover {
  border: 2px solid #3e6fd9;
  padding: 0;
}

.component-card-container {
  margin-bottom: 10px;
  width: 100%;
  height: 600px;
}

.subtract-component-card-container {
  margin-bottom: 10px;
  width: 100%;
  height: 570px;
}

.subtract-component-card-container.compact {
  height: 196px;
}

.replacement-component-card-title {
  margin-right: 5px;
  width: 100%;
  height: 25px;
  color: #242c5c;
  font-size: 16px;
  font-family: "Inter";
}

.replacement-header-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.replacement-header-container-tutorial,
.replacement-header-container-settings {
  margin: 10px 0 10px 0;
}

.subtract-component-card.compact {
  position: relative;
  margin-bottom: 7px;
  height: 49px;
  min-height: 49px;
}

.subtract-component-card-header-compact {
  padding: 0 10px;
}

.subtract-component-card-rating-menu-wrapper {
  display: flex;
  flex-direction: row;
}

.subtract-component-card-menu-button.compact {
  width: 22px;
}

.component-card.compact {
  height: 370px;
}

.subtraction-component-type-container {
  margin-top: 16px;
}

.subtraction-component-card-header.compact {
  margin: 0;
}

.subtract-component-card-title-container {
  max-width: 100%;
}

.subtract-component-card-title-container span {
  padding-top: 6px;
  max-width: 217px;
  max-height: 40px;
  overflow: hidden;
  line-height: 18px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-word;
}

.subtract-component-card-title-container.compact span {
  padding-top: 2px;
  max-height: 36px;
  line-height: 20px;
}

.component-card img {
  pointer-events: none;
}

.subtraction-component-card-header-text {
  width: 100%;
  height: 30px;
}

.mt-10 {
  margin-top: 10px;
}

.component-droppable-container {
  min-height: calc(100% - 10px);
}

.component-placeholder {
  padding-top: 30px;
}

.component-placeholder span {
  height: 38px;
  color: #242c5c;
  font-size: 22px;
  font-family: "Inter";
}

.subtraction-component-card-header-top {
  justify-content: space-between;
  width: 363px;
  min-width: 363px;
  height: 32px;
}

.row-dropdown-container {
  position: absolute;
  right: 0;
  opacity: 0;
  height: 51px;
}

.cell-dropdown-row:hover .row-dropdown-container {
  opacity: 1;
}

.open-details-button-subtraction {
  justify-content: space-between;
  align-items: center;
  outline: none;
  border: none;
  border-radius: 4px;
  background-color: #dfe7fa;
  padding: 0 10px 0 20px;
  width: 400px;
  height: 40px;
}

.open-details-button-subtraction span {
  color: #3e6fd9;
  font-size: 16px;
  font-family: "Inter";
}

.open-details-button-subtraction svg {
  color: #3e6fd9;
}

.open-details-button-subtraction:hover {
  background-color: #3e6fd9;
}

.open-details-button-subtraction:hover span {
  color: white;
}

.open-details-button-subtraction:hover svg {
  color: white;
}
.open-details-button-subtraction .button-triangle-icon {
  margin-left: 5px;
}

.open-details-button-subtraction:hover .button-triangle-icon {
  border-color: transparent transparent transparent white;
}

.open-details-button-subtraction.edit {
  margin-right: 10px;
  outline: none;
  padding: 0 10px 0 10px;
  width: 77px;
  height: 30px;
}

.open-details-button-subtraction.medium {
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
  outline: none;
  border: 1px solid #3e6fd9;
}

.active-component {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  box-shadow: 0 3px 6px rgba(36, 44, 92, 0.4);
  border: 1px solid #3e6fd9;
}

.top-2 {
  position: relative !important;
  top: 2px;
}

.green-ideas-count {
  margin-right: 10px;
  height: 100%;
  color: #242c5c;
  font-size: 21px;
  line-height: 35px;
  font-family: "Inter";
}

.custom-radio-option {
  position: relative;
  flex: 1 1;
  margin-right: 20px;
  border: 2px solid #cdd1e5;
  border-radius: 4px;
  background-color: #f7f8fa;
  padding: 0px 60px;
  width: 100%;
  height: 94px;
}

.custom-radio-option:hover {
  border: 2px solid #3e6fd9;
  border-color: currentColor;
}

.custom-radio-option:last-of-type {
  margin-right: 0;
}

.default-radio-option-text {
  height: 33px;
  color: #888da8;
  color: #242c5c;
  font-size: 26px;
  font-family: "Inter";
  -webkit-user-select: none;
          user-select: none;
}

.radio-button-checkmark {
  position: absolute;
  top: 15px;
  right: 20px;
  border: 2px solid #cdd1e5;
  border-radius: 20px;
  background-color: #f7f8fa;
  width: 25px;
  height: 25px;
}

.custom-radio-option svg {
  position: absolute;
  top: 15px;
  right: 20px;
  -webkit-transform: translate(calc((100% - 25px) / 2), calc(0px - (100% - 25px) / 2));
          transform: translate(calc((100% - 25px) / 2), calc(0px - (100% - 25px) / 2));
}

.custom-radio-option.active {
  border: 2px solid #3e6fd9;
}

.custom-radio-option.active .custom-radio-option-span {
  color: #242c5c;
}

.custom-radio-label {
  margin-bottom: 15px;
  height: 33px;
  color: #242c5c;
  font-size: 26px;
  font-family: "Inter";
}

.radio-button-tooltip {
  margin-bottom: 10px;
  margin-left: 10px;
}

.invitation-option-label {
  border-radius: 4px;
  background-color: #cdd1e5;
  padding: 0 5px;
  height: 22px;
  white-space: nowrap;
}

.invitation-option-label span {
  height: 18px;
  color: #242c5c;
  font-size: 12px;
}

.invitation-option-label:not(:last-of-type) {
  margin: 0 10px 10px 0;
}

.invitation-option-label-text {
  align-self: flex-start;
  margin: 20px 0 5px 20px;
  height: 27px;
  color: #465664;
  font-size: 20px;
  text-align: left;
}

.invitation-dropdown-option {
  border: 2px solid transparent;
  min-height: 90px;
}

.invitation-dropdown-option.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.invitation-dropdown-option.first {
  width: 100%;
}

.invitation-dropdown-option:not(.first):hover {
  border: 2px solid #3e6fd9;
  background-color: #dbdfed;
}

.labels-container {
  margin-left: 14px;
}

.labels-container.first {
  max-width: 100%;
  max-height: 28px;
  overflow: hidden;
}

.option-divider {
  margin: 0;
  margin-top: auto;
}

.invitation-dropdown-placeholder {
  margin-left: 30px;
  height: 32px;
  color: #465664;
  font-size: 24px;
}

.team-selector {
  border-radius: 4px;
  height: 100%;
}

.invitation-dropdown-container {
  position: relative;
  -webkit-user-select: none;
          user-select: none;
}

.invitation-dropdown {
  border: 2px solid #cdd1e5;
  border-radius: 4px;
  background-color: #f7f8fa;

  width: 538px;
  height: 90px;
}

.invitation-dropdown-triangle {
  position: absolute;
  top: 50px;
  right: 30px;
  border: 5px solid;
  border-color: #888da8 transparent transparent transparent;
}

.invitation-dropdown.active {
  border: 2px solid #cdd1e5;
  border-bottom: 2px solid transparent;
  border-radius: 4px 4px 0 0;
}

.invitation-dropdown-options {
  position: absolute;
  z-index: 200;
  border: 2px solid #cdd1e5;
  border-top: none;
  border-radius: 0 0 4px 4px;
  background-color: #f7f8fa;
  width: 538px;
  max-height: 272px;
  overflow-x: hidden;
  overflow-y: auto;
}

.invitation-dropdown-search-input {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 200;
}

.invitation-dropdown-search-input .custom-search-input {
  max-width: unset;
}

.share-ideas-wrapper {
  position: relative;
  border-radius: 4px;
  background-color: #ffffff;
  width: 622px;
  height: 720px;
  font-family: "Inter";
}

.share-ideas-header {
  justify-content: space-between;
  align-items: center;
  border-radius: 4px 4px 0 0;
  background-color: #eceef5;
  padding: 0 40px;
  width: 100%;
  height: 85px;
}

.share-ideas-body {
  padding: 30px 40px 0 40px;
  width: 100%;
}

.share-ideas-radio-text {
  color: #242c5c;
  font-size: 23px;
  line-height: 22px;
  font-family: "Inter";
  -webkit-user-select: none;
          user-select: none;
}

.share-ideas-label {
  border-radius: 4px;
  background-color: #eaf7ee;
  width: 549px;
  height: 50px;
}

.share-ideas-label span {
  margin-left: 10px;
  width: 183px;
  height: 32px;
  color: #39ac5b;
  font-weight: 600;
  font-size: 22px;
  font-family: "Inter";
  letter-spacing: 0.2px;
}

.share-ideas-button {
  position: absolute;
  bottom: 40px;
  align-self: flex-end;
  outline: none !important;
  border: none;
  border-radius: 4px;
  background-color: #3e6fd9;
  width: 243px;
  height: 44px;
}

.share-ideas-button span {
  height: 24px;
  color: #ffffff;
  font-size: 18px;
  text-transform: uppercase;
}

.teams-option-label {
  border-radius: 4px;
  background-color: #cdd1e5;
  padding: 0 5px;
  height: 22px;
  white-space: nowrap;
}

.teams-option-label span {
  height: 18px;
  color: #242c5c;
  font-size: 12px;
}

.teams-option-label:not(:last-of-type) {
  margin: 0 10px 10px 0;
}

.teams-option-label-text {
  align-self: flex-start;
  margin: 20px 0 5px 20px;
  height: 27px;
  color: #465664;
  font-size: 20px;
  text-align: left;
}

.teams-dropdown-option {
  border: 2px solid transparent;
  min-height: 90px;
}

.teams-dropdown-option.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.teams-dropdown-option.first {
  width: 100%;
  min-height: 48px;
}

.teams-dropdown-option:not(.first):hover {
  border: 2px solid #3e6fd9;
  background-color: #dbdfed;
}

.teams-option-label-container {
  margin-right: 5px;
}

.teams-dropdown-team-name svg {
  position: absolute;
  right: 18px;
  -webkit-transform: translate(calc((100% - 25px) / 2), 0);
          transform: translate(calc((100% - 25px) / 2), 0);
}

.teams-round-button {
  position: absolute;
  right: 18px;
  border: 2px solid #cdd1e5;
  border-radius: 20px;
  background-color: #f7f8fa;
  width: 25px;
  height: 25px;
}

.teams-labels-container {
  margin-left: 14px;
}

.labels-container.first {
  max-width: 100%;
  max-height: 28px;
  overflow: hidden;
}

.teams-option-divider {
  margin: 0;
  margin-top: auto;
}

.teams-labels-container-first {
  margin-right: 35px;
  margin-left: 14px;
  max-height: 78px;
  overflow: auto;

  scrollbar-width: none;
  -ms-overflow-style: none;
}

.teams-labels-container-first::-webkit-scrollbar {
  display: none;
}

.user-dropdown-option {
  border: 2px solid transparent;
  background-color: #f7f8fa;
  min-height: 48px;
}

.user-dropdown-option.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.user-dropdown-option.first {
  width: 100%;
}

.user-dropdown-option:not(.first):hover {
  border: 2px solid #3e6fd9;
  background-color: white;
}

.users-option-label-container {
  margin-right: 5px;
}

.user-dropdown-label-text {
  margin-left: 15px;
  max-width: 100%;
  height: 22px;
  overflow: hidden;
  color: #3e6fd9;
  font-size: 16px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user-dropdown-divider {
  margin: 0;
  margin-top: auto;
}

.user-dropdown-name {
  padding: 0 35px 0 28px;
  height: 46px;
}

.user-dropdown-name svg {
  position: absolute;
  right: 35px;
  -webkit-transform: translate(calc((100% - 25px) / 2), 0);
          transform: translate(calc((100% - 25px) / 2), 0);
}

.user-round-button {
  position: absolute;
  right: 35px;
  border: 2px solid #cdd1e5;
  border-radius: 20px;
  background-color: #f7f8fa;
  width: 25px;
  height: 25px;
}

.remove-user-button {
  outline: none !important;
  border: none;
  background: transparent;
  width: 22px;
  height: 22px;
}

.remove-user-button img {
  max-width: 14px;
  max-height: 14px;
}

.users-labels-container {
  margin-right: 35px;
  margin-left: 14px;
  max-height: 78px;
  overflow: auto;

  scrollbar-width: none;
  -ms-overflow-style: none;
}

.users-labels-container::-webkit-scrollbar {
  display: none;
}

.users-option-label {
  margin-bottom: 5px;
  border-radius: 4px;
  background-color: #cdd1e5;
  padding: 0 5px;
  height: 22px;
  white-space: nowrap;
}

.users-option-label span {
  height: 18px;
  color: #242c5c;
  font-size: 12px;
}

.users-option-label:not(:last-of-type) {
  margin: 0 10px 10px 0;
}

.users-option-label-text {
  align-self: flex-start;
  margin: 20px 0 5px 20px;
  height: 27px;
  color: #465664;
  font-size: 20px;
  text-align: left;
}

.subtraction-modal-wrapper {
  position: relative;
  border-radius: 4px;
  background: white;
  width: 1061px;
  min-width: 768px;
  max-width: 100vw;
  height: 792px;
  min-height: 768px;
  max-height: 100vh;
  overflow: hidden;
  font-family: "Inter";
}

.subtraction-modal-header {
  position: relative;
  background-color: #eceef5;
  width: 100%;
  height: 257px;
}

.subtraction-modal-header-icon {
  margin-right: 10px;
  height: 26px;
}

.subtraction-modal-title-container {
  justify-content: space-between;
  align-items: center;
  align-self: flex-start;
  margin-top: 20px;
  padding: 0 28px;
  width: 100%;
}

.subtraction-modal-title {
  height: 38px;
  color: #242c5c;
  font-size: 30px;
  font-family: "Inter";
}

.subtraction-modal-body {
  padding: 40px 140px 0;
  width: 100%;
}

.subtraction-modal-body.benefits-container {
  padding: 40px 0 0;
}

.subtraction-modal-footer {
  position: absolute;
  bottom: 0;
  border-top: 2px solid #eceef5;
  padding: 0 25px;
  width: 100%;
  height: 60px;
}

.subtraction-modal-button {
  cursor: pointer;
  outline: none !important;
  border: none;
  border-radius: 4px;
  width: 105px;
  height: 48px;
  font-size: 16px;
}

.subtraction-modal-button.disabled {
  background-color: #888da8;
  color: white;
}

.subtraction-modal-button.secondary {
  margin-right: 10px;
  background-color: transparent;
  color: #888da8;
}

.subtraction-modal-button span {
  height: 24px;
  font-size: 18px;
  font-family: "Inter";
}

.subtraction-modal-button.control {
  width: 131px;
}

.subtraction-modal-button.outlined {
  border: 2px solid #3e6fd9;
  background-color: white;
}

.subtraction-modal-button.outlined span {
  color: #3e6fd9;
}

.subtraction-text {
  align-self: center;
  margin-top: 20px;
  margin-bottom: auto;
  width: 100%;
  max-width: 900px;
  height: 24px;
  color: #242c5c;
  font-size: 28px;
  line-height: 22px;
  font-family: "Inter";
}

.subtraction-container {
  z-index: 100;
  border: 2px solid #c0c4de;
  border-radius: 4px;
  background-color: white;
  width: 100%;
  min-width: 320px;
  max-width: 485px;
  height: 52px;
}

.subtraction-modal-controls img {
  margin-left: 30px;
}

.feasible-idea {
  border-radius: 4px;
  background-color: #eceef5;
  height: 110px;
}

.feasible-idea.negative {
  background-color: rgba(241, 102, 106, 0.29);
}

.feasible-idea.positive {
  background-color: rgba(90, 197, 122, 0.2);
}

.feasible-idea-text {
  margin-bottom: 10px;
  height: 25px;
  color: #242c5c;
  font-size: 18px;
  font-family: "Inter";
}

.flex-1 {
  flex: 1 1;
}

.idea-rating-label {
  height: 42px;
  color: #242c5c;
  font-size: 20px;
  font-family: "Inter";
}

.idea-rating-container {
  align-self: center;
  margin-bottom: 40px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.bgc-white {
  background-color: white !important;
}

.subtraction-tabs-circle {
  position: relative;
  transition: border-color 0.5s;
  border: 3px solid transparent;
  border-radius: 20px;
  background-color: #eceef5;
  width: 25px;
  min-width: 25px;
  height: 25px;
}

.subtraction-tabs-circle.active {
  transition: border-color 0.5s;
  border-color: #3e6fd9;
}

.subtraction-tab-button span {
  transition: color 0.5s;
  height: 22px;
  color: #888da8;
  font-size: 16px;
  font-family: "Inter";
  white-space: nowrap;
}

.subtraction-tab-button div {
  height: 100%;
}

.subtraction-tab-label.active {
  transition: color 0.5s;
  color: #3e6fd9;
}

.subtraction-tab-button {
  position: absolute;
  bottom: 20px;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  cursor: pointer;
  outline: none !important;
  border: none;
  background-color: transparent;
  padding: 0 10px;
  height: 36px;
  line-height: 1.1;
}

.completed-idea-button div {
  height: 25%;
}

.subtraction-tabs-line-container {
  width: 178px;
  min-width: 300px;
  height: 3px;
  min-height: 3px;
}

.subtraction-tabs-line {
  background-color: transparent;
  width: 178px;
  min-width: 300px;
  height: 3px;
  min-height: 3px;
}

.subtraction-tabs-container {
  position: absolute;
  bottom: -12px;
  left: -170px;
  justify-content: space-evenly;
  width: 96%;
}

.subtraction-tabs-line {
  transition: background-color 0.5s;
  background-color: transparent;
}

.subtraction-tabs-line.active {
  transition: background-color 0.5s;
  background-color: #3e6fd9;
  min-width: 300px;
}

.subtraction-tabs-circle svg {
  position: absolute;
  top: -5px;
  left: -5px;
  opacity: 0;
  transition: opacity 0.5s;
  width: 29px;
  min-width: 29px;
  height: 29px;
  min-height: 29px;
}

.subtraction-tabs-circle svg.completed {
  opacity: 1;
  transition: opacity 0.5s;
}

.subtraction-tab {
  min-width: 235px;
  max-width: 20%;
}

.tab-label-optional {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 12px;
}

.subtraction-description-describe {
  color: #242c5c;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.subtraction-description-describe u {
  margin: 0 10px 0 10px;
}

.subtraction-imagine-the {
  height: 32px;
}

.subtraction-without-the {
  height: 32px;
}

.subtraction-modal-header-imagine {
  margin: 0 10px 0 10px;
  width: 98%;
  color: #242c5c;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
}

.subtraction-modal-header-imagine u {
  margin: 0 10px 0 10px;
}

.subtraction-modal-benefits-container__label {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
  color: #242c5c;
  font-size: 26px;
  font-family: "Inter";
  text-align: center;
}

.subtraction-modal-benefits-container__label span {
  height: 33px;
}

.subtraction-modal-benefits-container__label span u {
  max-width: 300px;
  word-wrap: break-word;
  margin-right: 6px;
  margin-bottom: -2px;
  margin-left: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.carousel-container__image-container {
  margin-right: 10px;
}

.describe-new-product-page-one {
  margin-bottom: 20px;
  width: 99%;
  font-size: 26px;
}

.subtraction-modal-input {
  margin-bottom: 15px;
}

.subtraction-modal__close-benefit-icon {
  position: relative;
  bottom: 37px;
  width: 12px;
  height: 12px;
}

.subtraction-modal__close-issue-icon {
  position: relative;
  bottom: 32px;
  width: 12px;
  height: 12px;
}

.subtraction-modal__add-benefit-icon,
.subtraction-modal__add-issue-icon {
  position: relative;
  bottom: 20px;
  width: 14px;
  height: 14px;
  color: #888da8;
}

.subtraction-modal__benefit-button {
  margin-left: 10px;
  border: none;
  background: transparent;
  width: 27px;
  height: 26px;
}

.subtraction-modal-benefit {
  margin-bottom: 3px;
}

.benefits-label {
  align-self: flex-start;
  height: 23px;
  color: #242c5c;
  font-size: 16px;
  font-family: "Inter";
}

.subtraction-modal-benefits-container {
  padding: 0 140px;
  max-height: 380px;
  overflow: hidden;
  overflow-y: auto;
}

.subtraction-modal-benefits-container button {
  cursor: pointer;
}

.mt--8 {
  margin-top: -8px;
}

.not-feasible {
  position: absolute;
  bottom: 110px;
  left: 30px;
  border-radius: 4px;
  background-color: rgba(241, 102, 106, 0.15);
  width: 1000px;
  height: 53px;
}

.not-feasible span {
  height: 25px;
  color: #242c5c;
  font-size: 18px;
  font-family: "Inter";
}

.issues-input-container {
  width: 100%;
}

.issues-input-container textarea {
  border: 2px solid #eceef5;
  padding: 14px 16px 14px 16px;
  width: 100%;
  max-height: 95px;
  color: #3e6fd9;
  font-size: 15px;
  line-height: 15px;
}

.issues-input-container textarea::-webkit-input-placeholder {
  color: rgba(36, 44, 92, 0.5);
}

.issues-input-container textarea::placeholder {
  color: rgba(36, 44, 92, 0.5);
}

.subtraction-modal__benefits-header {
  margin-bottom: 15px;
  height: 33px;
  color: #242c5c;
  font-size: 26px;
  font-family: "Inter";
}

.dependency-modal-input {
  margin-bottom: 8px;
}

.initials-container {
  display: flex;
  position: relative;
  top: -4.5px;
  align-items: flex-start;
  cursor: pointer;
  margin-right: 10px;
}

.issue-input-container {
  padding: 0 140px;
  max-height: 380px;
  overflow: hidden;
  overflow-y: auto;
}

.subtraction-modal__issue-button {
  margin-left: 10px;
  border: none;
  background: transparent;
  width: 27px;
  height: 26px;
}

.thread-container {
  margin-bottom: 7px;
  margin-left: 42px;
  width: calc(100% - 42px);
}

.width-90 {
  width: 90%;
}

.semitransparent {
  opacity: 0.5;
}

.subtraction-modal__button-ai {
  border: none;
  border-radius: 4px;
  background-color: #f9e9ec;
  padding: 7px 7px 7px 7px;
  height: 10%;
  color: #cc4b4f;
  font-weight: 500;
}

.subtraction-modal__button-ai:hover {
  background-color: #cc4b4f;
  color: #fff;
}

.subtraction-modal__button-ai_name-idea,
.subtraction-modal__button-ai_image {
  position: relative;
  width: 50%;
  font-size: 14px;
}

.subtraction-modal__button-ai_name-idea {
  top: 30px;
  left: 190px;
}

.subtraction-modal__button-ai_image {
  top: 30px;
  left: 94px;
}

.subtraction-modal__button-ai_image p {
  margin: 0;
}

.subtraction-modal-container__label {
  margin-bottom: 13px;
  width: 50%;
  text-align: left;
}

.subtraction-modal__button-ai_benefits {
  margin: 0 0 15px 25px;
  width: 208px;
  font-size: 14px;
}

.subtraction-modal__button-ai_challenges {
  margin: 0 0 15px 25px;
  width: 230px;
  font-size: 14px;
}

.subtraction-modal-container {
  justify-content: space-around;
  align-items: flex-start;
  margin-bottom: 5px;
}

.subtraction-modal-container__name,
.subtraction-modal-container__describe {
  width: 380px;
}

.subtraction-modal-container__describe {
  margin-top: 15px;
  margin-right: 5px;
}

.subtraction-modal-benefit-input,
.subtraction-modal-challenges-input {
  margin-top: -8px;
}

.subtraction-modal-benefit-input__empty,
.subtraction-modal-challenges-input__empty {
  background-color: white;
}

.subtraction-modal-benefit-line {
  margin-top: 20px;
  border-top: 2px solid #c3cde2;
}

.subtraction-modal-benefit__title.textarea,
.subtraction-modal-challenges-input__empty_title {
  font-size: 18px;
}

.subtraction-modal-challenges-input__empty_title {
  padding: 7px 16px;
}

.subtraction-modal-benefit__initials {
  position: relative;
  right: 5px;
  bottom: 53px;
  margin-left: 5px;
}

.subtraction-modal-challenges__initials {
  position: relative;
  right: 5px;
  bottom: 42px;
  cursor: pointer;
  margin-left: 5px;
}

.subtraction-modal__comment-solve-container {
  display: inline;
  position: relative;
  top: -25px;
  margin-left: 58px;
}

.subtraction-modal__comment-solve-container span {
  cursor: pointer;
  color: #75798d;
  font-size: 14px;
}

.subtraction-modal__close-solve-icon,
.subtraction-modal__add-solve-icon {
  position: relative;
  bottom: 5px;
}

.subtraction-modal__close-solve-icon {
  width: 12px;
  height: 12px;
}

.subtraction-modal__add-solve-icon {
  width: 14px;
  height: 14px;
  color: #888da8;
}

.subtraction-modal__upload-image-button {
  position: relative;
  top: 42px;
  right: 8px;
  border: 0px;
  border-radius: 4px;
  width: 45%;
  height: 59px;
  font-size: 14px;
}

.subtraction-modal__upload-image-button:hover {
  background-color: #cc4b4f;
}

.subtraction-modal__upload-image-button_disabled,
.subtraction-modal__upload-image-button_disabled:hover,
.subtraction-modal__button-ai_image-disabled,
.subtraction-modal__button-ai_image-disabled:hover {
  background-color: #888da8;
  color: white;
}

.reports-row-expanded {
  margin-bottom: 5px;
  margin-left: 2px;
  border-left: 7px solid transparent;
  width: 100%;
  max-height: 230px;
}

.reports-row-expanded-section {
  display: flex;
  flex: 1 1;
  border-radius: 6px;
  width: 100%;
  overflow: hidden;
}

.reports-row-expanded-header {
  border-radius: 6px 6px 0 0;
  background-color: #d6e8dc;
  padding: 15px 30px 0;
  width: 100%;
  min-height: 70px;
}

.reports-row-expanded-section:not(:last-of-type) {
  margin-right: 15px;
}

.reports-row-expanded-header span {
  height: 24px;
  color: #242c5c;
  font-size: 18px;
  font-family: "Inter";
}

.reports-row-expanded-container {
  padding: 20px 30px;
  width: 100%;
}

.reports-row-expanded-content {
  padding: 15px 25px;
  height: 100%;
  overflow: auto;
  text-align: left;
}

.reports-row-comments {
  color: #242c5c;
  font-size: 15px;
  line-height: 22px;
  font-family: "Inter";
  text-align: left;
  word-break: break-word;
}

.list-item {
  align-items: baseline;
  margin-bottom: 10px;
}

.list-item span,
.issue-description {
  color: #242c5c;
  font-size: 15px;
  line-height: 16.5px;
  font-family: "Inter";
  text-align: left;
  word-break: break-word;
}

.list-item p {
  margin-bottom: 0;
}

.user-initials .benefits-list-container {
  align-items: flex-start;
}

.benefits-list-title img {
  margin-top: 5px;
  margin-right: 8px;
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
}

.benefits-list-title .bullet-point-item {
  margin-top: 5px;
  width: 12px;
  height: 12px;
}

.benefits-list-title span {
  height: auto;
  color: #242c5c;
  font-size: 16px;
  font-family: "Inter";
  word-break: break-word;
}

.benefits-list {
  padding-left: 25px;
}

.benefits-list span {
  word-break: break-word;
}

.reports-row-expanded-content.green {
  background-color: #dcefe2;
}

.reports-row-expanded-content.yellow {
  background-color: #f5ead1;
}

.reports-row-expanded-content.red {
  background-color: #f7ddd9;
}

.reports-row-expanded-header.green {
  background-color: #d6e8dc;
}

.reports-row-expanded-header.yellow {
  background-color: #ebdfc4;
}

.reports-row-expanded-header.red {
  background-color: #f4d5d0;
}

.ml-30 {
  margin-left: 35px;
}

.reports-row-benefit div {
  justify-content: center;
}

.issue-expanded-report {
  margin-left: 10px;
}

.message-expanded-report {
  margin-left: 5px;
  max-width: calc(100% - 70px);
}

.compact-row {
  letter-spacing: -0.7px;
}

.letter-spacing-compact {
  letter-spacing: -0.9px;
}

.report-page-wrapper {
  margin: 91px 40px 0;
  background-color: #f7f8fa;
  font-family: "Inter";
}

.report-page-title {
  margin-right: 20px;
  height: 40px;
  color: #242c5c;
  font-size: 32px;
  font-family: "Inter";
  white-space: nowrap;
}

.print-report-button {
  justify-content: space-between;
  align-items: center;
  outline: none;
  border: none;
  border-radius: 4px;
  background-color: #dfe7fa;
  padding: 0 10px 0 20px;
  width: 151px;
  height: 41px;
}

.print-report-button span {
  color: #3e6fd9;
  font-size: 15px;
  font-family: "Inter";
}

.print-report-button svg {
  color: #3e6fd9;
}

.print-report-button:hover {
  background-color: #3e6fd9;
}

.print-report-button:hover span {
  color: white;
}

.print-report-button:hover svg {
  color: white;
}

.print-report-button:hover .button-triangle-icon {
  border-color: transparent transparent transparent white;
}

.reports-container-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  margin: 30px 0 10px 10px;
  padding: 0 20px 0 10px;
  width: calc(100% - 10px);
}

.reports-container {
  margin-left: 10px;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 10px 20px 20px 10px;
  width: calc(100% - 10px);
  overflow-y: auto;
}

.reports-row-header {
  height: 22px;
}

.reports-row {
  position: relative;
  margin-bottom: 5px;
  border: 2px solid transparent;
  border-radius: 4px;
  background-color: #e8f6ec;
  height: 51px;
}

.reports-table {
  border-radius: 4px;
  min-width: 615px;
}

.reports-row.expanded {
  margin-bottom: 0;
}

.reports-row.green,
.reports-row-expanded.green {
  background-color: #e9f6ed;
}

.reports-row.yellow,
.reports-row-expanded.yellow {
  background-color: #fff4de;
}

.reports-row.red,
.reports-row-expanded.red {
  background-color: #fde6e1;
}

.reports-row.white,
.reports-row-expanded.white {
  background-color: #f8f9fa;
}

.reports-row:hover {
  border: 2px solid #3e6fd9;
}

.reports-row:hover.green {
  background-color: #d0e9d8;
}

.reports-row:hover.yellow {
  background-color: #f3e3c4;
}

.reports-row:hover.red {
  background-color: #f0cec7;
}

.reports-checkbox {
  width: 21px;
  height: 21px;
}

.reports-row-checkbox {
  width: 58px;
  min-width: 6%;
  height: 21px;
}

.reports-row-rating {
  display: flex;
  flex: 1 1 82px;
  justify-content: center;
  z-index: 10;
  min-width: 6%;
}

.reports-row-component {
  flex: 1 1 135px;
  min-width: 8%;
}

.reports-row-first-attribute {
  flex: 1 1 175px;
  margin-left: 50px;
  min-width: 11%;
}

.reports-row-second-attribute {
  flex: 1 1 175px;
  min-width: 11%;
}

.reports-row-name {
  flex: 1 1 235px;
  min-width: 15%;
}

.reports-row-method {
  flex: 1 1 110px;
  width: 30px;
}

.reports-row-header .reports-row-method {
  flex: 1 1 120px;
  width: 30px;
}

.reports-row-header .reports-row-name {
  flex: 1 1 225px;
  min-width: 12%;
}

.border-report {
  height: 110%;
}

.border-right-report {
  margin-right: -5px;
  margin-left: 5px;
  border-right: 5px solid white;
  height: 110%;
}

.border-left-report {
  margin-right: 20px;
  margin-left: -20px;
  border-left: 5px solid white;
  padding-left: 5px;
  height: 110%;
}

.reports-row-benefit {
  flex: 1 1 175px;
  justify-content: space-around;
  min-width: 13%;
}

.reports-row-created {
  flex: 1 1 120px;
  min-width: 8%;
}

.reports-row-created-by {
  flex: 1 1 120px;
  justify-content: center;
  min-width: 8%;
}

.report-idea-score {
  border-radius: 50%;
  background-color: #5ac57a;
  width: 35px;
  min-width: 35px;
  height: 35px;
}

.report-idea-score span {
  height: 35px;
  color: #ffffff;
  font-size: 21px;
  line-height: 35px;
}

.reports-row-text {
  max-width: calc(100% - 10px);
  height: 20px;
  overflow: hidden;
  color: #242c5c;
  font-size: 16px;
  line-height: 20px;
  font-family: "Inter";
  text-overflow: ellipsis;
  white-space: nowrap;
}

.reports-row-initials {
  border-radius: 2px;
  background-color: #fde8e9;
  width: 32px;
  height: 26px;
}

.reports-row-initials span {
  height: 20px;
  color: #f1666a;
  font-size: 14px;
}

.padding-0 {
  padding: 0;
}

.abbreviation {
  margin-left: 5px;
  max-width: calc(100% - 55px);
  height: 25px;
  line-height: 25px;
}

.benefits-count {
  margin-bottom: 5px;
  font-size: 21px;
}

.reports-row-color-rating {
  width: 7px;
  min-width: 7px;
  height: calc(100% + 4px);
}

.reports-row-color-rating.expanded {
  height: 100%;
}

.reports-row:hover .reports-row-color-rating {
  border-radius: 2px 0 0 2px;
  height: 100%;
}

.reports-icon-container {
  min-width: 20px;
}

.reports-icon-container img {
  min-width: 20px;
  max-width: 20px;
}

.sort-triangle-icon {
  margin-top: 5px;
  border: 5px solid;
  border-color: #3e6fd9 transparent transparent transparent;
  width: 0;
  height: 0;
}

.sort-triangle-icon.asc {
  margin-top: 0px;
  margin: 0 0 5px;
  border-color: transparent transparent #3e6fd9 transparent;
}

.reports-row-text.active {
  color: #3e6fd9;
}

.reports-row-buttons-container {
  position: absolute;
  right: 0;
  opacity: 0;
  height: 51px;
}

.reports-row-buttons-container:hover {
  opacity: 1;
}

.print-report-button.edit {
  margin-right: 10px;
  outline: none;
  padding: 0 10px 0 10px;
  width: 77px;
}

.print-report-button.matrix {
  margin-right: 10px;
  outline: none;
  padding: 0 10px 0 10px;
  width: 138px;
}

.print-report-button.multiplication {
  margin-right: 10px;
  outline: none;
  padding: 0 10px 0 10px;
  width: 150px;
}

.print-report-button.medium {
  outline: none;
}

.reports-input-container {
  margin: 0 0 8px 10px;
}

.report-page-inputs-settings {
  margin: 10px 0 10px 0;
}

.reports-page-inputs-container {
  min-width: 615px;
}

.reports-input-first {
  align-items: flex-start;
  margin-left: auto;
  white-space: nowrap;
}

.reports-page-inputs-container {
  flex-wrap: wrap;
}

.report-page-inputs {
  min-width: 615px;
}

@media only screen and (max-width: 1080px) {
  .search-input-container.report {
    max-width: 210px;
  }
}

.justify-content-start {
  justify-content: start;
}

.login-text .terms-and-conditions {
  height: auto;
  line-height: 21px;
  text-align: left;
}

.terms-and-conditions.red,
.terms-and-conditions.red a {
  color: #f1666a;
}

.language-sign-up {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 17px;
}

.flag-icon-sign-up {
  cursor: pointer;
  margin-left: 5px;
  width: 30px;
  height: 20px;
}

.underlined {
  margin: 0 5px;
}

.captcha-error {
  border: 2px solid #f1666a;
  border-radius: 3px;
}

.custom-captcha {
  margin-bottom: 20px;
  width: 100%;
}

.width-49 {
  width: 49%;
}

.flex-grow-1 {
  flex-grow: 1;
}

.components-dashboard-wrapper {
  margin: 20px 40px 0;
  font-family: "Inter";
}

.search-input-container-subtraction-components {
  max-width: 326px;
}

.components-dashboard-count {
  margin-left: 10px;
  height: 38px;
  color: #838fdb;
  font-size: 30px;
}

.add-component-button-round {
  margin: 0 20px;
  color: #3e6fd9;
}

.add-component-button-round:hover {
  color: #3061ae;
}

.components-counter-container {
  padding-bottom: 10px;
  height: 45px;
}

.components-tooltip {
  margin-right: auto;
}

.components-cards-wrapper {
  max-height: 100%;
}

.add-component-button {
  margin-bottom: 10px;
  outline: none !important;
  border: none;
  border-radius: 4px;
  background-color: #d4def2;
  padding: 0 17px 0 20px;
  width: 346px;
  height: 49px;
  color: #3e6fd9;
}

.add-component-button:hover {
  background-color: #3e6fd9;
  color: white;
}

.add-component-button svg {
  width: 13px;
  height: 13px;
}

.add-component-button span {
  height: 24px;
  font-size: 16px;
  font-family: "Inter";
  text-transform: uppercase;
}

.subtraction-component-card {
  position: relative;
  margin: 0 10px 10px 0;
  box-shadow: 0 1px 1px #c6cbed;
  border: 2px solid transparent;
  border-radius: 4px;
  background-color: #eceef5;
  width: 398px;
  min-width: 264px;
  height: 754px;
  -webkit-user-select: none;
          user-select: none;
}

.subtraction-component-card:hover {
  border: 2px solid #3e6fd9;
}

.subtraction-component-card-header {
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 4px;
  background-color: #eceef5;
  padding: 0px;
  width: 100%;
  height: 30px;
}

.subtraction-component-card-header .link-icon {
  margin-right: 8px;
}

.subtract-component-card-rating-menu-wrapper .link-icon {
  margin-right: 8px;
}

.cant-create-idea {
  margin-top: 5px;
  margin-bottom: 5px;
  color: #8a93c3;
  font-size: 15px;
  font-family: "Inter";
  text-align: center;
}

.subtraction-component-card-title {
  margin-right: 3px;
  max-height: 23px;
  overflow: hidden;
  color: #242c5c;
  font-size: 22px;
  line-height: 23px;
  font-family: "Inter";
  letter-spacing: -0.36px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.subtract-component-card-menu-button {
  outline: none !important;
  border: none;
  background-color: transparent;
  width: 20px;
  height: 30px;
}

.component-type {
  border-radius: 4px;
  background-color: #f7f8fa;
  width: 59px;
  height: 22px;
  font-family: "Inter";
}

.component-type span {
  height: 18px;
  color: #3e6fd9;
  font-weight: 500;
  font-size: 14px;
}

.component-type.external {
  background-color: #fdeeee;
}

.component-type.external span {
  color: #f1666a;
}

.component-card-body {
  padding: 0 15px;
}

.subtract-component-card {
  margin-bottom: 10px;
  box-shadow: 0 1px 1px #c6cbed;
  border: 1px solid #c6cbed;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 1px;
  width: 346px;
  height: 114px;
  min-height: 114px;
  max-height: 114px;
}

.subtract-component-card.disabled {
  background-color: #d6dbed;
}

.subtract-component-card.disabled {
  background-color: rgba(241, 102, 106, 0.11);
}

.component-card.disabled {
  background-color: #d6dbed;
}

.subtraction-component-card-header.disabled {
  background-color: #d6dbed;
}

.subtraction-component-card.disabled {
  background-color: #d6dbed;
}

.dropdown-last-idea {
  margin-bottom: 5px;
}

.subtract-component-card:hover {
  border: 2px solid #3e6fd9;
  padding: 0;
}

.component-card-container {
  margin-bottom: 10px;
  border-radius: 1px;
  padding-top: 10px;
  width: 100%;
  height: 600px;
}

.subtract-component-card-container {
  margin-bottom: 10px;
  width: 100%;
  height: 570px;
}

.subtract-component-card-container.compact {
  height: 196px;
}

.subtraction-component-card-tooltip {
  margin-right: 5px;
  width: 100%;
  height: 25px;
  color: #242c5c;
  font-size: 16px;
  font-family: "Inter";
}

.subtraction-header-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.subtraction-header-container-tutorial,
.subtraction-header-container-settings {
  margin: 10px 0 10px 0;
}

.subtract-component-card.compact {
  position: relative;
  margin-bottom: 7px;
  height: 49px;
  min-height: 49px;
}

.subtract-component-card-header-compact {
  padding: 0 10px;
}

.subtract-component-card-rating-menu-wrapper {
  display: flex;
  flex-direction: row;
}

.subtract-component-card-menu-button.compact {
  width: 22px;
}

.component-card.compact {
  height: 370px;
}

.subtraction-component-type-container {
  margin-top: 16px;
}

.subtraction-component-card-header.compact {
  margin: 0;
}

.subtract-component-card-title-container {
  max-width: 100%;
}

.subtract-component-card-title-container span {
  padding-top: 6px;
  max-width: 269px;
  max-height: 40px;
  overflow: hidden;
  line-height: 18px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-word;
}

.subtract-component-card-title-container.compact span {
  padding-top: 2px;
  max-height: 36px;
  line-height: 20px;
}

.component-card img {
  pointer-events: none;
}

.subtraction-component-card-header-text {
  width: 100%;
  height: 30px;
}

.mt-10 {
  margin-top: 10px;
}

.component-droppable-container {
  min-height: calc(100% - 10px);
}

.component-placeholder {
  padding-top: 30px;
}

.component-placeholder span {
  height: 38px;
  color: #242c5c;
  font-size: 22px;
  font-family: "Inter";
}

.subtraction-component-card-header-top {
  justify-content: space-between;
  width: 363px;
  min-width: 363px;
  height: 32px;
}

.row-dropdown-container {
  position: absolute;
  right: 0;
  opacity: 0;
  height: 51px;
}

.cell-dropdown-row:hover .row-dropdown-container {
  opacity: 1;
}

.open-details-button-subtraction {
  justify-content: space-between;
  align-items: center;
  outline: none;
  border: none;
  border-radius: 4px;
  background-color: #dfe7fa;
  padding: 0 10px 0 20px;
  width: 400px;
  height: 40px;
}

.open-details-button-subtraction span {
  color: #3e6fd9;
  font-size: 16px;
  font-family: "Inter";
}

.open-details-button-subtraction svg {
  color: #3e6fd9;
}

.open-details-button-subtraction:hover {
  background-color: #3e6fd9;
}

.open-details-button-subtraction:hover span {
  color: white;
}

.open-details-button-subtraction:hover svg {
  color: white;
}
.open-details-button-subtraction .button-triangle-icon {
  margin-left: 5px;
}

.open-details-button-subtraction:hover .button-triangle-icon {
  border-color: transparent transparent transparent white;
}

.open-details-button-subtraction.edit {
  margin-right: 10px;
  outline: none;
  padding: 0 10px 0 10px;
  width: 77px;
  height: 30px;
}

.open-details-button-subtraction.medium {
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
  outline: none;
  border: 1px solid #3e6fd9;
}

.active-component {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  box-shadow: 0 3px 6px rgba(36, 44, 92, 0.4);
  border: 1px solid #3e6fd9;
}

.top-2 {
  position: relative !important;
  top: 2px;
}

.invitation-input {
  border: 2px solid #cdd1e5;
  border-radius: 4px;
  background-color: #f7f8fa;
  width: 168px;
  height: 152px;
}

.invitation-input:not(:last-of-type) {
  margin-right: 18px;
}

.invitation-input.active {
  border-color: #3e6fd9;
}

.invitation-radio-button-icon {
  margin: 24px 0 30px;
  border: 2px solid #cdd1e5;
  border-radius: 10px;
  background-color: transparent;
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
}

.invitation-input-text {
  width: 122px;
  height: 32px;
  color: #242c5c;
  font-size: 24px;
  line-height: 22px;
  font-family: "Inter";
}

.invitation-title-container img {
  margin: 24px 0 30px;
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
}

.invitation-dialog-wrapper {
  position: relative;
  border-radius: 4px;
  width: 622px;
  height: 728px;
  font-family: "Inter";
}

.invitation-dialog-header {
  justify-content: space-between;
  align-items: center;
  border-radius: 4px 4px 0 0;
  background-color: #eceef5;
  padding: 0 40px;
  width: 100%;
  height: 85px;
}

.invitation-dialog-header img {
  -webkit-user-select: none;
          user-select: none;
}

.invitation-dialog-body {
  padding: 0 40px 0 40px;
  width: 100%;
}

.invitation-dialog-title {
  height: 38px;
  color: #242c5c;
  font-size: 30px;
  font-family: "Inter";
}

.invitation-dialog-text {
  margin: 50px 0 20px 0;
  height: 32px;
  color: #465664;
  font-size: 24px;
}

.invitation-dialog-button {
  position: absolute;
  bottom: 40px;
  align-self: flex-end;
  outline: none !important;
  border: none;
  border-radius: 4px;
  background-color: #3e6fd9;
  width: 243px;
  height: 44px;
}

.invitation-dialog-button span {
  height: 24px;
  color: #ffffff;
  font-size: 18px;
  text-transform: uppercase;
}

.users-page-wrapper {
  margin: 95px 40px 0;
  background-color: #f7f8fa;
  max-height: calc(100vh - 20px);
  overflow-y: hidden;
  font-family: "Inter";
}

.users-page-title-container {
  min-width: 150px;
  height: 38px;
  text-align: left;
}

.users-page-count {
  height: 38px;
  color: #838fdb;
  font-size: 30px;
}

.users-page-title {
  margin-right: 5px;
  height: 40px;
  color: #242c5c;
  font-size: 27px;
  font-family: "Inter";
}

.button-triangle-icon {
  border: 5px solid;
  border-color: transparent transparent transparent #3e6fd9;
}

.users-button {
  margin-right: 15px;
  outline: none !important;
  border: 1px solid #3e6fd9;
  border-radius: 4px;
  background-color: #ecf1fc;
  padding: 0 10px 0 15px;
  width: 177px;
  height: 41px;
}

.users-button span {
  height: 20px;
  color: #3e6fd9;
  font-size: 15px;
  font-family: "Inter";
  text-transform: uppercase;
}

.users-button:hover {
  background-color: #3e6fd9;
}

.users-button:hover span {
  color: white;
}

.users-button:hover .button-triangle-icon {
  border-color: transparent transparent transparent white;
}

.users-button.team-button {
  margin-right: 10px;
  width: 197px;
}

.team-types-count .team-type-indicator {
  height: 40px;
}

.team-types-count .team-type-indicator:not(:last-of-type) {
  margin-right: 10px;
}

.users-copy-link-button {
  margin-right: 10px;
  min-width: 115px;
}

.users-copy-link-button .copy-link-button,
.users-copy-link-button .share-link-button {
  max-width: 200px;
  height: 41px;
}

.users-page-wrapper .custom-search-input-input {
  min-width: 130px;
}

.users-placeholder {
  padding-top: 30px;
}

.users-placeholder span {
  height: 38px;
  color: #242c5c;
  font-size: 22px;
  font-family: "Inter";
}

.users-row {
  margin-bottom: 2px;
  border: 2px solid transparent;
  border-radius: 4px;
  background-color: #f3f4f8;
  height: 51px;
}

.users-row:hover {
  border: 2px solid #3e6fd9;
  background-color: #eceef5;
}

.users-row-header {
  margin-bottom: 2px;
  background-color: transparent;
  height: 22px;
}

.users-container-header {
  margin: 20px 0 10px 10px;
  padding: 0 20px 0 10px;
  width: calc(100% - 10px);
}

.users-row-color-indicator {
  background-color: #cdd1e5;
  min-width: 7px;
  max-width: 7px;
  height: 51px;
}

.users-row:hover .users-row-color-indicator {
  border-radius: 4px 0 0 4px;
  background-color: #3e6fd9;
}

.users-row-checkbox {
  width: 58px;
  min-width: 58px;
  min-width: 5%;
  max-width: 58px;
  height: 21px;
}

.users-checkbox {
  width: 21px;
  height: 21px;
}

.users-row-initials {
  flex: 1 1 56px;
  min-width: 5%;
  max-width: 56px;
}

.users-row-fullname {
  flex: 1 1 226px;
  min-width: 17%;
}

.users-row-username {
  flex: 1 1 385px;
  min-width: 28%;
}

.users-row-team {
  flex: 1 1 298px;
  min-width: 22%;
  overflow: hidden;
}

.users-row-date {
  flex: 1 1 272px;
  min-width: 16%;
}

.users-row-color-indicator.transparent {
  background-color: transparent;
}

.users-rows-container {
  margin-left: 10px;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 10px 20px 20px 10px;
  width: calc(100% - 10px);
  overflow-y: auto;
}

.users-row-initials-block {
  border-radius: 2px;
  background-color: #fde8e9;
  width: 31px;
  height: 26px;
}

.users-row-initials-block span {
  height: 20px;
  color: #f1666a;
  font-size: 14px;
}

.users-row-full-name-text {
  height: 22px;
  overflow: hidden;
  color: #3e6fd9;
  font-size: 16px;
  font-family: "Inter";
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.users-row-text {
  max-width: calc(100% - 10px);
  height: 22px;
  overflow: hidden;
  color: #242c5c;
  font-size: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.users-row-text.active {
  color: #3e6fd9;
}

.users-row-text.pointer {
  -webkit-user-select: none;
          user-select: none;
}

.more-button {
  outline: none !important;
  border: none;
  background-color: transparent;
  width: 30px;
  height: 30px;
  -webkit-user-select: none;
          user-select: none;
}

.more-button img {
  min-width: 4px;
  min-height: 15px;
  pointer-events: none;
}

.team-type-indicator {
  border-radius: 4px;
  background-color: #d6e3ff;
  padding: 0 15px;
  width: 149px;
  height: 34px;
}

.team-type-label {
  height: 23px;
  color: #242c5c;
  font-size: 16px;
  font-family: "Inter";
  white-space: nowrap;
}

.team-type-count {
  margin-right: 5px;
  height: 23px;
  color: #3e6fd9;
  font-size: 16px;
  font-family: "Inter";
}

.team-type-indicator svg {
  margin-right: 5px;
  width: 19px;
  min-width: 19px;
  height: 19px;
  min-height: 19px;
  color: #242c5c;
}

.team-type-indicator.individual {
  background-color: #ffdedf;
}

.team-type-indicator.individual svg {
  margin-right: 10px;
  width: 14px;
  min-width: 14px;
  height: 19px;
  min-height: 19px;
  color: #242c5c;
}

.menu-button-placeholder {
  width: 30px;
  min-width: 30px;
  min-height: 30px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f8fa !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

