.plan-dialog {
  width: 348px;
  height: 308px;
}

.header-dialog {
  font-weight: 500;
  font-size: 24px;
}

.dialog-close {
  width: 22px;
}

.top-dialog {
  background-color: #eceef5;
  padding: 30px;
  height: 90px;
}

.modal-adding-part {
  padding: 30px;
  padding-top: 16px;
  height: 218px;
}

.number-field {
  margin-top: 20px;
  margin-left: 20px;
}

.type-plan {
  width: 371px;
}

.plan-subscription {
  margin-top: 20px;
  background-color: white;
  padding: 20px 30px;
  width: 300px;
  min-height: 500px;
}

.plan-tokens-dialog {
  width: 346px;
  height: 308px;
}

.modal-adding-tokens-part {
  padding: 30px;
  padding-top: 15px;
  height: 220px;
}

.plan-monthly {
  margin-left: 20px;
  font-weight: 100;
  font-size: 14px;
}

.small-description-input {
  margin-left: -15px;
  border: 0 !important;
  background-color: #f6f7f9 !important;
  padding-left: 15px !important;
  width: 256px !important;
  height: 26px !important;
  color: #242c5c !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 26px !important;
  text-align: left;
}

.save-description {
  position: absolute;
  left: 286px;
  outline: 0 !important;
  border: 0;
  background-color: #3e6fd9;
  width: 22px;
  height: 26px;
}

.save-description:hover {
  background-color: #3061ae;
}

.active-plan-item:hover {
  cursor: pointer;
  color: #3e6fd9;
}

.plan {
  margin-bottom: 50px;
}

.plus-icon {
  position: absolute;
  width: 18px;
}

.close-description {
  position: absolute;
  left: 264px;
  z-index: 2;
  outline: 0 !important;
  border: 0;
  background-color: #fde8e9;
  width: 22px;
  height: 26px;
}

.close-description:hover {
  fill: white !important;
  background-color: #cc4b4f;
}

.close-description:hover .icon-close {
  fill: white;
}

.icon-close {
  fill: #f1666a;
}

.remove-description-field {
  margin-right: 0 !important;
  border-radius: 0;
  width: 130px !important;
  height: 43px !important;
  min-height: 43px !important;
  max-height: 43px !important;
  font-weight: 500 !important;
}

.add-description-field {
  margin-top: 0 !important;
  min-width: 130px !important;
  max-width: 130px !important;
}
