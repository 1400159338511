.profile {
  padding-left: 10px;
}

.text-color {
  color: #242c5c;
}

.title {
  font-size: 26px;
}

.name-title {
  margin-top: 20px;
  font-size: 11px;
}

.email-title {
  margin-top: 12px;
  font-size: 11px;
}

.email-info {
  margin-top: -6px;
  font-weight: 500;
  font-size: 16px;
}

.name-info {
  margin-top: -6px;
  font-weight: 500;
  font-size: 20px;
}

.default-button {
  width: auto;
  min-width: auto;
  color: #3e6fd9;
}

.default-button:hover {
  color: white;
}

.reset-password-button {
  margin-top: 15px;
}

.edit-profile-button {
  margin-top: 20px;
}
