.users-row {
  margin-bottom: 2px;
  border: 2px solid transparent;
  border-radius: 4px;
  background-color: #f3f4f8;
  height: 51px;
}

.users-row:hover {
  border: 2px solid #3e6fd9;
  background-color: #eceef5;
}

.users-row-header {
  margin-bottom: 2px;
  background-color: transparent;
  height: 22px;
}

.users-container-header {
  margin: 20px 0 10px 10px;
  padding: 0 20px 0 10px;
  width: calc(100% - 10px);
}

.users-row-color-indicator {
  background-color: #cdd1e5;
  min-width: 7px;
  max-width: 7px;
  height: 51px;
}

.users-row:hover .users-row-color-indicator {
  border-radius: 4px 0 0 4px;
  background-color: #3e6fd9;
}

.users-row-checkbox {
  width: 58px;
  min-width: 58px;
  min-width: 5%;
  max-width: 58px;
  height: 21px;
}

.users-checkbox {
  width: 21px;
  height: 21px;
}

.users-row-initials {
  flex: 1 1 56px;
  min-width: 5%;
  max-width: 56px;
}

.users-row-fullname {
  flex: 1 1 226px;
  min-width: 17%;
}

.users-row-username {
  flex: 1 1 385px;
  min-width: 28%;
}

.users-row-team {
  flex: 1 1 298px;
  min-width: 22%;
  overflow: hidden;
}

.users-row-date {
  flex: 1 1 272px;
  min-width: 16%;
}

.users-row-color-indicator.transparent {
  background-color: transparent;
}

.users-rows-container {
  margin-left: 10px;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 10px 20px 20px 10px;
  width: calc(100% - 10px);
  overflow-y: auto;
}

.users-row-initials-block {
  border-radius: 2px;
  background-color: #fde8e9;
  width: 31px;
  height: 26px;
}

.users-row-initials-block span {
  height: 20px;
  color: #f1666a;
  font-size: 14px;
}

.users-row-full-name-text {
  height: 22px;
  overflow: hidden;
  color: #3e6fd9;
  font-size: 16px;
  font-family: "Inter";
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.users-row-text {
  max-width: calc(100% - 10px);
  height: 22px;
  overflow: hidden;
  color: #242c5c;
  font-size: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.users-row-text.active {
  color: #3e6fd9;
}

.users-row-text.pointer {
  user-select: none;
}

.more-button {
  outline: none !important;
  border: none;
  background-color: transparent;
  width: 30px;
  height: 30px;
  user-select: none;
}

.more-button img {
  min-width: 4px;
  min-height: 15px;
  pointer-events: none;
}

.team-type-indicator {
  border-radius: 4px;
  background-color: #d6e3ff;
  padding: 0 15px;
  width: 149px;
  height: 34px;
}

.team-type-label {
  height: 23px;
  color: #242c5c;
  font-size: 16px;
  font-family: "Inter";
  white-space: nowrap;
}

.team-type-count {
  margin-right: 5px;
  height: 23px;
  color: #3e6fd9;
  font-size: 16px;
  font-family: "Inter";
}

.team-type-indicator svg {
  margin-right: 5px;
  width: 19px;
  min-width: 19px;
  height: 19px;
  min-height: 19px;
  color: #242c5c;
}

.team-type-indicator.individual {
  background-color: #ffdedf;
}

.team-type-indicator.individual svg {
  margin-right: 10px;
  width: 14px;
  min-width: 14px;
  height: 19px;
  min-height: 19px;
  color: #242c5c;
}

.menu-button-placeholder {
  width: 30px;
  min-width: 30px;
  min-height: 30px;
}
