.header-body {
  margin-top: 39px;
  color: #242c5c;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 24px;
  text-align: center;
}

.table {
  margin-top: 23px;
  border-width: 2px;
  border-style: solid;
  border-color: #cdd1e5;
  border-radius: 5px;
  background: #f7f8fa;
  padding-top: 38px;
  width: 539px;
  height: 258px;
}

.table-item {
  display: flex;
  margin-left: 49px;
  max-width: 420px;
  color: #242c5c;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
}

.circle {
  margin-right: 16px;
  border-radius: 8px;
  background: #cdd1e5;
  width: 16px;
  min-width: 16px;
  height: 16px;
}

.cancel-button {
  margin-top: 40px;
  width: 539px;
  height: 58px;
  font-size: 20px;
}

.dialog {
  width: 662px;
  height: 551px;
}
