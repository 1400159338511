.header {
  color: #242c5c;
  font-size: 26px;
}

.promo-code {
  margin-top: 22px;
  background-color: #e2eafc;
  height: 76px;
}

.promo-code span {
  padding-right: 40px;
  padding-left: 33px;
  color: #242c5c;
  font-weight: 500;
  font-size: 20px;
}

.promo-code-button {
  margin-left: 5px;
  width: 90px;
  color: #3e6fd9;
  font-weight: 500;
}

.promo-code-button:hover {
  color: white;
}

.promo-code-field {
  border: 2px #9eb6ec solid;
  width: 215px;
  font-weight: 500;
}

.promo-code-field::placeholder {
  color: #3e6fd9;
  font-weight: 500;
}

.promo-code-field:focus,
.promo-code-field:hover {
  border-color: #3e6fd9;
}

.subscription_block {
  margin-top: 20px;
  border-width: 1px;
  border-style: solid;
  border-color: #c6cbed;
  border-radius: 5px;
  padding: 28px 33px;
  min-height: 310px;
}

.header-active-subscription {
  display: flex;
  color: #242c5c;
  font-size: 20px;
  line-height: 22px;
  text-align: left;
  text-align: start;
}

.body-active-subscription {
  margin-top: 20px;
  min-height: 220px;
}

.left-body-active-subscription {
  min-width: 255px;
}

.active-plan-header {
  display: flex;
  color: #242c5c;
  font-weight: 500;
  font-size: 18px;
  text-align: left;
  text-align: start;
}

.active-plan-item {
  min-width: 240px;
  color: #242c5c;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  list-style-type: "– ";
  text-align: left;
}

.active-plan-items {
  margin-top: 10px;
  padding-left: 13px;
}

.credits-header {
  color: #242c5c;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
}

.credits {
  margin-top: 20px;
  color: #242c5c;
  font-weight: 500;
  font-size: 30px;
  line-height: 22px;
  text-align: left;
}

.credits-details {
  margin-top: 7px;
  width: 120px;
  color: #242c5c;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
}

.credits-buy {
  margin-top: 29px;
  border-width: 1px;
  border-style: solid;
  border-color: #f1666a;
  border-radius: 3px;
  background: #fde8e9;
  padding-left: 12px;
  width: 205px;
  height: 43px;
  color: #f1666a;
  font-weight: 500;
  font-size: 16px;
  text-align: left;
}

.mb-32 {
  margin-bottom: 32px;
}

.mt-20 {
  margin-top: 20px;
}

.credits-buy:hover {
  background-color: #cc4b4f;
  color: white;
}

.billing-description {
  width: 140px;
  color: #242c5c;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  text-align: left;
}

.billing-description-right {
  margin-left: -26px;
  width: 140px;
  color: #242c5c;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  text-align: right;
}

.active-plan-body {
  margin-top: 16px;
}

.get-billing-details {
  margin-top: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #3e6fd9;
  border-radius: 3px;
  background: #3e6fd9;
  width: 236px;
  height: 43px;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  text-align: left;
}

.cancel-subscription-link {
  display: flex;
  margin-top: 10px;
  color: #3e6fd9 !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  text-align: start;
}

.cancel-subscription-link:hover {
  cursor: pointer;
}

.border-active-subscription {
  border-left: 2px #eceef5 solid;
  padding-left: 25px;
}

.border-ai-credits {
  border: 1px #c6cbed solid;
  border-radius: 5px;
  padding: 25px;
}

.padding-credits {
  padding-right: 30px;
}

.padding-15 {
  padding: 15px;
}

.bulling-button-dark-blue {
  border-width: 1px;
  border-style: solid;
  border-color: #3e6fd9;
  border-radius: 3px;
  background: #3e6fd9;
  width: 168px;
  height: 43px;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  text-align: left;
}

.bulling-button-blue {
  display: flex;
  justify-content: center;
  margin-left: 5px;
  padding: 0;
  width: 168px;
  height: 43px;
  color: #3e6fd9;
  font-weight: 500;
}

.bulling-button-blue:hover {
  color: white;
}

.billing-left-button {
  margin-left: -20px;
}

.billing-right-button {
  margin-left: 0 !important;
}

.buy-subscription {
  margin-top: 27px;
}

.page {
  min-width: 1200px;
}

.billing-left-blue {
  margin-left: -5px;
}

.ml-10 {
  margin-left: 10px;
}

.plan {
  filter: drop-shadow(0px 1px 0.5px #c6cbed);
  margin-top: 10px;
  margin-bottom: 20px;
  border-width: 1px;
  border-style: solid;
  border-color: #c6cbed;
  border-radius: 5px;
  background: #ffffff;
  padding: 27px 36px 27px;
  width: 335px;
  min-height: 540px;
}

.plan-header {
  color: #242c5c;
  font-style: normal;
  font-weight: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  text-align: left;
}

.plan-amount {
  color: #242c5c;
  font-weight: 500;
  font-size: 30px;
  line-height: 22px;
  text-align: left;
}

.plan-period {
  margin-left: 12px;
  color: #242c5c;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
}

.plan-prev {
  margin-top: 14px;
}

.plan-billed {
  margin-top: 4px;
  color: #242c5c;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
}

.plan-buy {
  margin-top: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: #3e6fd9;
  background: #3e6fd9;
  width: 168px;
  min-width: 100%;
  height: 43px;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  text-align: left;
}

.get-billing-details:hover,
.plan-buy:hover {
  background-color: #3061ae;
  color: white;
}

.plan-descriptions {
  margin-top: 20px;
  margin-left: -25px;
}

.plan-credits-description {
  display: flex;
  width: 270px;
  color: #242c5c;
  font-style: normal;
  font-weight: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
}

.select-amount {
  margin-top: 10px;
  padding-left: 27px;
  font-weight: 500;
}

.select-amount:hover {
  border-color: #f1666a;
  background-color: #fde8e9;
  color: #f1666a;
}

.plan-credits-buy {
  border-top: 2px #c6cbed solid;
  padding-top: 15px;
}

.select-credits {
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-color: #f1f1f1;
  background: #fafafa;
  padding-right: 20px;
  padding-left: 17px;
  height: 39px;
  color: #242c5c;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 40px;
  text-align: left;
}

.select-credits:hover {
  cursor: pointer;
  background-color: #ffffff;
}

button:active,
button:focus {
  outline: none;
}
button::-moz-focus-inner {
  border: 0;
}

.select-credits-block {
  position: absolute;
  bottom: 69px;
  filter: drop-shadow(0px -3px 4px rgba(0, 0, 0, 0.16));
  width: 261px;
}

.select-amount-active {
  display: flex;
  align-items: center;
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16));
  border-width: 1px;
  border-style: solid;
  border-color: #f1666a;
  border: 0;
  border-radius: 3px;
  background: #fde8e9;
  background-color: #fafafa;
  width: 250px;
  height: 43px;
}

.select-amount-active:hover {
  background-color: #fafafa;
  color: #242c5c;
}

.arrow-icon {
  position: relative;
  width: 30px;
  height: 42px;
}

.select-credits-label {
  display: flex;
  justify-content: space-between;
  width: 195px;
}
