.header {
  background-color: #eceef5;
  padding: 0px 42px;
  height: 85px;
}

.dialog {
  width: 622px;
  height: 566px;
}

.next-dialog {
  width: 622px;
  height: 515px;
}

.title-header {
  margin-left: 10px;
  color: #242c5c;
  font-weight: 500;
  font-size: 30px;
}

.description-span {
  margin-top: 12px;
  width: 400px;
  font-weight: 500;
}

.default-color {
  color: #242c5c;
}

.confirm-span {
  margin-top: 40px;
}

.main-span {
  margin-top: 40px;
  font-weight: 500;
  font-size: 24px;
}

.tariff-block {
  margin-top: 30px;
  margin-bottom: 28px;
  border: 2px #cdd1e5 solid;
  border-radius: 5px;
  width: 163px;
  height: 193px;
}

.tariff-left-margin {
  margin-left: 17px;
}

.check_mark {
  display: flex;
  margin-bottom: 14px;
  border-width: 2px;
  border-style: solid;
  border-color: #cdd1e5;
  border-radius: 8px;
  width: 16px;
  height: 16px;
}

.default-button {
  width: 524px !important;
  height: 58px !important;
  font-size: 21px;
}

.icon-check_mark {
  margin-bottom: 12px;
}

.close-icon {
  margin-right: -10px;
  padding: 10px;
}

.tariff-block:hover,
.close-icon:hover {
  cursor: pointer;
}

.tariff-info {
  color: #242c5c;
  font-weight: 500;
  font-size: 18px;
}

.total-table {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 42px;
  border: 5px #cdd1e5 solid;
  border-width: 2px;
  border-radius: 4px;
  background: #f7f8fa;
  width: 540px;
  height: 223px;
}

.credits-block {
  margin-top: 30px;
  margin-right: 30px;
  margin-left: 30px;
}

.credits-block-bottom {
  margin-top: 20px;
  margin-bottom: 10px;
}

.total-block {
  margin-right: 30px;
  margin-bottom: 26px;
  margin-left: 30px;
  border-top: 2px #cdd1e5 solid;
  border-radius: 1px;
  padding-top: 15px;
}

.credits-block span {
  color: #242c5c;
  font-weight: 500;
  font-size: 18px;
}

.total-block span {
  color: #242c5c;
  font-weight: 600;
  font-size: 18px;
}

.purchase-button {
  margin-top: 28px;
  border: 0;
  min-width: 544px;
}

.credits-block-subscription {
  margin-top: 20px;
}

.total-table-subscription {
  margin-top: 28px;
}
