.dialog {
  width: 500px;
  height: 360px;
}

.header {
  padding: 0px 42px;
  height: 80px;
}

.close-icon {
  margin-right: -20px;
  padding: 20px;
}

.close-icon:hover {
  cursor: pointer;
}

.title-header {
  margin-left: 10px;
  color: #242c5c;
  font-weight: 600;
  font-size: 20px;
}

.close-button {
  border: none;
  background: none;
}

.buy-button {
  margin: 0 !important;
}

.body-label {
  padding: 30px 20px 42px 20px;
  font-weight: 500;
  font-size: 20px;
}

.purchase-button {
  margin-top: 18px;
  border: 2px #3e6fd9 solid !important;
  background-color: white !important;
  color: #3e6fd9 !important;
}

.purchase-button:hover {
  background-color: #f5f5f5 !important;
}

.default-button {
  width: 80% !important;
  height: 42px !important;
  font-weight: 600;
  text-align: center;
}

.icon-generate {
  width: 24px;
  height: 24px;
  color: #cc4b4f;
}
